import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { patientmessagingService } from '../../../../services/http/patientmessaging.service'
import { Message } from '../../../../services/models/Message.model'
import { IconLabel } from '../../../../UI/IconLabel/IconLabel'
import { MOMENT_DATETIME_FORMAT } from '../../../constants'
import { useFamilyMember } from '../../../hooks/useFamilyMember'

interface Props {
    message: Message
    onMessageUpdated: (message: Message) => void
}

export function Acknowledge(props: Props) {
    const { getMemberClinicByClientName } = useFamilyMember()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const memberClinicID = useMemo((): number | null => {
        return getMemberClinicByClientName(props.message.clientName)?.id || null
    }, [props.message.clientName, getMemberClinicByClientName])

    const isAcknowledged = useMemo(() => {
        return (
            !props.message.isResponseAllowed &&
            moment(props.message.replyAt).isAfter(moment(props.message.sentAt))
        )
    }, [props.message])

    const onAcknowledge = () => {
        if (!memberClinicID) {
            return
        }

        const message: Message = {
            ...props.message,
            replyAt: new Date(),
        }

        setIsLoading(true)
        patientmessagingService
            .acknowledge(message.id, message.replyAt!, memberClinicID)
            .then(() => {
                props.onMessageUpdated(message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div className="acknowledgement-wrapper">
            <IconLabel
                label={
                    isAcknowledged ? 'Acknowledged' : 'Acknowledgement Required'
                }
                materialIcon="done_all"
                color="color-green"
            />

            {isAcknowledged ? (
                <div className="mt-3">
                    <span>You have acknowledged this message on&nbsp;</span>
                    <span className="font-weight-500">
                        {moment().format(MOMENT_DATETIME_FORMAT)}.
                    </span>
                </div>
            ) : (
                <div className="mt-3">
                    <div>Your acknowledgement of this message is required.</div>
                    <button
                        className={
                            'mt-3 width-unset d-inline-block background-green color-white' +
                            (isLoading ? ' is-loading' : '')
                        }
                        onClick={onAcknowledge}
                    >
                        Acknowledge
                    </button>
                </div>
            )}
        </div>
    )
}

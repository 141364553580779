import axios from 'axios'

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/reminder`

export const reminderService = {
    confirmAppointment: (id: string): Promise<void> => confirmAppointment(id),
    cancelAppointment: (id: string): Promise<void> => cancelAppointment(id),
}

const confirmAppointment = (id: string): Promise<void> => {
    return axios.get(`${baseURL}/sendgrid/confirm?id=${id}`)
}

const cancelAppointment = (id: string): Promise<void> => {
    return axios.get(`${baseURL}/sendgrid/cancel?id=${id}`)
}

import React from 'react'
import { ROUTES } from '../App'
import { AppLogo } from '../UI/AppLogo/AppLogo'

export function Header() {
    return (
        <header className="header-wrapper">
            <div className="header-inner">
                <AppLogo />

                <a href={process.env.REACT_APP_AVAROS_HOST + ROUTES.AUTH}>
                    <div className="d-none d-sm-block">
                        <button className="primary d-inline-block">
                            <span>Sign In</span>
                        </button>
                    </div>
                    <div className="d-sm-none d-block">
                        <div className="div-button font-size-18 mt-2">
                            Sign In
                        </div>
                    </div>
                </a>
            </div>
        </header>
    )
}

import React from 'react'
import { ROUTES } from '../App'

export function HowItWorks() {
    return (
        <section className="howitworks-wrapper">
            <div className="howitworks-inner">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-4 col-12">
                        <div className="section-title">Sign-up is free</div>
                        <div className="section-subtitle">
                            You can create your Medley account anytime
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <a
                            href={
                                process.env.REACT_APP_AVAROS_HOST +
                                ROUTES.AUTH +
                                ROUTES.SIGNUP
                            }
                        >
                            <button className="primary d-inline-block">
                                <span>Let's Get Started!</span>
                            </button>
                        </a>
                    </div>
                </div>

                <img
                    className="d-none d-md-block section-line"
                    src={
                        process.env.PUBLIC_URL +
                        '/assets/images/illustration-section-lines.png'
                    }
                    alt="Divider"
                />

                <div className="row d-flex align-items-center justify-content-between">
                    <div className="d-none d-md-block col-md-5 col-12">
                        <img
                            className="illustration"
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/images/illustration-howitworks1.png'
                            }
                            alt="Messaging"
                        />
                    </div>

                    <div className="col-md-5 col-12">
                        <div className="section-title">
                            Start Getting Secure Messages
                        </div>
                        <div className="section-subtitle">
                            Your Doctor can now send you secure messages,
                            documents, lab results and more
                        </div>
                    </div>

                    <div className="d-block d-md-none col-md-5 col-12">
                        <img
                            className="illustration"
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/images/illustration-howitworks1.png'
                            }
                            alt="Messaging"
                        />
                    </div>
                </div>

                <img
                    className="d-none d-md-block section-line flip-horizontal"
                    src={
                        process.env.PUBLIC_URL +
                        '/assets/images/illustration-section-lines.png'
                    }
                    alt="Divider"
                />

                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-md-5 col-12">
                        <div className="section-title">
                            Add Friends and Family
                        </div>
                        <div className="section-subtitle">
                            Use one Medley account to manage your entire
                            Family's messages
                        </div>
                    </div>

                    <div className="col-md-5 col-12">
                        <img
                            className="illustration"
                            src={
                                process.env.PUBLIC_URL +
                                '/assets/images/illustration-howitworks2.png'
                            }
                            alt="Friends & Family"
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

import { AxiosError, AxiosResponse } from 'axios'
import moment from 'moment'
import { MAX_MONTHS_AWAY_BOOKING_ALLOWED } from '../../main/appointments/components/BookAppointment/BookAppointment'
import {
    BookedAppointment,
    deserializeBookedAppointment,
} from '../../main/appointments/models/BookedAppointment.model'
import {
    Clinic,
    deserializeClinic,
} from '../../main/appointments/models/Clinic.model'
import {
    deserializeProvider,
    Provider,
} from '../../main/appointments/models/Provider.model'
import {
    deserializeRange,
    Range,
} from '../../main/appointments/models/Range.model'
import { ax } from './auth.service'

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/schedule`

const getAppointments = (
    memberProviderIDs: number[]
): Promise<BookedAppointment[]> => {
    return new Promise((resolve, reject) => {
        let url = `${baseURL}/patient/appointment?q=q`
        memberProviderIDs?.forEach((id: number) => {
            url += '&memberProviderID=' + id
        })

        ax.get(url)
            .then((response: AxiosResponse) => {
                if (response.data?.length) {
                    const appointments: BookedAppointment[] = response.data.map(
                        (obj: any) => deserializeBookedAppointment(obj)
                    )
                    appointments.sort(
                        (a: BookedAppointment, b: BookedAppointment) =>
                            a.startAt.getTime() - b.startAt.getTime()
                    )
                    resolve(appointments)
                } else {
                    resolve([])
                }
            })
            .catch(() => reject())
    })
}

const getProviders = (): Promise<{
    providers: Provider[]
    clinics: Clinic[]
}> => {
    return new Promise((resolve, reject) => {
        ax.get(`${baseURL}/patient/providers`).then(
            (response: AxiosResponse) => {
                const data = response.data
                const ret = {
                    providers: [],
                    clinics: [],
                }

                if (data?.providers?.length) {
                    ret.providers = data.providers.map((obj: any) =>
                        deserializeProvider(obj)
                    )
                }
                if (data?.clinics?.length) {
                    ret.clinics = data.clinics.map((obj: any) =>
                        deserializeClinic(obj)
                    )
                }

                resolve(ret)
            },
            (error: AxiosError) => {
                reject(error?.response?.data ?? true)
            }
        )
    })
}

export interface BookAppointmentPayload {
    demographicID: number
    providerID: number
    typeID: number
    startAt: string
    endAt: string
    reason?: string
    clientName: string
}

const bookAppointment = (
    payload: BookAppointmentPayload
): Promise<BookedAppointment> => {
    return new Promise((resolve, reject) => {
        ax.post(`${baseURL}/patient/appointment`, payload).then(
            (response: AxiosResponse) => {
                const data = response.data
                if (data) {
                    const appointment: BookedAppointment = deserializeBookedAppointment(
                        data
                    )
                    resolve(appointment)
                } else {
                    reject(true)
                }
            },
            (error: AxiosError) => {
                reject(error?.response?.data ?? true)
            }
        )
    })
}

const cancelAppointment = (appointmentID: number): Promise<number> => {
    return new Promise((resolve, reject) => {
        ax.delete(
            `${baseURL}/patient/appointment?appointmentID=${appointmentID}`
        ).then(
            (response: AxiosResponse) => {
                const data = response.data
                if (data) {
                    resolve(appointmentID)
                } else {
                    reject(true)
                }
            },
            (error: AxiosError) => {
                reject(error?.response?.data ?? true)
            }
        )
    })
}

const getAppointmentTypeRanges = (
    providerNo: number,
    clientName: string,
    typeID: number
): Promise<Range[]> => {
    return new Promise((resolve, reject) => {
        ax.get(
            `${baseURL}/patient/appointment/ranges?providerID=${providerNo}&clientName=${clientName}&typeID=${typeID}`
        ).then(
            (response: AxiosResponse) => {
                const data = response.data
                if (data?.length) {
                    let ranges: Range[] =
                        data.map((obj: any) => deserializeRange(obj)) ?? []

                    // Filter out ranges more than MAX_MONTHS_AWAY_BOOKING_ALLOWED months away from today's date
                    const maxRange = moment(
                        new Date().setHours(0, 0, 0, 0)
                    ).add(MAX_MONTHS_AWAY_BOOKING_ALLOWED, 'months')
                    ranges = ranges.filter((range: Range) =>
                        moment(range.startAt).isSameOrBefore(maxRange)
                    )

                    // Sort ranges by ascending order
                    ranges.sort((a: Range, b: Range) => {
                        const dateA = a.startAt.getTime()
                        const dateB = b.startAt.getTime()
                        if (dateA > dateB) {
                            return 1
                        } else if (dateA < dateB) {
                            return -1
                        } else {
                            return 0
                        }
                    })

                    // Subtract 1 minute from startAt
                    ranges = ranges.map((range: Range) => {
                        const _range = Object.assign({}, range)
                        _range.startAt = moment(_range.startAt)
                            .subtract(1, 'minutes')
                            .toDate()
                        return _range
                    })

                    resolve(ranges)
                } else {
                    reject(true)
                }
            },
            (error: AxiosError) => {
                reject(error?.response?.data ?? true)
            }
        )
    })
}

export const scheduleService = {
    getAppointments,
    getProviders,
    bookAppointment,
    cancelAppointment,
    getAppointmentTypeRanges,
}

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
    FamilyMember,
    MemberClinic,
    MemberProvider,
} from '../../services/models/Patient.model'
import { appSelector } from '../../services/store/selectors/appSelector'
import { utilsService } from '../../services/utils.service'

export const useFamilyMember = () => {
    const state = useSelector(appSelector)

    const familyMember = useMemo((): FamilyMember | null => {
        return (
            (state.familyMemberID &&
                state.familyMembersMap.get(state.familyMemberID)) ||
            null
        )
    }, [state.familyMemberID, state.familyMembersMap])

    const familyMembers = useMemo(
        () => Array.from(state.familyMembersMap.values()) || [],
        [state.familyMembersMap]
    )

    const memberClinics = useMemo(
        (): MemberClinic[] => familyMember?.clinics || [],
        [familyMember]
    )

    const memberClinicIDs = useMemo(
        (): number[] => memberClinics?.map((i: MemberClinic) => i.id) || [],
        [memberClinics]
    )

    const getMemberClinicByClientName = useCallback(
        (clientName): MemberClinic | null =>
            memberClinics?.find(
                (i: MemberClinic) => i.clientName === clientName
            ) || null,
        [memberClinics]
    )

    const memberProviders = useMemo(
        (): MemberProvider[] => familyMember?.providers || [],
        [familyMember]
    )

    const memberProviderIDs = useMemo(
        (): number[] => memberProviders?.map((i: MemberProvider) => i.id) || [],
        [memberProviders]
    )

    const approvedMemberProviderIDs = useMemo(
        (): number[] =>
            memberProviders
                ?.filter((i: MemberProvider) =>
                    MemberProvider.isRequestApproved(i)
                )
                ?.map((i: MemberProvider) => i.id) || [],
        [memberProviders]
    )

    const approvedMembersProviderFromCurrentFamilyMember = useMemo(
        (): MemberProvider[] =>
            familyMember?.providers?.filter((i: MemberProvider) =>
                MemberProvider.isRequestApproved(i)
            ) || [],

        [familyMember]
    )

    const pendingMembersProviderFromCurrentFamilyMember = useMemo(
        (): MemberProvider[] =>
            familyMember?.providers?.filter((i: MemberProvider) =>
                MemberProvider.isRequestPending(i)
            ) || [],

        [familyMember]
    )

    const getMemberProviderFromCurrentFamilyMember = useCallback(
        (providerID: number, clientName: string): MemberProvider | null =>
            familyMember?.providers?.find(
                (i: MemberProvider) =>
                    i.providerID === providerID && i.clientName === clientName
            ) || null,

        [familyMember]
    )

    const getMemberProviderRequestStatus = useCallback(
        (
            providerID: number,
            clientName: string,
            isOnlineBookingEnabled
        ): {
            demographicID: number
            isOnlineBookingEnabled: boolean
            isRequestApproved: boolean
            isRequestPending: boolean
            canSendRequest: boolean
            createdAt: Date | null
        } => {
            const memberProvider = getMemberProviderFromCurrentFamilyMember(
                providerID,
                clientName
            )

            const isRequestApproved = MemberProvider.isRequestApproved(
                memberProvider
            )
            const isRequestPending = MemberProvider.isRequestPending(
                memberProvider
            )
            const canSendRequest = MemberProvider.canSendRequest(
                isOnlineBookingEnabled,
                isRequestApproved,
                isRequestPending
            )

            return {
                demographicID: memberProvider?.demographicID || 0,
                isOnlineBookingEnabled,
                isRequestApproved,
                canSendRequest,
                isRequestPending,
                createdAt: memberProvider?.createdAt || null,
            }
        },
        [getMemberProviderFromCurrentFamilyMember]
    )

    const patientName = useMemo(
        (): string | null =>
            (familyMember && getName(familyMember)) || 'Patient',
        [familyMember]
    )

    const patientFirstName = useMemo((): string => {
        if (!patientName?.length) {
            return 'Patient'
        }
        return patientName.split(' ')[0]
    }, [patientName])

    const getPatientNameByFamilyMember = useCallback(
        (_familyMember: FamilyMember): string => getName(_familyMember),
        []
    )

    return {
        familyMember,
        familyMembers,
        familyMemberID: state.familyMemberID,

        memberClinics,
        memberClinicIDs,
        approvedMembersProviderFromCurrentFamilyMember,
        pendingMembersProviderFromCurrentFamilyMember,
        getMemberClinicByClientName,
        getMemberProviderRequestStatus,

        memberProviders,
        memberProviderIDs,
        approvedMemberProviderIDs,

        patientName,
        patientFirstName,
        getPatientNameByFamilyMember,
    }
}

function getName(familyMember: FamilyMember): string {
    if (!familyMember) {
        return 'Patient'
    }

    let firstName = familyMember.firstName ?? ''
    if (firstName?.length) {
        firstName = utilsService.capitalize(firstName)
    }

    let lastName = familyMember.lastName ?? ''
    if (lastName?.length) {
        lastName = utilsService.capitalize(lastName)
    }

    return [firstName, lastName].join(' ')
}

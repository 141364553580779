import { useCallback } from 'react'
import { AppointmentType } from '../models/AppointmentType.model'
import { Clinic } from '../models/Clinic.model'
import { useClinic } from './useClinic'

export const useAppointmentType = () => {
    const { getClinicByClientName } = useClinic()

    const getAppointmentTypeByIdAndClinic = useCallback(
        (clinic: Clinic | null, id: number): AppointmentType | null => {
            if (!clinic || !clinic?.appointmentTypes?.length || !id) {
                return null
            }

            return (
                clinic.appointmentTypes.find(
                    (i: AppointmentType) => i.id === id
                ) ?? null
            )
        },
        []
    )

    const getAppointmentTypeByIdAndClientName = useCallback(
        (clientName: string, id: number): AppointmentType | null => {
            if (!clientName || !id) {
                return null
            }

            const clinic = getClinicByClientName(clientName)
            return getAppointmentTypeByIdAndClinic(clinic, id)
        },
        [getClinicByClientName, getAppointmentTypeByIdAndClinic]
    )

    return {
        getAppointmentTypeByIdAndClinic,
        getAppointmentTypeByIdAndClientName,
    }
}

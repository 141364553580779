import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { POCKET_PILLS_HOMEPAGE } from '../../main/constants'
import { PocketPillsLogo } from '../PocketPillsLogo/PocketPillsLogo'

interface Props {
    show: boolean
    onClose: () => void
}

export function LeanMoreModal(props: Props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            dialogClassName="learn-more-modal-wrapper modal-wrapper"
            animation={false}
            centered
        >
            <Modal.Header className="px-4 learn-more-modal-header justify-content-center">
                <PocketPillsLogo />
            </Modal.Header>

            <Modal.Body>
                <p className="mb-4">
                    <div className="font-weight-500 mb-2">
                        What is a preferred pharmacy?
                    </div>
                    <div>
                        A preferred pharmacy is the pharmacy your doctor's
                        prescriptions are sent to. You can select any pharmacy
                        as your preferred pharmacy at your doctor's office.
                    </div>
                </p>

                <p className="mb-4">
                    <div className="font-weight-500 mb-2">
                        Why am I being asked if I want to set PocketPills as my
                        preferred pharmacy?
                    </div>
                    <div>
                        PocketPills is a pharmacy chain with free one-day
                        delivery of all prescriptions. They're a certified
                        pharmacy with licensed Canadian pharmacists. If you'd
                        prefer to have your prescriptions delivered to you, you
                        may benefit from their services. To learn more about
                        PocketPills, check out their website&nbsp;
                        <a
                            href={POCKET_PILLS_HOMEPAGE}
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                        .
                    </div>
                </p>
                <p className="mb-4">
                    <div className="font-weight-500 mb-2">
                        What if I choose PocketPills as my preferred pharmacy
                        and then change my mind?
                    </div>
                    <div>
                        You can always update your preferred pharmacy choice in
                        your user profile.
                    </div>
                </p>
            </Modal.Body>

            <Modal.Footer>
                <div
                    className="div-button color-text-secondary"
                    onClick={props.onClose}
                >
                    Close
                </div>
            </Modal.Footer>
        </Modal>
    )
}

import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { PocketPillsLogo } from '../PocketPillsLogo/PocketPillsLogo'
import { LeanMoreModal } from './LearnMoreModal'
import './PocketPillsForm.scss'

interface Props {
    classes?: string
    isSelected: boolean
    onSelected: (selected: boolean) => void
}

export function PocketPillsForm(props: Props) {
    const [showLearnMoreModal, setShowLearnMoreModal] = useState<boolean>(false)

    return (
        <>
            <Form.Row className={props.classes || ''}>
                <Col md={12}>
                    <Form.Label>
                        <span>Set</span>
                        <PocketPillsLogo />
                        <span>as your preferred pharmacy?&nbsp;</span>
                        <span
                            className="color-blue cursor-pointer"
                            onClick={() => setShowLearnMoreModal(true)}
                        >
                            Learn More
                        </span>
                    </Form.Label>
                </Col>
                <Col md={12}>
                    <Form.Label>
                        <ul className="pl-3">
                            <li>Free home delivery</li>
                            <li>All drug plans accepted</li>
                            <li>Low prices guaranteed</li>
                            <li>Discrete packaging</li>
                        </ul>
                    </Form.Label>
                </Col>

                <Col md={12} className="mt-2">
                    <div className="d-flex align-items-center">
                        <div
                            className="cursor-pointer d-flex align-items-center mr-4"
                            onClick={() => {
                                props.onSelected(true)
                            }}
                        >
                            <i
                                className={
                                    'material-icons mr-2' +
                                    (props.isSelected
                                        ? ' color-blue'
                                        : ' color-text-secondary')
                                }
                            >
                                {props.isSelected
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </i>
                            <span>Yes</span>
                        </div>
                        <div
                            className="cursor-pointer d-flex align-items-center mr-4"
                            onClick={() => {
                                props.onSelected(false)
                            }}
                        >
                            <i
                                className={
                                    'material-icons mr-2' +
                                    (!props.isSelected
                                        ? ' color-blue'
                                        : ' color-text-secondary')
                                }
                            >
                                {!props.isSelected
                                    ? 'radio_button_checked'
                                    : 'radio_button_unchecked'}
                            </i>
                            <span>No</span>
                        </div>
                    </div>
                </Col>
            </Form.Row>

            <LeanMoreModal
                show={showLearnMoreModal}
                onClose={() => setShowLearnMoreModal(false)}
            />
        </>
    )
}

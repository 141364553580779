import React, { ReactNode } from 'react'
import './PageCard.scss'

interface Props {
    title: string
    subtitle?: string
    classes?: string
    children?: ReactNode
}

export function PageCard(props: Props) {
    return (
        <div className={'page-card-wrapper ' + (props.classes ?? '')}>
            <div className="page-card-inner">
                <div className="page-card-title">{props.title}</div>
                {props.subtitle && (
                    <div className="page-card-subtitle">{props.subtitle}</div>
                )}
                {props.children}
            </div>
        </div>
    )
}

import { useReducer, useCallback } from 'react'

interface State {
    loading: boolean
    errorData: any
    responseData: any
    requestIdentifier: number
}

interface Action {
    type: HTTP_ACTIONS
    responseData?: any
    errorData?: any
    requestIdentifier: number
}

const initialState: State = {
    loading: false,
    errorData: null,
    responseData: null,
    requestIdentifier: -1,
}

enum HTTP_ACTIONS {
    SEND = 'SEND',
    RESPONSE = 'RESPONSE',
    ERROR = 'RESET',
    RESET = 'ERROR',
}

const httpReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case HTTP_ACTIONS.SEND:
            return {
                ...state,
                loading: true,
                errorData: null,
                requestIdentifier: action.requestIdentifier,
            }

        case HTTP_ACTIONS.RESPONSE:
            return {
                ...state,
                loading: false,
                responseData: action.responseData,
                errorData: null,
                requestIdentifier: action.requestIdentifier,
            }

        case HTTP_ACTIONS.ERROR:
            return {
                ...state,
                loading: false,
                responseData: null,
                errorData: action.errorData,
                requestIdentifier: action.requestIdentifier,
            }

        case HTTP_ACTIONS.RESET:
        default:
            return initialState
    }
}

const useHttp = () => {
    const [httpState, setHttpState] = useReducer(httpReducer, initialState)

    const httpSendRequest = useCallback(
        (requestFunc: () => Promise<any>, requestIdentifier: number) => {
            setHttpState({
                type: HTTP_ACTIONS.SEND,
                requestIdentifier,
            })

            requestFunc()
                .then((responseData: any) => {
                    setHttpState({
                        type: HTTP_ACTIONS.RESPONSE,
                        responseData,
                        requestIdentifier,
                    })
                })
                .catch((errorData: any) => {
                    setHttpState({
                        type: HTTP_ACTIONS.ERROR,
                        errorData,
                        requestIdentifier,
                    })
                })
        },
        []
    )

    return {
        httpLoading: httpState.loading,
        httpResponseData: httpState.responseData,
        httpErrorData: httpState.errorData,
        httpRequestIdentifier: httpState.requestIdentifier,
        httpSendRequest,
    }
}

export default useHttp

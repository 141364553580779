import { ErrorMessage } from '@hookform/error-message'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Form as FormBS } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { AUTHREGEX_DATE } from '../../../services/http/auth.service'
import { GenderField } from '../../../UI/GenderField/GenderField'
import { MemberDetails } from './MemberDetailsModal'

interface Props {
    memberDetails: MemberDetails | undefined
    onSaved: (form: MemberDetails) => void
    onCancel: () => void
}

export interface DropdownOption {
    value: string
    display: string
}

export function Form(props: Props) {
    const {
        register,
        handleSubmit,
        errors,
        setValue,
        control,
        clearErrors,
    } = useForm()
    const [form, setForm] = useState<MemberDetails | null>(null)

    useEffect(() => {
        let memberDetails: MemberDetails | null = null
        if (props.memberDetails) {
            memberDetails = props.memberDetails || null
        }
        if (!memberDetails) {
            memberDetails = {
                firstName: '',
                lastName: '',
                gender: '',
                birthday: '',
                healthCardNumber: '',
            }
        } else {
            const birthday = moment(memberDetails.birthday).format('YYYY-MM-DD')
            setValue('birthday', birthday)
            setValue('gender', memberDetails.gender)
        }
        setForm(memberDetails)
    }, [props.memberDetails, setValue])

    const onSubmit = (data: MemberDetails) => {
        props.onSaved(data)
    }

    return (
        <div>
            <FormBS onSubmit={handleSubmit(onSubmit)}>
                {/* First Name */}
                <FormBS.Row className="mt-4">
                    <Col md={12}>
                        <FormBS.Label>First Name</FormBS.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.firstName?.message ||
                                errors?.form?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="firstName"
                            type="text"
                            tabIndex={4}
                            placeholder="First Name"
                            ref={register({
                                required: 'First Name is required',
                            })}
                            onChange={() => {
                                clearErrors('form')
                            }}
                            defaultValue={form?.firstName}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="firstName"
                            as="div"
                        />
                    </Col>
                </FormBS.Row>

                {/* Last Name */}
                <FormBS.Row className="mt-4">
                    <Col md={12}>
                        <FormBS.Label>Last Name</FormBS.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.lastName?.message ||
                                errors?.form?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="lastName"
                            type="text"
                            tabIndex={5}
                            placeholder="Last Name"
                            ref={register({
                                required: 'Last Name is required',
                            })}
                            onChange={() => {
                                clearErrors('form')
                            }}
                            defaultValue={form?.lastName}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="lastName"
                            as="div"
                        />
                    </Col>
                </FormBS.Row>

                {/* Gender */}
                <FormBS.Row className="mt-4">
                    <Col md={12}>
                        <FormBS.Label>Gender</FormBS.Label>

                        <Controller
                            control={control}
                            name="gender"
                            rules={{
                                required: 'Gender is required',
                            }}
                            tabIndex={7}
                            defaultValue={form?.gender}
                            render={({ onChange, value }) => (
                                <GenderField
                                    classes={
                                        errors?.gender?.message ||
                                        errors?.form?.message
                                            ? ' invalid-state'
                                            : ''
                                    }
                                    initialValue={value}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="gender"
                            as="div"
                        />
                    </Col>
                </FormBS.Row>

                {/* Date of Birth */}
                <FormBS.Row className="mt-4">
                    <Col md={12}>
                        <FormBS.Label>Date of Birth</FormBS.Label>
                        <Controller
                            control={control}
                            name="birthday"
                            tabIndex={2}
                            defaultValue={form?.birthday}
                            rules={{
                                required: 'Date of Birth is required',
                                pattern: {
                                    value: AUTHREGEX_DATE,
                                    message: 'Invalid Date of Birth',
                                },
                            }}
                            render={({ onChange, value }) => (
                                <div className="w-100">
                                    <InputMask
                                        mask="9999-99-99"
                                        alwaysShowMask={true}
                                        value={value}
                                        onChange={(event) => {
                                            onChange(event)
                                            clearErrors('form')
                                        }}
                                    >
                                        {(inputProps: any) => (
                                            <input
                                                {...inputProps}
                                                className={
                                                    'form-control uppercase min-height-39' +
                                                    (errors?.birthday
                                                        ?.message ||
                                                    errors?.form?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="birthday"
                                                type="text"
                                                tabIndex={6}
                                                placeholder="YYYY-MM-DD"
                                            />
                                        )}
                                    </InputMask>
                                </div>
                            )}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="birthday"
                            as="div"
                        />
                    </Col>
                </FormBS.Row>

                {/* Health Card Number */}
                <FormBS.Row className="mt-4">
                    <Col md={12}>
                        <FormBS.Label>
                            Health Card Number (Optional)
                        </FormBS.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.healthCardNumber?.message ||
                                errors?.form?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="healthCardNumber"
                            type="text"
                            tabIndex={9}
                            placeholder="Health Card Number (Optional)"
                            ref={register({
                                pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: 'Must be 10 digits',
                                },
                                minLength: 10,
                                maxLength: 10,
                            })}
                            onChange={() => {
                                clearErrors('form')
                            }}
                            defaultValue={form?.healthCardNumber ?? ''}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="healthCardNumber"
                            as="div"
                        />
                    </Col>
                </FormBS.Row>

                <FormBS.Row className="my-4">
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <input
                            type="submit"
                            value="Save"
                            className="mr-3 width-unset color-blue background-none"
                        />
                        <div
                            className="div-button color-text-secondary"
                            onClick={props.onCancel}
                        >
                            Cancel
                        </div>
                    </Col>
                </FormBS.Row>
            </FormBS>
        </div>
    )
}

import React, { useMemo } from 'react'
import { FamilyMember } from '../../../../services/models/Patient.model'
import { MAX_MEMBERS } from '../../../constants'
import { onOpenAddMembersModal } from '../../../extra/add-members/AddMembersModal'
import { useFamilyMember } from '../../../hooks/useFamilyMember'
import './ManageMembers.scss'
import { Member } from './Member'

export function ManageMembers() {
    const { familyMembers } = useFamilyMember()

    const canAddMember = useMemo((): boolean => {
        return familyMembers.length < MAX_MEMBERS
    }, [familyMembers])

    return (
        <div className="manage-members-wrapper">
            <div className="font-weight-500 mb-2">Members</div>
            {familyMembers?.map((familyMember: FamilyMember, index: number) => (
                <Member key={index} familyMember={familyMember} />
            ))}

            {familyMembers?.length === 0 && (
                <div className="color-text-secondary">No members exist</div>
            )}

            {canAddMember && (
                <div className="text-center mt-3">
                    <div
                        className="div-button color-blue"
                        onClick={() => {
                            onOpenAddMembersModal()
                        }}
                    >
                        Add Members
                    </div>
                </div>
            )}
        </div>
    )
}

import React, { createContext, useContext, useEffect, useRef } from 'react'
import { MessageThread } from '../../../services/models/MessageThread.model'
import { GET_MESSAGES_INTERVAL } from '../../constants'
import { useFamilyMember } from '../../hooks/useFamilyMember'
import { useMessageThreads } from './useMessageThreads'

interface ContextState {
    messageThreads: {
        messageThreads: MessageThread[]
        unreadCount: number
        isLoading: boolean
        hasLoadedAll: boolean
        getMessageThreads: (memberClinicIDs: number[]) => void
        updateMessageThread: (messageThread: MessageThread) => void
    }
}

const initialState: ContextState = {
    messageThreads: {
        messageThreads: [],
        unreadCount: 0,
        isLoading: false,
        hasLoadedAll: true,
        getMessageThreads: () => {},
        updateMessageThread: () => {},
    },
}

const LocalStateContext = createContext<ContextState>(initialState)

// Provider
export function MessagesContextProvider({ children }: any) {
    const { memberClinicIDs } = useFamilyMember()
    const {
        messageThreads,
        unreadCount,
        isLoading,
        hasLoadedAll,
        getMessageThreads,
        updateMessageThread,
    } = useMessageThreads()
    const getMessageThreadsRef = useRef(getMessageThreads)
    const getMessagesIntervalRef = useRef<any>(null)

    useEffect(() => {
        getMessageThreadsRef.current(memberClinicIDs)

        if (getMessagesIntervalRef.current) {
            clearInterval(getMessagesIntervalRef.current)
        }
        getMessagesIntervalRef.current = setInterval(() => {
            getMessageThreadsRef.current(memberClinicIDs)
        }, GET_MESSAGES_INTERVAL)

        return () => {
            if (getMessagesIntervalRef.current) {
                clearInterval(getMessagesIntervalRef.current)
            }
        }
    }, [memberClinicIDs])

    return (
        <LocalStateContext.Provider
            value={{
                messageThreads: {
                    messageThreads,
                    unreadCount,
                    isLoading,
                    hasLoadedAll,
                    getMessageThreads,
                    updateMessageThread,
                },
            }}
        >
            {children}
        </LocalStateContext.Provider>
    )
}

// Consumer
export function useMessagesContext() {
    return useContext(LocalStateContext)
}

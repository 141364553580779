import React, { useMemo } from 'react'
import { onOpenSwitchMemberModal } from '../../main/extra/switch-member/SwitchMemberModal'
import { useFamilyMember } from '../../main/hooks/useFamilyMember'
import { PAGE, usePage } from '../../main/hooks/usePage'
import { useMessagesContext } from '../../main/messages/hooks/useMessagesContext'
import { authService } from '../../services/http/auth.service'
import { PageNavigated } from '../../services/store/actions'
import { AppLogo } from '../AppLogo/AppLogo'
import { Avatar } from '../Avatar/Avatar'
import { Badge } from '../Badge/Badge'
import './TopBar.scss'

export function TopBar() {
    const { currentPage } = usePage()
    const {
        unreadCount: unreadMessagesCount,
    } = useMessagesContext().messageThreads
    const { patientName, patientFirstName, familyMembers } = useFamilyMember()

    const canShowSwitchMember = useMemo(() => familyMembers?.length > 1, [
        familyMembers,
    ])

    const dispatchPageNavigatedAction = (_currentPage: PAGE) => {
        PageNavigated.dispatch(_currentPage)
    }

    return (
        <div className="top-bar-wrapper d-none d-md-flex">
            <AppLogo />

            <div className="navigation-wrapper max-content-width">
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.APPOINTMENTS ? ' active' : '')
                    }
                    onClick={() =>
                        dispatchPageNavigatedAction(PAGE.APPOINTMENTS)
                    }
                >
                    <span>Appointments</span>
                </div>
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.MESSAGES ? ' active' : '')
                    }
                    onClick={() => dispatchPageNavigatedAction(PAGE.MESSAGES)}
                >
                    <span>Messages</span>
                    <Badge value={unreadMessagesCount} offsetRight={-6} />
                </div>
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.ACCOUNT ? ' active' : '')
                    }
                    onClick={() => dispatchPageNavigatedAction(PAGE.ACCOUNT)}
                >
                    <span>Account</span>
                </div>
            </div>

            <div className="d-flex align-items-center">
                {canShowSwitchMember && (
                    <div
                        className="patient-block mr-3"
                        onClick={() => onOpenSwitchMemberModal()}
                    >
                        {patientName && <Avatar name={patientName} />}
                        <div className="font-weight-500 ml-2">
                            {patientFirstName}
                        </div>
                    </div>
                )}

                <div
                    className="logout-button-wrapper"
                    onClick={authService.signout}
                >
                    <i className="material-icons color-text-secondary">
                        exit_to_app
                    </i>
                </div>
            </div>
        </div>
    )
}

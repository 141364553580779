import { useSelector } from 'react-redux'
import { appSelector } from '../../services/store/selectors/appSelector'

export enum PAGE {
    APPOINTMENTS = 'Appointments',
    MESSAGES = 'Messages',
    ACCOUNT = 'Account',
}

export const usePage = () => {
    const state = useSelector(appSelector)

    return {
        currentPage: state.currentPage,
    }
}

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import {
    authService,
    storePatient,
    UpdateProfilePayload,
} from '../../../../services/http/auth.service'
import { Patient } from '../../../../services/models/Patient.model'
import { PatientUpdated } from '../../../../services/store/actions'
import { PocketPillsForm } from '../../../../UI/PocketPillsForm/PocketPillsForm'
import { onOpenToast } from '../../../../UI/Toast/Toast'
import { usePatient } from '../../../hooks/usePatient'

interface FormObj {
    pocketPillsEnabled: boolean
}

export function PrescriptionHomeDelivery() {
    const { patient } = usePatient()
    const { handleSubmit, control, setValue } = useForm()
    const [form, setForm] = useState<FormObj | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!patient) {
            return
        }

        const pocketPillsEnabled = patient.settings.pocketPillsEnabled
        setValue('pocketPillsEnabled', pocketPillsEnabled)

        const _form = {
            pocketPillsEnabled,
        }
        setForm(_form)
    }, [patient, setValue])

    const onSubmit = (data: FormObj) => {
        if (!patient) {
            return
        }

        const payload: UpdateProfilePayload = {
            email: patient.email,
            firstName: patient.firstName,
            lastName: patient.lastName,
            gender: patient.gender,
            birthday: moment(patient.birthday).format('YYYY-MM-DD'),
            healthCardProvince: patient.healthCardProvince || '',
            healthCardVersion: patient.healthCardVersion || '',
            settings: {
                pocketPillsEnabled: data.pocketPillsEnabled,
            },
        }
        if (patient.healthCardNumber) {
            payload.healthCardNumber = +patient.healthCardNumber
        }

        let _patient: Patient | null = null
        if (patient) {
            _patient = {
                ...patient,
                ...payload,
            }
        }

        setIsLoading(true)
        authService
            .updateProfile(payload)
            .then(
                () => {
                    onOpenToast('Updated', 'success')

                    if (_patient) {
                        // Update patient object in localStorage
                        storePatient(_patient)

                        // Update patient in redux store
                        PatientUpdated.dispatch(_patient)
                    }
                },
                () => {
                    onOpenToast('Unable to Update', 'error')
                }
            )
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Col md={12}>
                        <div className="font-weight-500 mb-4">
                            Prescription - Home Delivery
                        </div>
                    </Col>
                </Form.Row>

                <Controller
                    control={control}
                    name="pocketPillsEnabled"
                    tabIndex={10}
                    defaultValue={form?.pocketPillsEnabled ? true : false}
                    render={({ onChange, value }) => (
                        <PocketPillsForm
                            isSelected={value}
                            onSelected={(isSelected: boolean) => {
                                onChange(isSelected)
                            }}
                        />
                    )}
                />

                <Form.Row className="mt-5">
                    <Col md={12} className="text-center">
                        <input
                            className={
                                'width-unset primary ' +
                                (isLoading ? ' is-loading' : '')
                            }
                            type="submit"
                            value="Save"
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    )
}

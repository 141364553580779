import React from 'react'
import './IconButton.scss'

interface Props {
    materialIcon: string
    materialIconColor?:
        | 'color-text'
        | 'color-text-secondary'
        | 'color-text-hint'
        | 'color-blue'
        | 'color-green'
        | 'color-red'
    classes?: string
    showBgColor?: boolean
    onClick?: () => void
}

export function IconButton(props: Props) {
    return (
        <div
            className={
                'icon-button-wrapper ' +
                (props.classes ?? '') +
                (props.onClick ? ' hover' : '')
            }
            onClick={() => {
                props.onClick && props.onClick()
            }}
        >
            <i
                className={
                    'material-icons md-20 ' +
                    (props.materialIconColor ?? 'color-text-secondary')
                }
            >
                {props.materialIcon}
            </i>
        </div>
    )
}

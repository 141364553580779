import { store } from '../../App'
import { PAGE } from '../../main/hooks/usePage'
import { Client } from '../models/Client.model'
import { FamilyMember, Patient } from '../models/Patient.model'
import { Provider } from '../models/Provider.model'

export class PatientLoaded {
    static readonly type = 'PatientLoaded'
    static dispatch = (patient: Patient) =>
        dispatch(PatientLoaded.type, patient)
}

export class PatientUpdated {
    static readonly type = 'PatientUpdated'
    static dispatch = (patient: Patient) =>
        dispatch(PatientUpdated.type, patient)
}

export class ClientsLoaded {
    static readonly type = 'ClientsLoaded'
    static dispatch = (clients: Client[]) =>
        dispatch(ClientsLoaded.type, clients)
}

export class ClientProvidersLoaded {
    static readonly type = 'ClientProvidersLoaded'
    static dispatch = (clientName: string, providers: Provider[]) =>
        dispatch(ClientProvidersLoaded.type, {
            clientName,
            providers,
        })
}

export class FamilyMemberIDSelected {
    static readonly type = 'FamilyMemberIDSelected'
    static dispatch = (familtyMemberID: number | null) =>
        dispatch(FamilyMemberIDSelected.type, familtyMemberID)
}

export class FamilyMembersLoaded {
    static readonly type = 'FamilyMembersLoaded'
    static dispatch = (familyMembers: FamilyMember[]) =>
        dispatch(FamilyMembersLoaded.type, familyMembers)
}

export class FamilyMemberAdded {
    static readonly type = 'FamilyMemberAdded'
    static dispatch = (familyMember: FamilyMember) =>
        dispatch(FamilyMemberAdded.type, familyMember)
}

export class FamilyMemberUpdated {
    static readonly type = 'FamilyMemberUpdated'
    static dispatch = (familyMember: FamilyMember) =>
        dispatch(FamilyMemberUpdated.type, familyMember)
}

export class PageNavigated {
    static readonly type = 'PageNavigated'
    static dispatch = (currentPage: PAGE) =>
        dispatch(PageNavigated.type, currentPage)
}

const dispatch = (type: string, payload?: any) => {
    store.dispatch({
        type,
        payload,
    })
}

import React from 'react'
import { IconLabel } from '../../../../UI/IconLabel/IconLabel'
import { onOpenToast } from '../../../../UI/Toast/Toast'
import { FILE_ACCEPTS, MAX_FILE_SIZE } from '../../../constants'

interface Props {
    onFilesSelected: (files: File[]) => void
}

export function FileUploader(props: Props) {
    const onFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let files: File[] =
            Array.from(event.target.files || []).map((file: File) => file) || []

        files = files.reduce((array: File[], file: File) => {
            if (!isFileTypeAcceptable(file)) {
                onOpenToast('File type not accepted', 'error')
            } else if (!isFileSizeAcceptable(file)) {
                onOpenToast(
                    `File size is greater than ${
                        MAX_FILE_SIZE / 1024 / 1024
                    }MB`,
                    'error'
                )
            } else {
                array.push(file)
            }

            return array
        }, [])

        props.onFilesSelected(files)
    }

    return (
        <div className="file-uploader-wrapper">
            <IconLabel
                label="Upload Attachment"
                materialIcon="add"
                color="color-text"
            />
            <input
                type="file"
                multiple
                onChange={onFilesChange}
                accept={FILE_ACCEPTS}
            />
        </div>
    )
}

const isFileTypeAcceptable = (file: File): boolean => {
    return file.type?.length > 0 && FILE_ACCEPTS.indexOf(file.type) !== -1
}

const isFileSizeAcceptable = (file: File): boolean => {
    return file.size <= MAX_FILE_SIZE
}

import React from 'react'
import { Features } from './Features'
import { Footer } from './Footer'
import { Header } from './Header'
import { Hero } from './Hero'
import { HowItWorks } from './HowItWorks'
import './Landing.scss'

export function Landing() {
    return (
        <div className="landing-wrapper">
            <Header />
            <Hero />

            <div className="sections-wrapper">
                <HowItWorks />
                <Features />
                <Footer />
            </div>
        </div>
    )
}

import { useCallback, useContext, useMemo } from 'react'
import { Provider } from '../models/Provider.model'
import { AppointmentsContext } from './useAppointmentsContext'

export const useProvider = () => {
    const appointmentsContext = useContext(AppointmentsContext)

    const providers = useMemo((): Provider[] => {
        return appointmentsContext.state.providers || []
    }, [appointmentsContext.state.providers])

    const getProviderByIDAndClientName = useCallback(
        (providerID: number, clientName: string): Provider | null =>
            appointmentsContext.state.providers?.find(
                (i: Provider) =>
                    i.providerNo === providerID && i.clientName === clientName
            ) || null,
        [appointmentsContext.state.providers]
    )

    return {
        providers,
        getProviderByIDAndClientName,
    }
}

import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { patientmessagingService } from '../../../../services/http/patientmessaging.service'
import { Attachment, Message } from '../../../../services/models/Message.model'
import { utilsService } from '../../../../services/utils.service'
import { Avatar } from '../../../../UI/Avatar/Avatar'
import { MOMENT_DATETIME_FORMAT } from '../../../constants'
import { useFamilyMember } from '../../../hooks/useFamilyMember'
import { useProviderIndex } from '../../../hooks/useProviderIndex'
import { Acknowledge } from './Acknowledge'
import { FileAttachment } from './FileAttachment'
import { Reply } from './Reply'

interface Props {
    demographicID: number
    message: Message
    onMessageUpdated: (message: Message) => void
}

export function MessageItem(props: Props) {
    const { getProviderByClientName } = useProviderIndex()
    const { patientName, getMemberClinicByClientName } = useFamilyMember()

    const memberClinicID = useMemo((): number | null => {
        return getMemberClinicByClientName(props.message.clientName)?.id || null
    }, [props.message.clientName, getMemberClinicByClientName])

    useEffect(() => {
        if (
            moment(props.message.readAt).isBefore(
                moment(props.message.sentAt)
            ) &&
            memberClinicID
        ) {
            const message: Message = {
                ...props.message,
                readAt: new Date(),
            }

            patientmessagingService
                .markAsRead(message.id, message.readAt!, memberClinicID)
                .then(() => {
                    props.onMessageUpdated(message)
                })
        }
    }, [props, memberClinicID])

    const provider = useMemo(
        () =>
            getProviderByClientName(
                props.message.sendingProvider,
                props.message.clientName
            ),
        [props.message, getProviderByClientName]
    )

    const showAcknowledge = useMemo((): boolean => {
        return !props.message.isResponseAllowed
    }, [props.message])

    const showReply = useMemo((): boolean => {
        return (
            props.message.isResponseAllowed &&
            !props.message.patientMessage?.length &&
            moment(props.message.replyAt).isBefore(moment(props.message.sentAt))
        )
    }, [props.message])

    const onMessageUpdated = (message: Message) => {
        props.onMessageUpdated(message)
    }

    return (
        <div className="message-item-wrapper">
            {/* Provider's message */}
            <div className="message-item-inner d-flex align-items-start">
                <div className="d-none d-md-block">
                    <Avatar
                        classes="mr-3"
                        name={provider?.fullName || 'Provider'}
                    />
                </div>
                <div className="w-100">
                    <div className="mt-2 d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className="d-block d-md-none">
                                <Avatar
                                    classes="mr-3"
                                    name={provider?.fullName || 'Provider'}
                                />
                            </div>
                            <div className="font-weight-500">
                                {provider?.fullName || 'Provider'}
                            </div>
                        </div>
                        <div className="color-text-secondary text-right">
                            {moment(props.message.sentAt).format(
                                MOMENT_DATETIME_FORMAT
                            )}
                        </div>
                    </div>
                    <div
                        className="whitespace-pre-line mt-4"
                        dangerouslySetInnerHTML={{
                            __html: utilsService.urlify(
                                props.message.providerMessage
                            ),
                        }}
                    ></div>

                    {props.message.providerAttachments &&
                        props.message.providerAttachments?.length > 0 && (
                            <div className="mt-5 d-flex flex-wrap">
                                {props.message.providerAttachments.map(
                                    (attachment: Attachment) => (
                                        <FileAttachment
                                            key={attachment.id}
                                            filename={attachment.fileName}
                                            attachmentID={attachment.id}
                                            clientName={
                                                props.message.clientName
                                            }
                                            demographicID={props.demographicID}
                                        />
                                    )
                                )}
                            </div>
                        )}

                    {showAcknowledge && (
                        <div className="mt-5">
                            <Acknowledge
                                message={props.message}
                                onMessageUpdated={onMessageUpdated}
                            />
                        </div>
                    )}

                    {showReply && (
                        <div className="mt-5">
                            <Reply
                                message={props.message}
                                onMessageUpdated={onMessageUpdated}
                            />
                        </div>
                    )}
                </div>
            </div>
            {props.message.patientMessage &&
                props.message.patientMessage?.length > 0 && (
                    <div className="message-item-inner d-flex align-items-start background-content-background-light">
                        <div className="d-none d-md-block">
                            {patientName && (
                                <Avatar classes="mr-3" name={patientName} />
                            )}
                        </div>
                        <div className="w-100">
                            <div className="mt-2 d-flex flex-column flex-md-row align-items-stretch align-items-md-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <div className="d-block d-md-none">
                                        {patientName && (
                                            <Avatar
                                                classes="mr-3"
                                                name={patientName}
                                            />
                                        )}
                                    </div>
                                    <div className="font-weight-500">
                                        <span>{patientName}</span>
                                        <span className="color-blue">
                                            <span>&nbsp;·&nbsp;</span>
                                            <span>You</span>
                                        </span>
                                    </div>
                                </div>
                                <div className="color-text-secondary text-right">
                                    {moment(props.message.replyAt).format(
                                        MOMENT_DATETIME_FORMAT
                                    )}
                                </div>
                            </div>

                            <div
                                className="whitespace-pre-line mt-4"
                                dangerouslySetInnerHTML={{
                                    __html: utilsService.urlify(
                                        props.message.patientMessage
                                    ),
                                }}
                            ></div>

                            {props.message.patientAttachments &&
                                props.message.patientAttachments?.length >
                                    0 && (
                                    <div className="mt-4 d-flex flex-wrap">
                                        {props.message.patientAttachments.map(
                                            (attachment: Attachment) => (
                                                <FileAttachment
                                                    key={attachment.id}
                                                    filename={
                                                        attachment.fileName
                                                    }
                                                    attachmentID={attachment.id}
                                                    clientName={
                                                        props.message.clientName
                                                    }
                                                    demographicID={
                                                        props.demographicID
                                                    }
                                                />
                                            )
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                )}
        </div>
    )
}

export class Provider {
    providerID!: number
    firstName!: string
    lastName!: string
    fullName!: string
    clientName!: string
    createdAt!: Date
}

export function deserializeProvider(obj: any): Provider {
    const model: Provider = new Provider()
    model.providerID = obj.providerID
    model.firstName = obj.firstName
    model.lastName = obj.lastName
    if (obj.fullName) {
        model.fullName = obj.fullName
    } else {
        model.fullName = [obj.firstName, obj.lastName].join(' ')
    }
    model.clientName = obj.clientName
    if (obj.createdAt) {
        model.createdAt = new Date(obj.createdAt)
    }
    return model
}

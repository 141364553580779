import React from 'react'
import { PAGE, usePage } from '../../main/hooks/usePage'
import { useMessagesContext } from '../../main/messages/hooks/useMessagesContext'
import { authService } from '../../services/http/auth.service'
import { PageNavigated } from '../../services/store/actions'
import { Badge } from '../Badge/Badge'
import './NavigationBar.scss'

export function NavigationBar() {
    const { currentPage } = usePage()
    const {
        unreadCount: unreadMessagesCount,
    } = useMessagesContext().messageThreads

    const dispatchPageNavigatedAction = (_currentPage: PAGE) => {
        PageNavigated.dispatch(_currentPage)
    }

    return (
        <div className="navigation-bar-wrapper d-flex d-md-none">
            <div className="navigation-wrapper">
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.APPOINTMENTS ? ' active' : '')
                    }
                    onClick={() =>
                        dispatchPageNavigatedAction(PAGE.APPOINTMENTS)
                    }
                >
                    <i className="material-icons color-text-secondary">event</i>
                </div>
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.MESSAGES ? ' active' : '')
                    }
                    onClick={() => dispatchPageNavigatedAction(PAGE.MESSAGES)}
                >
                    <i className="material-icons color-text-secondary">mail</i>
                    <Badge
                        value={unreadMessagesCount}
                        offsetTop={-6}
                        offsetRight={4}
                    />
                </div>
                <div
                    className={
                        'navigation-item' +
                        (currentPage === PAGE.ACCOUNT ? ' active' : '')
                    }
                    onClick={() => dispatchPageNavigatedAction(PAGE.ACCOUNT)}
                >
                    <i className="material-icons color-text-secondary">
                        person
                    </i>
                </div>
                <div className="navigation-item" onClick={authService.signout}>
                    <i className="material-icons color-text-secondary">
                        exit_to_app
                    </i>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom'
import { ROUTES } from '../App'
import { ForgotPassword } from './components/ForgotPassword/ForgotPassword'
import { ResetPassword } from './components/ResetPassword/ResetPassword'
import { SignIn } from './components/SignIn/SignIn'
import { SignUp } from './components/SignUp/SignUp'
import { SignUpSuccess } from './components/SignUpSuccess/SignUpSuccess'
import { VerifyEmail } from './components/VerifyEmail/VerifyEmail'

export function Authentication() {
    const { path } = useRouteMatch()

    return (
        <Router>
            <Switch>
                <Route path={path + ROUTES.SIGNIN}>
                    <SignIn />
                </Route>
                <Route path={path + ROUTES.SIGNUP}>
                    <SignUp />
                </Route>
                <Route path={path + ROUTES.SIGNUP_SUCCESS}>
                    <SignUpSuccess />
                </Route>
                <Route path={path + ROUTES.FORGOT_PASSWORD}>
                    <ForgotPassword />
                </Route>
                <Route path={path + ROUTES.RESET_PASSWORD + '/:secret'}>
                    <ResetPassword />
                </Route>
                <Route path={path + ROUTES.VERIFY_EMAIL + '/:secret'}>
                    <VerifyEmail />
                </Route>
                <Route path={path}>
                    <Redirect to={path + ROUTES.SIGNIN} />
                </Route>
            </Switch>
        </Router>
    )
}

import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Patient } from '../../services/models/Patient.model'
import { appSelector } from '../../services/store/selectors/appSelector'

export const usePatient = () => {
    const state = useSelector(appSelector)

    const patient = useMemo((): Patient | null => {
        return state.patient
    }, [state.patient])

    return {
        patient,
    }
}

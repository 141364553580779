import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ROUTES } from '../../../App'
import { reminderService } from '../../../services/http/reminder.service'
import { PageCard } from '../../UI/PageCard/PageCard'

export function ConfirmAppointment() {
    const { id } = useParams<any>()
    if (!id) {
        window.location.href =
            process.env.REACT_APP_BASE_HREF + ROUTES.AUTH + ROUTES.SIGNIN
    }

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

    useEffect(() => {
        setIsLoading(true)
        reminderService
            .confirmAppointment(id)
            .then(() => {
                setIsSuccessful(true)
            })
            .catch(() => {
                setIsSuccessful(false)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [id])

    return !isLoading ? (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <PageCard
                title={isSuccessful ? 'Appointment Confirmed!' : 'Whoops!'}
                subtitle={
                    isSuccessful
                        ? 'Your appointment has been successfully confirmed. You may close this window.'
                        : 'An error has occurred. Please try again later.'
                }
            />
        </div>
    ) : (
        <div className="h-100 d-flex align-items-center justify-content-center is-loading"></div>
    )
}

export class BookedAppointment {
    appointmentID!: number
    demographicID!: number
    clientName!: string
    startAt!: Date
    endAt!: Date
    appointmentType!: number
    providerID!: number
    reason!: string
}

export function deserializeBookedAppointment(obj: any): BookedAppointment {
    const model: BookedAppointment = Object.assign({}, obj)
    model.appointmentID = obj.appointmentID ?? obj.id
    if (obj.startAt) {
        model.startAt = new Date(obj.startAt)
    }
    if (obj.endAt) {
        model.endAt = new Date(obj.endAt)
    }
    model.appointmentType = obj.appointmentType ?? obj.typeID ?? 0
    return model
}

import React, { useEffect, useState } from 'react'
import './Avatar.scss'

interface Props {
    name?: string
    classes?: string
}

const BRIGHTNESS_THRESHOLD = 150
const COLOR_FONT_LIGHT = 'color-white'
const COLOR_FONT_DARK = 'color-text-secondary'
const DEFAULT_NAME = 'N/A'

const getInitials = (name: string): string => {
    if (!name?.length) {
        return DEFAULT_NAME
    }

    const nameParts = name.split(' ')
    if (nameParts.length >= 2) {
        return nameParts[0][0] + nameParts[1][0]
    } else {
        if (nameParts[0].length >= 2) {
            return nameParts[0][0] + nameParts[0][1]
        } else {
            return nameParts[0][0] + nameParts[0][0]
        }
    }
}

const generateColour = (value: string) => {
    let hash = 0
    for (let i = 0; i < value.length; i++) {
        // @ts-ignore
        hash = value.charCodeAt(i) + ((hash << 5) - hash)
    }

    let colour = '#'
    for (let i = 0; i < 3; i++) {
        // @ts-ignore
        const str = (hash >> (i * 8)) & 0xff
        colour += ('00' + str.toString(16)).substr(-2)
    }

    return colour
}

const getFontColour = (colour: string) => {
    if (getBrightness(colour) < BRIGHTNESS_THRESHOLD) {
        return COLOR_FONT_LIGHT
    } else {
        return COLOR_FONT_DARK
    }
}

const getBrightness = (colour: string): number => {
    const hex = colour.replace('#', '')
    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4), 16)
    const brightness = (r * 299 + g * 587 + b * 114) / 1000
    return brightness
}

export function Avatar(props: Props) {
    const [bgColour, setBGColour] = useState<string>('')
    const [fontColour, setFontColour] = useState<string>('color-text-secondary')

    useEffect(() => {
        if (!props.name?.length) {
            return
        }

        const _bgColour = generateColour(props.name)
        setBGColour(_bgColour)
        setFontColour(getFontColour(_bgColour))
    }, [props.name])

    return (
        <div
            className={`avatar-wrapper  ${props.classes ?? ''} ${fontColour}`}
            style={{ backgroundColor: `${bgColour}` }}
        >
            {getInitials(props.name!)}
        </div>
    )
}

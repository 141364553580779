import React, { useMemo } from 'react'
import { FamilyMember } from '../../../../services/models/Patient.model'
import { Avatar } from '../../../../UI/Avatar/Avatar'
import { useFamilyMember } from '../../../hooks/useFamilyMember'

interface Props {
    familyMember: FamilyMember
    status?: string
    onClick?: () => void
}

export function Member(props: Props) {
    const { getPatientNameByFamilyMember } = useFamilyMember()

    const patientName = useMemo(
        (): string => getPatientNameByFamilyMember(props.familyMember),
        [props.familyMember, getPatientNameByFamilyMember]
    )

    const Wrapper = ({ children }: any) =>
        props.onClick ? (
            <div
                className="member-wrapper cursor-pointer"
                onClick={props.onClick}
            >
                {children}
            </div>
        ) : (
            <div className="member-wrapper">{children}</div>
        )

    return (
        <Wrapper>
            <div className="d-flex align-items-center">
                <Avatar name={patientName} />
                <div className="ml-3">
                    <div className="font-weight-500">{patientName}</div>
                </div>
            </div>

            {props.status && (
                <div className="color-text-hint">{props.status}</div>
            )}
        </Wrapper>
    )
}

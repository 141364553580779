import { ErrorMessage } from '@hookform/error-message'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Link, useHistory } from 'react-router-dom'
import { ROUTES } from '../../../App'
import useHttp from '../../../services/hooks/useHttp/useHttp'
import {
    AUTHREGEX_DATE,
    AUTHREGEX_EMAIL,
    AUTHREGEX_PASSWORD_STRENGTH,
    authService,
    SignUpPayload,
    UuidSignUpPayload,
    VerifySignUpPayload,
} from '../../../services/http/auth.service'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'
import { GenderField } from '../../../UI/GenderField/GenderField'
import { HealthCardProvinceField } from '../../../UI/HealthCardProvinceField/HealthCardProvinceField'
import { PocketPillsForm } from '../../../UI/PocketPillsForm/PocketPillsForm'
import { Toggle } from '../../../UI/Toggle/Toggle'

enum REQUEST {
    VERIFY_UUID,
    UUID_SIGNUP,
    SIGNUP,
    SIGNUP_VERIFY,
}

enum FORM_STEP {
    CREDENTAILS,
    DEMOGRAPHIC,
    HIN,
    PRESCRIPTION,
}

const DEFAULT_HAS_HIN = true
const DEFAULT_PROVINCE = 'CA-ON'

interface FormObj {
    email: string
    password: string
    firstName: string
    lastName: string
    birthday: string
    gender: string
    healthCardNumber: number | null
    healthCardVersion: string | null
    healthCardProvince: string | null
    pocketPillsEnabled: boolean
}

const initialForm: FormObj = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: '',
    healthCardNumber: null,
    healthCardVersion: null,
    healthCardProvince: DEFAULT_PROVINCE,
    pocketPillsEnabled: true,
}

export function SignUp() {
    const {
        register,
        setValue,
        handleSubmit,
        errors,
        watch,
        control,
        setError,
        clearErrors,
    } = useForm()
    const { push } = useHistory()
    const uuid = getParams('uuid')
    const [form, setForm] = useState<FormObj>(initialForm)
    const formRef = useRef<FormObj>(form)
    const [formStep, setFormStep] = useState<FORM_STEP>(FORM_STEP.CREDENTAILS)
    const [hasHIN, setHasHin] = useState<boolean>(DEFAULT_HAS_HIN)
    const [isUuidSignUp, setIsUuidSignUp] = useState<boolean>(false)
    const [isEmailPrefilled, setIsEmailPrefilled] = useState<boolean>(false)
    const [firstNamePrefilled, setFirstNamePrefilled] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        httpLoading,
        httpResponseData,
        httpErrorData,
        httpRequestIdentifier,
        httpSendRequest,
    } = useHttp()

    useEffect(() => {
        if (!!uuid) {
            httpSendRequest(
                authService.verifyUuid.bind({}, uuid),
                REQUEST.VERIFY_UUID
            )
        }
    }, [uuid, httpSendRequest])

    useEffect(() => {
        formRef.current = form
    }, [form])

    useEffect(() => {
        if (!httpLoading) {
            if (httpRequestIdentifier === REQUEST.VERIFY_UUID) {
                // If successful, prefill fields associated with uuid
                if (httpResponseData) {
                    if (httpResponseData.isAvailable) {
                        let email = httpResponseData.email
                        if (email?.length) {
                            email = email.trim()
                        }
                        const firstName = httpResponseData.firstName
                        setForm({
                            ...formRef.current,
                            email: email ?? '',
                            firstName: firstName ?? '',
                        })
                        setValue('email', email ?? '')
                        setValue('firstName', firstName ?? '')
                        setIsEmailPrefilled(!!email)
                        setFirstNamePrefilled(firstName)
                        setIsUuidSignUp(true)
                    } else if (httpResponseData.isAvailable === false) {
                        // If uuid is consumed, then redirect to sign in page
                        push(
                            process.env.REACT_APP_BASE_HREF +
                                ROUTES.AUTH +
                                ROUTES.SIGNIN
                        )
                    }
                } else {
                    // Else, redirect to error page
                    window.location.href =
                        process.env.REACT_APP_BASE_HREF + ROUTES.ERROR
                }
            } else if (httpRequestIdentifier === REQUEST.SIGNUP_VERIFY) {
                // If successful, then proceed to sign up
                if (httpResponseData) {
                    onSignUp(form)
                } else {
                    setError('form', {
                        type: 'manual',
                        message:
                            httpErrorData?.errorMessage ??
                            httpErrorData?.error ??
                            'Unable to verify user profile',
                    })
                    setFormStep(FORM_STEP.CREDENTAILS)
                }
            } else if (
                httpRequestIdentifier === REQUEST.SIGNUP ||
                httpRequestIdentifier === REQUEST.UUID_SIGNUP
            ) {
                // On successful signup, navigate to SIGNUP_SUCCESS page
                if (httpResponseData) {
                    push(
                        process.env.REACT_APP_BASE_HREF +
                            ROUTES.AUTH +
                            ROUTES.SIGNUP_SUCCESS
                    )
                } else if (httpErrorData) {
                    // On unsuccessful signup, set error
                    if (httpErrorData?.error?.includes('already signed up')) {
                        setError('email', {
                            type: 'manual',
                            message: 'This email has already been taken',
                        })
                        setFormStep(FORM_STEP.CREDENTAILS)
                    } else {
                        setError('form', {
                            type: 'manual',
                            message:
                                'Unable to complete registration, please try again later',
                        })
                    }
                }
            }
        }
        setIsLoading(httpLoading)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        httpLoading,
        httpResponseData,
        httpRequestIdentifier,
        httpErrorData,
        setError,
        setValue,
        push,
    ])

    useEffect(() => {
        if (hasHIN === false) {
            if (formRef.current) {
                setForm({
                    ...formRef.current,
                    healthCardNumber: null,
                    healthCardVersion: null,
                    healthCardProvince: null,
                })
            }
            setValue('healthCardNumber', '')
            setValue('healthCardVersion', '')
            setValue('healthCardProvince', '')
            clearErrors('form')
        } else if (hasHIN === true) {
            if (formRef.current) {
                setForm({
                    ...formRef.current,
                    healthCardProvince: DEFAULT_PROVINCE,
                })
            }
            setValue('healthCardProvince', DEFAULT_PROVINCE)
            clearErrors('form')
        }
        clearErrors()
    }, [hasHIN, setValue, clearErrors])

    const onFormStepNext = (data: FormObj) => {
        const _form = {
            ...form,
        }
        Object.keys(data as any).forEach((key: string) => {
            // @ts-ignore
            if (data[key] !== undefined) {
                // @ts-ignore
                _form[key] = data[key]
            }
        })
        setForm(_form)

        if (formStep === FORM_STEP.PRESCRIPTION) {
            if (isUuidSignUp) {
                onSignUpVerify(_form)
            } else {
                onSignUp(_form)
            }
            return
        }

        if (isUuidSignUp && formStep === FORM_STEP.CREDENTAILS) {
            setFormStep(FORM_STEP.PRESCRIPTION)
        } else {
            setFormStep(formStep + 1)
        }

        clearErrors()
    }

    const onFormStepPrev = (_formStep: FORM_STEP) => {
        if (_formStep === FORM_STEP.CREDENTAILS) {
            setFormStep(FORM_STEP.CREDENTAILS)
            return
        }

        if (isUuidSignUp && formStep === FORM_STEP.PRESCRIPTION) {
            setFormStep(FORM_STEP.CREDENTAILS)
        } else {
            setFormStep(_formStep - 1)
        }

        clearErrors('form')
    }

    const onSignUpVerify = (_form: FormObj) => {
        if (isUuidSignUp) {
            const payload: VerifySignUpPayload = {
                uuid: uuid!,
                email: _form.email,
                dateOfBirth: _form.birthday + 'T00:00:00.000Z',
            }
            httpSendRequest(
                authService.verifySignUp.bind({}, payload),
                REQUEST.SIGNUP_VERIFY
            )
        } else {
            onSignUp(_form)
        }
    }

    const onSignUp = (_form: FormObj) => {
        if (isUuidSignUp) {
            const payload: UuidSignUpPayload = {
                uuid: uuid!,
                email: _form.email,
                password: _form.password,
                birthday: _form.birthday + 'T00:00:00.000Z',
                settings: {
                    pocketPillsEnabled: _form.pocketPillsEnabled,
                },
            }
            httpSendRequest(
                authService.uuidSignup.bind({}, payload),
                REQUEST.UUID_SIGNUP
            )
        } else {
            const payload: SignUpPayload = {
                email: _form.email,
                password: _form.password,
                firstName: _form.firstName,
                lastName: _form.lastName,
                birthday: _form.birthday + 'T00:00:00.000Z',
                gender: _form.gender,
                settings: {
                    pocketPillsEnabled: _form.pocketPillsEnabled,
                },
            }
            if (_form.healthCardNumber) {
                payload.healthCardNumber = +_form.healthCardNumber
            }
            if (_form.healthCardVersion) {
                payload.healthCardVersion = _form.healthCardVersion
            }
            if (_form.healthCardProvince) {
                payload.healthCardProvince = _form.healthCardProvince
            }
            httpSendRequest(
                authService.signup.bind({}, payload),
                REQUEST.SIGNUP
            )
        }
    }

    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <AuthCard
                title={
                    !isUuidSignUp ? 'Sign Up' : firstNamePrefilled || 'Sign Up'
                }
                subtitle={
                    !isUuidSignUp
                        ? 'Enter your details below'
                        : 'Sign up with your details below'
                }
                children={
                    <React.Fragment>
                        {formStep === FORM_STEP.CREDENTAILS && (
                            <Form onSubmit={handleSubmit(onFormStepNext)}>
                                <Form.Row>
                                    <Col md={12}>
                                        <Form.Label>Email</Form.Label>
                                        <input
                                            className={
                                                'form-control' +
                                                (errors?.email?.message ||
                                                errors?.form?.message
                                                    ? ' invalid-state'
                                                    : '')
                                            }
                                            name="email"
                                            placeholder="Email"
                                            tabIndex={1}
                                            autoCapitalize={'off'}
                                            ref={register({
                                                required: 'Email is required',
                                                pattern: {
                                                    value: AUTHREGEX_EMAIL,
                                                    message:
                                                        'Invalid Email Address',
                                                },
                                            })}
                                            onChange={() => {
                                                clearErrors('form')
                                            }}
                                            onBlur={(
                                                event: React.ChangeEvent<HTMLInputElement>
                                            ) => {
                                                const email = event.target.value
                                                if (!email?.length) {
                                                    return
                                                }

                                                authService
                                                    .isEmailAvailable(
                                                        event.target.value
                                                    )
                                                    .then((isAvailable) => {
                                                        if (!isAvailable) {
                                                            setError('email', {
                                                                type: 'manual',
                                                                message:
                                                                    'This email has already been taken',
                                                            })
                                                        } else {
                                                            clearErrors([
                                                                'email',
                                                            ])
                                                        }
                                                    })
                                            }}
                                            defaultValue={form?.email}
                                            readOnly={isEmailPrefilled}
                                            disabled={isEmailPrefilled}
                                        />
                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="email"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                {isUuidSignUp && (
                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>
                                                Date of Birth (YYYY-MM-DD)
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="birthday"
                                                tabIndex={2}
                                                defaultValue={form?.birthday}
                                                rules={{
                                                    required:
                                                        'Date of Birth is required',
                                                    pattern: {
                                                        value: AUTHREGEX_DATE,
                                                        message:
                                                            'Invalid Date of Birth',
                                                    },
                                                }}
                                                render={({
                                                    onChange,
                                                    value,
                                                }) => (
                                                    <div className="w-100">
                                                        <InputMask
                                                            mask="9999-99-99"
                                                            alwaysShowMask={
                                                                true
                                                            }
                                                            value={value}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                onChange(event)
                                                                clearErrors(
                                                                    'form'
                                                                )
                                                            }}
                                                        >
                                                            {(
                                                                inputProps: any
                                                            ) => (
                                                                <input
                                                                    {...inputProps}
                                                                    className={
                                                                        'form-control uppercase min-height-39' +
                                                                        (errors
                                                                            ?.birthday
                                                                            ?.message ||
                                                                        errors
                                                                            ?.form
                                                                            ?.message
                                                                            ? ' invalid-state'
                                                                            : '')
                                                                    }
                                                                    name="birthday"
                                                                    type="text"
                                                                    tabIndex={6}
                                                                    placeholder="YYYY-MM-DD"
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                )}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="birthday"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>
                                )}

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <Form.Label>Password</Form.Label>
                                        <input
                                            className={
                                                'form-control' +
                                                (errors?.password?.message ||
                                                errors?.form?.message
                                                    ? ' invalid-state'
                                                    : '')
                                            }
                                            name="password"
                                            type="password"
                                            tabIndex={3}
                                            placeholder="Password"
                                            ref={register({
                                                required:
                                                    'Password is required',
                                                pattern: {
                                                    value: AUTHREGEX_PASSWORD_STRENGTH,
                                                    message:
                                                        'Use 8 or more characters with a mix of letters, numbers & symbols',
                                                },
                                            })}
                                            onChange={() => {
                                                clearErrors('form')
                                            }}
                                            defaultValue={form?.password}
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="password"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <Form.Label>
                                            Confirm Password
                                        </Form.Label>
                                        <input
                                            className={
                                                'form-control' +
                                                (errors?.confirmPassword
                                                    ?.message ||
                                                errors?.form?.message
                                                    ? ' invalid-state'
                                                    : '')
                                            }
                                            name="confirmPassword"
                                            type="password"
                                            tabIndex={4}
                                            placeholder="Confirm Password"
                                            ref={register({
                                                required:
                                                    'Confirm Password is required',
                                                validate: (value) =>
                                                    value ===
                                                        watch('password') ||
                                                    'Passwords do not match',
                                            })}
                                            onChange={() => {
                                                clearErrors('form')
                                            }}
                                            defaultValue={form?.password}
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="confirmPassword"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <NextButton
                                            isLoading={isLoading}
                                            currentStep={FORM_STEP.CREDENTAILS}
                                            label="Next"
                                            stepsCount={!isUuidSignUp ? 4 : 2}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                        {formStep === FORM_STEP.DEMOGRAPHIC && (
                            <Form onSubmit={handleSubmit(onFormStepNext)}>
                                <Form.Row>
                                    <Col md={12}>
                                        <Form.Label>First Name</Form.Label>
                                        <input
                                            className={
                                                'form-control' +
                                                (errors?.firstName?.message ||
                                                errors?.form?.message
                                                    ? ' invalid-state'
                                                    : '')
                                            }
                                            name="firstName"
                                            type="text"
                                            tabIndex={5}
                                            placeholder="First Name"
                                            ref={register({
                                                required:
                                                    'First Name is required',
                                            })}
                                            onChange={() => {
                                                clearErrors('form')
                                            }}
                                            defaultValue={form?.firstName}
                                            readOnly={
                                                firstNamePrefilled?.length > 0
                                            }
                                            disabled={
                                                firstNamePrefilled?.length > 0
                                            }
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="firstName"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <Form.Label>Last Name</Form.Label>
                                        <input
                                            className={
                                                'form-control' +
                                                (errors?.lastName?.message ||
                                                errors?.form?.message
                                                    ? ' invalid-state'
                                                    : '')
                                            }
                                            name="lastName"
                                            type="text"
                                            tabIndex={6}
                                            placeholder="Last Name"
                                            ref={register({
                                                required:
                                                    'Last Name is required',
                                            })}
                                            onChange={() => {
                                                clearErrors('form')
                                            }}
                                            defaultValue={form?.lastName}
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="lastName"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <Form.Label>
                                            Date of Birth (YYYY-MM-DD)
                                        </Form.Label>

                                        <Controller
                                            control={control}
                                            name="birthday"
                                            tabIndex={7}
                                            defaultValue={form?.birthday}
                                            rules={{
                                                required:
                                                    'Date of Birth is required',
                                                pattern: {
                                                    value: AUTHREGEX_DATE,
                                                    message:
                                                        'Invalid Date of Birth',
                                                },
                                            }}
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <InputMask
                                                        mask="9999-99-99"
                                                        alwaysShowMask={true}
                                                        value={value}
                                                        onChange={(event) => {
                                                            onChange(event)
                                                            clearErrors('form')
                                                        }}
                                                    >
                                                        {(inputProps: any) => (
                                                            <input
                                                                {...inputProps}
                                                                className={
                                                                    'form-control uppercase min-height-39' +
                                                                    (errors
                                                                        ?.birthday
                                                                        ?.message ||
                                                                    errors?.form
                                                                        ?.message
                                                                        ? ' invalid-state'
                                                                        : '')
                                                                }
                                                                name="birthday"
                                                                type="text"
                                                                tabIndex={6}
                                                                placeholder="YYYY-MM-DD"
                                                            />
                                                        )}
                                                    </InputMask>
                                                </div>
                                            )}
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="birthday"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <Form.Label>Gender</Form.Label>

                                        <Controller
                                            control={control}
                                            name="gender"
                                            rules={{
                                                required: 'Gender is required',
                                            }}
                                            tabIndex={8}
                                            defaultValue={form?.gender}
                                            render={({ onChange, value }) => (
                                                <GenderField
                                                    classes={
                                                        errors?.gender
                                                            ?.message ||
                                                        errors?.form?.message
                                                            ? ' invalid-state'
                                                            : ''
                                                    }
                                                    initialValue={value}
                                                    onChange={onChange}
                                                />
                                            )}
                                        />

                                        <ErrorMessage
                                            className="color-red mt-1"
                                            errors={errors}
                                            name="gender"
                                            as="div"
                                        />
                                    </Col>
                                </Form.Row>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <NextButton
                                            isLoading={isLoading}
                                            currentStep={FORM_STEP.DEMOGRAPHIC}
                                            label="Next"
                                            stepsCount={!isUuidSignUp ? 4 : 2}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                        {formStep === FORM_STEP.HIN && (
                            <Form onSubmit={handleSubmit(onFormStepNext)}>
                                <Form.Row>
                                    <Form.Label className="d-flex align-items-center ml-2">
                                        <Toggle
                                            active={hasHIN}
                                            onClick={() => setHasHin(!hasHIN)}
                                        />
                                        <span className="ml-2">
                                            I have Health Card Insurance
                                        </span>
                                    </Form.Label>
                                </Form.Row>

                                <React.Fragment>
                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>
                                                Health Card Province
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="healthCardProvince"
                                                rules={{
                                                    required: hasHIN
                                                        ? 'Health Card Province is required'
                                                        : false,
                                                }}
                                                tabIndex={9}
                                                defaultValue={
                                                    form?.healthCardProvince ??
                                                    ''
                                                }
                                                disabled={hasHIN ? false : true}
                                                render={({
                                                    onChange,
                                                    value,
                                                }) => (
                                                    <HealthCardProvinceField
                                                        classes={
                                                            errors
                                                                ?.healthCardProvince
                                                                ?.message ||
                                                            errors?.form
                                                                ?.message
                                                                ? ' invalid-state'
                                                                : ''
                                                        }
                                                        initialValue={value}
                                                        onChange={onChange}
                                                        disabled={
                                                            hasHIN
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                )}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="healthCardProvince"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>
                                                Health Card Number
                                            </Form.Label>
                                            <input
                                                className={
                                                    'form-control' +
                                                    (errors?.healthCardNumber
                                                        ?.message ||
                                                    errors?.form?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="healthCardNumber"
                                                type="text"
                                                tabIndex={10}
                                                placeholder="Health Card Number"
                                                ref={register({
                                                    required: hasHIN
                                                        ? 'Health Card Number is required'
                                                        : false,
                                                    pattern: {
                                                        value: /^[0-9]{10}$/,
                                                        message:
                                                            'Must be 10 digits',
                                                    },
                                                    minLength: 10,
                                                    maxLength: 10,
                                                })}
                                                onChange={() => {
                                                    clearErrors('form')
                                                }}
                                                onBlur={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    const hin =
                                                        event.target.value
                                                    if (
                                                        !hasHIN ||
                                                        !hin?.length
                                                    ) {
                                                        return
                                                    }

                                                    authService
                                                        .isHinAvailable(
                                                            event.target.value
                                                        )
                                                        .then((isAvailable) => {
                                                            if (!isAvailable) {
                                                                setError(
                                                                    'healthCardNumber',
                                                                    {
                                                                        type:
                                                                            'manual',
                                                                        message:
                                                                            'This Health Card Number has already been taken',
                                                                    }
                                                                )
                                                            } else {
                                                                clearErrors([
                                                                    'healthCardNumber',
                                                                ])
                                                            }
                                                        })
                                                }}
                                                defaultValue={
                                                    form?.healthCardNumber ?? ''
                                                }
                                                disabled={hasHIN ? false : true}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="healthCardNumber"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>
                                                Health Card Version (Optional)
                                            </Form.Label>
                                            <input
                                                className={
                                                    'form-control' +
                                                    (errors?.healthCardVersion
                                                        ?.message ||
                                                    errors?.form?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="healthCardVersion"
                                                type="text"
                                                tabIndex={11}
                                                placeholder="XX"
                                                ref={register({
                                                    required: false,
                                                    minLength: {
                                                        value: 2,
                                                        message:
                                                            'Must be 2 characters',
                                                    },
                                                    maxLength: {
                                                        value: 2,
                                                        message:
                                                            'Must be 2 characters',
                                                    },
                                                    max: 2,
                                                })}
                                                onChange={() => {
                                                    clearErrors('form')
                                                }}
                                                defaultValue={
                                                    form?.healthCardVersion ??
                                                    ''
                                                }
                                                disabled={hasHIN ? false : true}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="healthCardVersion"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>
                                </React.Fragment>

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <NextButton
                                            isLoading={isLoading}
                                            currentStep={FORM_STEP.HIN}
                                            label="Next"
                                            stepsCount={!isUuidSignUp ? 4 : 2}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}

                        {formStep === FORM_STEP.PRESCRIPTION && (
                            <Form onSubmit={handleSubmit(onFormStepNext)}>
                                <Controller
                                    control={control}
                                    name="pocketPillsEnabled"
                                    tabIndex={10}
                                    defaultValue={form?.pocketPillsEnabled}
                                    render={({ onChange, value }) => (
                                        <PocketPillsForm
                                            classes="mb-5"
                                            isSelected={value}
                                            onSelected={(
                                                isSelected: boolean
                                            ) => {
                                                onChange(isSelected)
                                            }}
                                        />
                                    )}
                                />

                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <NextButton
                                            isLoading={isLoading}
                                            currentStep={!isUuidSignUp ? 3 : 1}
                                            label="Sign Up"
                                            stepsCount={!isUuidSignUp ? 4 : 2}
                                        />
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}

                        <ErrorMessage
                            className="color-red mt-3"
                            errors={errors}
                            name="form"
                            as="div"
                        />

                        <Form.Row className="mt-4">
                            <Col
                                md={12}
                                className="d-flex justify-content-between"
                            >
                                {formStep !== FORM_STEP.CREDENTAILS && (
                                    <PrevButton
                                        onClick={() => onFormStepPrev(formStep)}
                                    />
                                )}
                                <div></div>
                                <div className="color-text-hint">
                                    <Link
                                        className="color-text-hint font-weight-500 uppercase"
                                        to={
                                            process.env.REACT_APP_BASE_HREF +
                                            ROUTES.AUTH +
                                            ROUTES.SIGNIN
                                        }
                                    >
                                        <span>Cancel Sign Up</span>
                                    </Link>
                                </div>
                            </Col>
                        </Form.Row>
                    </React.Fragment>
                }
            />
        </div>
    )
}

function NextButton({
    isLoading,
    currentStep,
    label,
    stepsCount,
}: {
    isLoading: boolean
    currentStep: number
    label: string
    stepsCount: number
}) {
    return (
        <button
            type="button"
            className={
                'auth-card-next-button primary d-flex justify-content-between align-items-center' +
                (isLoading ? ' is-loading' : '')
            }
        >
            <input type="submit" />

            <div className="d-flex align-items-center">
                {new Array(stepsCount).fill(null).map((_, index: number) => (
                    <div
                        key={index}
                        className={
                            'form-step-indicator' +
                            (index === currentStep ? ' active' : '')
                        }
                    ></div>
                ))}
            </div>

            <div className="d-flex align-items-center">
                <span>{label}</span>
                <i className="material-icons md-20 ml-2">arrow_forward</i>
            </div>
        </button>
    )
}

function PrevButton({ onClick }: { onClick: () => void }) {
    return (
        <div
            className="d-flex align-items-center font-weight-500 uppercase color-blue cursor-pointer"
            onClick={onClick}
        >
            <i className="material-icons md-20 mr-2">arrow_back</i>
            <span>Back</span>
        </div>
    )
}

const getParams = (param: string): string | null => {
    return new URLSearchParams(window.location.search)?.get(param)
}

import React, { useCallback, useMemo } from 'react'
import { useClinic } from '../../hooks/useClinic'
import { useProvider } from '../../hooks/useProvider'
import { Clinic } from '../../models/Clinic.model'
import { Provider } from '../../models/Provider.model'
import './ProviderCard.scss'

interface Props {
    providerID: number
    clientName: string
}

export function ProviderCard(props: Props) {
    const { getClinicByClientName } = useClinic()
    const { getProviderByIDAndClientName } = useProvider()

    const provider = useMemo(
        (): Provider | null =>
            getProviderByIDAndClientName(props.providerID, props.clientName),
        [props.providerID, props.clientName, getProviderByIDAndClientName]
    )

    const clinic = useMemo((): Clinic | null => {
        return (provider && getClinicByClientName(provider.clientName)) || null
    }, [provider, getClinicByClientName])

    const onRender = useCallback(() => {
        return provider && clinic ? (
            <div className="provider-card-wrapper d-block d-sm-flex">
                <div className="min-width-40p mr-3">
                    <div className="font-size-16 font-weight-500 color-blue">
                        {provider.fullName}
                    </div>
                    <div className="font-weight-500">{clinic.displayName}</div>
                    <div className="whitespace-pre-line">{clinic.address}</div>
                </div>
                <div className="color-text-secondary mt-3 mt-sm-0">
                    {clinic.message}
                </div>
            </div>
        ) : (
            <div className="color-text-hint">
                Unable to load Provider details
            </div>
        )
    }, [provider, clinic])

    return onRender()
}

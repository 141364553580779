import React, { useMemo, useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import {
    patientmessagingService,
    RespondPayload,
} from '../../../../services/http/patientmessaging.service'
import { Attachment, Message } from '../../../../services/models/Message.model'
import { IconLabel } from '../../../../UI/IconLabel/IconLabel'
import { useFamilyMember } from '../../../hooks/useFamilyMember'
import { FileAttachment } from './FileAttachment'
import { FileUploader } from './FileUploader'

interface Props {
    message: Message
    onMessageUpdated: (message: Message) => void
}

export function Reply(props: Props) {
    const { getMemberClinicByClientName } = useFamilyMember()
    const [response, setResponse] = useState<string>('')
    const [files, setFiles] = useState<File[]>([])
    const [formError, setFormError] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const memberClinicID = useMemo((): number | null => {
        return getMemberClinicByClientName(props.message.clientName)?.id || null
    }, [props.message.clientName, getMemberClinicByClientName])

    const onResponseChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const value = event.target.value
        setResponse(value)
        setFormError('')
    }

    const onValidate = () => {
        if (!response.length) {
            setFormError('Message is required')
            return false
        }

        return true
    }

    const onReply = () => {
        if (!memberClinicID) {
            return
        }
        if (!onValidate()) {
            return
        }

        const message: Message = {
            ...props.message,
            patientMessage: response,
            replyAt: new Date(),
        }

        setIsLoading(true)

        const promises: Promise<Attachment>[] = []

        files?.forEach((file: File) => {
            promises.push(
                new Promise((resolve) => {
                    patientmessagingService
                        .upload(props.message.clientName, file)
                        .then((attachment: Attachment) => {
                            resolve(attachment)
                        })
                })
            )
        })

        Promise.all(promises).then((attachments: Attachment[]) => {
            if (attachments?.length) {
                message.patientAttachments = attachments
            }

            const payload: RespondPayload = {
                messageID: message.id,
                attachment:
                    attachments?.map(
                        (attachment: Attachment) => attachment.id
                    ) ?? [],
                response: message.patientMessage!,
                replyAt: message.replyAt!,
                memberClinicID,
            }
            patientmessagingService.respond(payload).then(() => {
                props.onMessageUpdated(message)
                setIsLoading(false)
            })
        })
    }

    const onFilesSelected = (_files: File[]) => {
        setFiles([...files, ..._files])
    }

    return (
        <div className="reply-wrapper pb-0">
            <IconLabel label="Reply" materialIcon="reply" color="color-blue" />

            <div>
                <TextareaAutosize
                    className={formError ? ' invalid-state' : ''}
                    placeholder="Type reply message ..."
                    value={response}
                    onChange={onResponseChange}
                />
            </div>

            {formError?.length > 0 && (
                <div className="mt-3 color-red">{formError}</div>
            )}

            {files?.length > 0 && (
                <div className="d-flex flex-wrap">
                    {files.map((file: File, index: number) => (
                        <FileAttachment
                            key={index}
                            filename={file.name}
                            canDelete={true}
                            onDelete={() => {
                                const _files = [...files]
                                _files.splice(index, 1)
                                setFiles(_files)
                            }}
                        />
                    ))}
                </div>
            )}

            <div className="mt-3 d-flex flex-wrap align-items-center">
                <button
                    className={
                        'width-unset d-inline-block mr-3 mb-4 primary ' +
                        (isLoading ? ' is-loading' : '')
                    }
                    onClick={onReply}
                >
                    Send
                </button>

                <div className="mb-4">
                    <FileUploader onFilesSelected={onFilesSelected} />
                </div>
            </div>
        </div>
    )
}

import moment from 'moment'

export class Message {
    id!: number
    clientName!: string
    isResponseAllowed!: boolean
    messageThreadID!: number
    patientAttachments?: Attachment[]
    patientMessage?: string
    providerAttachments?: Attachment[]
    providerMessage!: string
    readAt?: Date
    readDeadline?: Date
    replyAt?: Date
    replyDeadline?: Date
    sendingProvider!: number
    sentAt!: Date

    static isNotRead = (message: Message): boolean =>
        moment(message.readAt).isBefore(message.sentAt)
}

export class Attachment {
    id!: number
    clientName!: string
    createdAt!: Date
    fileName!: string
    objectID!: string

    deserialize(obj: any) {
        Object.assign(this, obj)
        return this
    }
}

export function deserializeMessage(obj: any): Message {
    const model: Message = Object.assign({}, obj)
    if (obj.patientAttachments) {
        model.patientAttachments = obj.patientAttachments.map((_obj: any) =>
            new Attachment().deserialize(_obj)
        )
    }

    if (obj.providerAttachments) {
        model.providerAttachments = obj.providerAttachments.map((ob: any) =>
            new Attachment().deserialize(ob)
        )
    }

    if (obj.sentAt) {
        model.sentAt = new Date(obj.sentAt)
    }

    return model
}

export function deserializeAttachment(obj: any): Attachment {
    const model: Attachment = Object.assign({}, obj)
    return model
}

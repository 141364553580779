import React from 'react'

export function Features() {
    return (
        <section className="features-wrapper">
            <div className="features-inner">
                <div className="section-title text-center color-blue mb-5">
                    How Clinics are Using Medley
                </div>

                <div className="features-item">
                    <div className="icon-wrapper">
                        <i className="material-icons color-blue">description</i>
                        <div className="icon-bg"></div>
                    </div>
                    <div className="ml-md-5">
                        <div className="section-title">
                            Exchanging Forms and Documents
                        </div>
                        <div className="section-subtitle mt-2">
                            Shares forms and documents with Medley and avoid
                            unnecessary visits
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <i className="material-icons color-green mr-2">
                                done
                            </i>
                            <div className="color-green uppercase font-size-18 font-weight-600 mt-2">
                                <span>Save Time</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="features-item">
                    <div className="icon-wrapper">
                        <i className="material-icons color-blue">science</i>
                        <div className="icon-bg"></div>
                    </div>
                    <div className="ml-md-5">
                        <div className="section-title">
                            Share Lab and Imaging Results
                        </div>
                        <div className="section-subtitle mt-2">
                            Share a result and a plan quickly and securely
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <i className="material-icons color-green mr-2">
                                done
                            </i>
                            <div className="color-green uppercase font-size-18 font-weight-600 mt-2">
                                <span>Increase Awareness</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="features-item">
                    <div className="icon-wrapper">
                        <i className="material-icons color-blue">
                            event_available
                        </i>
                        <div className="icon-bg"></div>
                    </div>
                    <div className="ml-md-5">
                        <div className="section-title">
                            Share Appointment Details
                        </div>
                        <div className="section-subtitle mt-2">
                            Inform your patients of upcoming Imaging or
                            Specialist Appointments
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <i className="material-icons color-green mr-2">
                                done
                            </i>
                            <div className="color-green uppercase font-size-18 font-weight-600 mt-2">
                                <span>Increase Attendance</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

import moment from 'moment'
import React, { useCallback, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Moment from 'react-moment'
import { scheduleService } from '../../../../services/http/schedule.service'
import { Provider } from '../../../../services/models/Provider.model'
import { ConfirmationPopover } from '../../../../UI/ConfirmationPopover/ConfirmationPopover'
import { onOpenToast } from '../../../../UI/Toast/Toast'
import { useProviderIndex } from '../../../hooks/useProviderIndex'
import { useAppointmentType } from '../../hooks/useAppointmentType'
import {
    AppointmentType,
    DEFAULT_COLOR,
} from '../../models/AppointmentType.model'
import { BookedAppointment } from '../../models/BookedAppointment.model'
import { ProviderCard } from '../ProviderCard/ProviderCard'
// import { ConfirmationPopover } from '../../../UI/ConfirmationPopover/ConfirmationPopover'
import './AppointmentCard.scss'

interface Props {
    appointment: BookedAppointment
    onCancelled?: (appointment: BookedAppointment) => void
    viewOnly?: boolean
}

const CANNOT_CANCEL_WITHIN_HOURS = 48

export function AppointmentCard(props: Props) {
    const { getProviderByClientName } = useProviderIndex()
    const { getAppointmentTypeByIdAndClientName } = useAppointmentType()

    const provider = useMemo((): Provider | null => {
        return (
            getProviderByClientName(
                props.appointment.providerID,
                props.appointment.clientName
            ) || null
        )
    }, [
        props.appointment.providerID,
        props.appointment.clientName,
        getProviderByClientName,
    ])

    const appointmentType = useMemo((): AppointmentType | null => {
        if (!provider) {
            return null
        }
        return getAppointmentTypeByIdAndClientName(
            provider.clientName,
            props.appointment.appointmentType
        )
    }, [props.appointment, provider, getAppointmentTypeByIdAndClientName])

    const canCancel = useMemo(() => {
        const canCancelDate = moment(new Date())
        canCancelDate.add(CANNOT_CANCEL_WITHIN_HOURS, 'h')
        const appointmentDate = moment(props.appointment.startAt)
        return appointmentDate.isAfter(canCancelDate)
    }, [props.appointment.startAt])

    const onRender = useCallback(() => {
        const onCancel = () => {
            scheduleService
                .cancelAppointment(props.appointment.appointmentID)
                .then(
                    () => {
                        if (props.onCancelled) {
                            props.onCancelled(props.appointment)
                            onOpenToast(
                                'Successfully cancelled appointment',
                                'success'
                            )
                        }
                    },
                    () => {
                        onOpenToast('Unable to cancel appointment', 'error')
                    }
                )
                .catch(() => {
                    onOpenToast('Unable to cancel appointment', 'error')
                })
        }

        return (
            <div className="appointment-card-wrapper">
                <div className="d-sm-flex d-block">
                    <div className="d-flex flex-1">
                        <div className="d-flex">
                            <i className="material-icons mt-1 mr-4">event</i>
                            <div className="font-weight-500">
                                <div>
                                    <Moment
                                        format="dddd, MMMM Do, YYYY"
                                        className="font-size-18"
                                    >
                                        {props.appointment.startAt}
                                    </Moment>
                                </div>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <Moment format="hh:mm A">
                                            {props.appointment.startAt}
                                        </Moment>
                                        <span>&nbsp;-&nbsp;</span>
                                        <Moment format="hh:mm A">
                                            {props.appointment.endAt}
                                        </Moment>
                                    </div>
                                    {appointmentType && (
                                        <div className="d-flex align-items-center">
                                            <i
                                                className="material-icons ml-3 mr-1"
                                                style={{
                                                    color:
                                                        appointmentType?.color ??
                                                        DEFAULT_COLOR,
                                                }}
                                            >
                                                label
                                            </i>
                                            <div>{appointmentType.name}</div>
                                        </div>
                                    )}
                                </div>
                                {props.appointment.reason && (
                                    <React.Fragment>
                                        <div className="color-text-secondary mt-3">
                                            Reason for Visit
                                        </div>
                                        <div>{props.appointment.reason}</div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-1 mt-3 mt-sm-0">
                        <ProviderCard
                            providerID={props.appointment.providerID}
                            clientName={props.appointment.clientName}
                        />
                    </div>
                </div>
                {!props.viewOnly && (
                    <div className="text-right mt-3">
                        {canCancel ? (
                            <ConfirmationPopover
                                message={
                                    'Are you sure you want to cancel this appointment?'
                                }
                                confirmLabel={'Cancel Appointment'}
                                cancelLabel={'Close'}
                                onCancel={() => onCancel()}
                                position={'bottom-end'}
                                children={
                                    <div className="div-button color-red">
                                        Cancel Appointment
                                    </div>
                                }
                            />
                        ) : (
                            <OverlayTrigger
                                placement="top-end"
                                delay={{ show: 100, hide: 400 }}
                                overlay={
                                    <Tooltip id="button-tooltip" {...props}>
                                        <span>
                                            Cannot cancel appointment within
                                        </span>
                                        <span>
                                            &nbsp;{CANNOT_CANCEL_WITHIN_HOURS}
                                            &nbsp;
                                        </span>
                                        <span>hours of appointment's date</span>
                                    </Tooltip>
                                }
                            >
                                <div className="div-button d-inline-flex align-items-center color-text-hint">
                                    <span>Cancel Appointment</span>
                                    <i className="material-icons md-16 ml-2">
                                        info
                                    </i>
                                </div>
                            </OverlayTrigger>
                        )}
                    </div>
                )}
            </div>
        )
    }, [props, canCancel, appointmentType])

    return onRender()
}

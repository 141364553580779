import { ErrorMessage } from '@hookform/error-message'
import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import {
    BookAppointmentPayload,
    scheduleService,
} from '../../../../services/http/schedule.service'
import { MemberProvider } from '../../../../services/models/Patient.model'
import { Provider } from '../../../../services/models/Provider.model'
import { Provider as ProviderModel } from '../..//models/Provider.model'
import { ConfirmationPopover } from '../../../../UI/ConfirmationPopover/ConfirmationPopover'
import { onOpenToast } from '../../../../UI/Toast/Toast'
import { useFamilyMember } from '../../../hooks/useFamilyMember'
import { useProviderIndex } from '../../../hooks/useProviderIndex'
import { useAppointmentType } from '../../hooks/useAppointmentType'
import { useClinic } from '../../hooks/useClinic'
import { DEFAULT_DURATION } from '../../models/AppointmentType.model'
import { BookedAppointment } from '../../models/BookedAppointment.model'
import { toProviderIndex } from '../../models/Provider.model'
import { Range } from '../../models/Range.model'
import { AppointmentCard } from '../AppointmentCard/AppointmentCard'
import { AwaitingApproval } from '../AwaitingApproval/AwaitingApproval'
import { ProviderCard } from '../ProviderCard/ProviderCard'
import { AppointmentTypeField } from './AppointmentTypeField'
import './BookAppointment.scss'
import { ProviderField } from './ProviderField'

interface Props {
    onBooked: (appointment: BookedAppointment) => void
    onCancel: () => void
}

const MAX_REASON_CHARS = 100
export const MAX_MONTHS_AWAY_BOOKING_ALLOWED = 6

enum FORM_STEP {
    CREATE,
    REVIEW,
}

export function BookAppointment(props: Props) {
    const { setValue, handleSubmit, errors, control, clearErrors } = useForm()
    const { getClinicByProvider } = useClinic()
    const { getProviderByClientName } = useProviderIndex()
    const { getAppointmentTypeByIdAndClinic } = useAppointmentType()
    const {
        familyMember,
        approvedMembersProviderFromCurrentFamilyMember,
    } = useFamilyMember()
    const [form, setForm] = useState<FormObj>(initialForm)
    const [formStep, setFormStep] = useState<FORM_STEP>(FORM_STEP.CREATE)
    const [ranges, setRanges] = useState<Range[]>([])
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [startTime, setStartTime] = useState<Date | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [
        selectExistingProviders,
        setSelectExistingProviders,
    ] = useState<boolean>(
        approvedMembersProviderFromCurrentFamilyMember?.length > 0
    )
    const [
        appointmentReview,
        setAppointmentReview,
    ] = useState<BookedAppointment | null>(null)
    const [memberProvidersRequested, setMemberProvidersRequested] = useState<
        MemberProvider[]
    >([])

    const onFormStepNext = (data: FormObj) => {
        const _form = {
            ...form,
            ...data,
        }
        setForm(_form)
        if (formStep === FORM_STEP.REVIEW) {
            onBookAppointment(_form)
            return
        }
        setFormStep(formStep + 1)
    }

    const onFormStepPrev = (_formStep: FORM_STEP) => {
        setFormStep(_formStep - 1)
    }

    const onBookAppointment = (_form: FormObj) => {
        if (!isFormValid || !_form.startAt || !_form.endAt) {
            return
        }

        const payload: BookAppointmentPayload = {
            demographicID: _form.demographicID,
            providerID: _form.providerID,
            typeID: _form.typeID,
            startAt: _form.startAt.toISOString(),
            endAt: _form.endAt.toISOString(),
            clientName: _form.clientName,
        }
        if (_form.reason) {
            payload.reason = _form.reason
        }

        setIsLoading(true)
        scheduleService
            .bookAppointment(payload)
            .then((appointment: BookedAppointment) => {
                if (appointment) {
                    onOpenToast('Successfully booked appointment', 'success')
                    setIsLoading(false)
                    props.onBooked(appointment)
                    props.onCancel()
                } else {
                    onOpenToast('Unable to book appointment', 'error')
                    setIsLoading(false)
                }
            })
    }

    const provider = useMemo(
        (): Provider | null =>
            getProviderByClientName(form.providerID, form.clientName),
        [form.providerID, form.clientName, getProviderByClientName]
    )

    const clinic = useMemo(() => {
        return getClinicByProvider(provider)
    }, [provider, getClinicByProvider])

    const appointmentType = useMemo(() => {
        return getAppointmentTypeByIdAndClinic(clinic, form.typeID) || null
    }, [form.typeID, clinic, getAppointmentTypeByIdAndClinic])

    const getAppointmentTypeRanges = useCallback(
        (typeID: number) => {
            if (!provider) {
                return null
            }

            scheduleService
                .getAppointmentTypeRanges(
                    provider.providerID,
                    provider.clientName,
                    typeID
                )
                .then((_ranges: Range[]) => {
                    if (_ranges?.length) {
                        setRanges(_ranges)
                        setStartDate(_ranges[0].startAt)
                    } else {
                        setRanges([])
                        setStartDate(null)
                        setStartTime(null)
                    }
                })
                .catch(() => {
                    setRanges([])
                    setStartDate(null)
                    setStartTime(null)
                })
        },
        [provider]
    )

    const appointmentTypeTimes = useMemo(() => {
        if (!appointmentType) {
            return []
        }

        const _ranges =
            ranges.filter((range: Range) =>
                moment(range.startAt).isSame(moment(startDate), 'day')
            ) ?? []

        const currentDate = moment(new Date())
        const dates: Date[] = []

        // Split each date into time intervals by the appointmentType's duration
        _ranges.forEach((range: Range) => {
            let date = moment(range.startAt)
            if (date.isAfter(currentDate)) {
                dates.push(date.toDate())
            }

            const endDate = moment(range.endAt)
            while (date.isBefore(endDate)) {
                date = moment(date).add(
                    appointmentType.defaultDuration ?? DEFAULT_DURATION,
                    'minutes'
                )
                if (date.isBefore(endDate) && date.isAfter(currentDate)) {
                    dates.push(date.toDate())
                }
            }
        })

        if (dates.length) {
            const date = dates[0]
            setStartTime(date)
            setForm({
                ...form,
                startAt: date,
                endAt: getDateAfterDuration(
                    date,
                    appointmentType?.defaultDuration ?? DEFAULT_DURATION
                ),
            })
        } else {
            setForm({
                ...form,
                startAt: initialForm.startAt,
                endAt: initialForm.endAt,
            })
        }

        return dates

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ranges, startDate, appointmentType])

    const isFormValid = useMemo(() => {
        const valid =
            form?.demographicID !== 0 &&
            form?.providerID !== 0 &&
            form?.typeID > 0 &&
            form?.clientName?.length &&
            form?.startAt &&
            form?.endAt

        if (valid) {
            const appointment = new BookedAppointment()
            appointment.appointmentID = new Date().getTime()
            if (form.startAt) {
                appointment.startAt = form.startAt
            }
            if (form.endAt) {
                appointment.endAt = form.endAt
            }
            appointment.appointmentType = form.typeID
            appointment.providerID = form.providerID
            appointment.reason = form.reason
            appointment.clientName = form.clientName
            setAppointmentReview(appointment)
        } else {
            setAppointmentReview(null)
        }

        return valid
    }, [form])

    const renderAppointmentTypeField = useMemo(() => {
        const noProviderSet = (
            <div className="color-text-hint">Need to select Provider</div>
        )

        if (!form.providerID || !provider) {
            return noProviderSet
        }

        const _clinic = getClinicByProvider(provider)
        if (!_clinic) {
            return noProviderSet
        }

        if (_clinic.appointmentTypes?.length) {
            return (
                <Controller
                    control={control}
                    name="typeID"
                    rules={{
                        required: 'Appointment Type is required',
                        validate: (value) => {
                            if (!value) {
                                return 'Appointment Type is required'
                            }
                        },
                    }}
                    defaultValue={form?.typeID}
                    render={({ onChange }) => (
                        <AppointmentTypeField
                            providerID={form.providerID}
                            clientName={form.clientName}
                            appointmentTypeID={form.typeID}
                            options={_clinic.appointmentTypes ?? []}
                            onSelected={(typeID: number) => {
                                setForm({
                                    ...form,
                                    typeID,
                                    startAt: initialForm.startAt,
                                    endAt: initialForm.endAt,
                                })
                                onChange(typeID)
                                getAppointmentTypeRanges(typeID)
                            }}
                        />
                    )}
                />
            )
        } else {
            return (
                <div className="color-red">
                    There are no available appointment types at this clinic
                </div>
            )
        }
    }, [form, provider, control, getAppointmentTypeRanges, getClinicByProvider])

    const onReset = () => {
        setValue('provider', initialForm.providerID)
        setForm({
            ...form,
            ...initialForm,
        })
    }

    // Reset form when family member is switched
    useEffect(() => {
        if (familyMember) {
            onReset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyMember])

    return (
        <div className="book-appointment-wrapper">
            <div className="d-none d-sm-flex align-items-center mb-4">
                <ConfirmationPopover
                    message={
                        'Are you sure you want to cancel booking appointment?'
                    }
                    confirmLabel={'Cancel Booking'}
                    cancelLabel={'Continue'}
                    onCancel={props.onCancel}
                    position={'bottom-start'}
                    children={
                        <React.Fragment>
                            <i className="material-icons mr-4 cursor-pointer">
                                arrow_back
                            </i>
                        </React.Fragment>
                    }
                />

                <div className="font-size-18 font-weight-500 uppercase">
                    Book Appointment
                </div>
            </div>

            <div className="panel-wrapper flex-1">
                {formStep === FORM_STEP.CREATE && (
                    <Form
                        className="panel-inner"
                        onSubmit={handleSubmit(onFormStepNext)}
                    >
                        <div className="d-flex align-items-center justify-content-between">
                            <Form.Label className="mb-2 uppercase">
                                {selectExistingProviders
                                    ? 'Provider'
                                    : 'Adding Provider'}
                            </Form.Label>

                            <div
                                className="div-button color-blue"
                                onClick={() => {
                                    setSelectExistingProviders(
                                        !selectExistingProviders
                                    )
                                    onReset()
                                }}
                            >
                                {selectExistingProviders
                                    ? 'Add New Provider'
                                    : 'Select Existing Provider'}
                            </div>
                        </div>
                        {!form.providerID && (
                            <Controller
                                control={control}
                                name="provider"
                                rules={{
                                    required: 'Provider is required',
                                    validate: (value) => {
                                        if (!value) {
                                            return 'Provider is required'
                                        }
                                    },
                                }}
                                defaultValue={form?.providerID}
                                render={({ onChange }) => (
                                    <ProviderField
                                        selectExistingProviders={
                                            selectExistingProviders
                                        }
                                        onSelected={(
                                            _provider: ProviderModel,
                                            demographicID: number
                                        ) => {
                                            if (!_provider) {
                                                return
                                            }

                                            const _providerIndex = toProviderIndex(
                                                _provider
                                            )

                                            setForm({
                                                ...initialForm,
                                                providerID:
                                                    _providerIndex.providerID,
                                                demographicID,
                                                clientName:
                                                    _providerIndex?.clientName ??
                                                    initialForm.clientName,
                                            })
                                            onChange(_providerIndex.providerID)
                                            clearErrors('provider')
                                        }}
                                        onMemberProvidersRequested={(
                                            memberProviders: MemberProvider[]
                                        ) => {
                                            const _memberProvidersRequested = [
                                                ...memberProvidersRequested,
                                                ...memberProviders,
                                            ]
                                                .filter(
                                                    (i: MemberProvider) =>
                                                        familyMember &&
                                                        familyMember.id ===
                                                            i.memberID
                                                )
                                                .filter(
                                                    (
                                                        i: MemberProvider,
                                                        index: number,
                                                        array: MemberProvider[]
                                                    ) =>
                                                        array.findIndex(
                                                            (
                                                                ii: MemberProvider
                                                            ) => i.id === ii.id
                                                        ) === index
                                                )

                                            setMemberProvidersRequested(
                                                _memberProvidersRequested
                                            )
                                        }}
                                    />
                                )}
                            />
                        )}
                        {form?.providerID !== 0 && (
                            <React.Fragment>
                                <div>
                                    <ProviderCard
                                        providerID={form.providerID}
                                        clientName={form.clientName}
                                    />
                                </div>
                                <div className="text-right mt-2">
                                    <div
                                        className="div-button color-text-hint"
                                        onClick={onReset}
                                    >
                                        Clear Provider
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="provider"
                            as="div"
                        />
                        {form?.providerID === 0 && (
                            <div className="mt-3">
                                {memberProvidersRequested?.map(
                                    (memberProvider: MemberProvider) => (
                                        <AwaitingApproval
                                            key={memberProvider.id}
                                            memberProvider={memberProvider}
                                        />
                                    )
                                )}
                            </div>
                        )}
                        {form?.providerID !== 0 && (
                            <React.Fragment>
                                <Form.Label className="mt-4 uppercase">
                                    Appointment Type
                                </Form.Label>
                                {renderAppointmentTypeField}

                                <ErrorMessage
                                    className="color-red mt-1"
                                    errors={errors}
                                    name="typeID"
                                    as="div"
                                />

                                <Form.Label className="mt-5 uppercase">
                                    Reason for Visit (Optional)
                                </Form.Label>

                                <Controller
                                    control={control}
                                    name="reason"
                                    rules={{
                                        maxLength: {
                                            value: MAX_REASON_CHARS,
                                            message: `Maximum ${MAX_REASON_CHARS} characters are allowed`,
                                        },
                                    }}
                                    defaultValue={form?.reason}
                                    render={({ onChange, value }) => (
                                        <FormControl
                                            as="textarea"
                                            className="resize-none"
                                            placeholder="Enter Reason"
                                            value={value}
                                            onChange={(event) => {
                                                const reason =
                                                    event.target.value ?? ''
                                                if (
                                                    reason?.length >
                                                    MAX_REASON_CHARS
                                                ) {
                                                    return
                                                }

                                                setForm({
                                                    ...form,
                                                    reason,
                                                })
                                                onChange(reason)
                                            }}
                                        />
                                    )}
                                />

                                <div className="d-flex justify-content-between align-item-start">
                                    <ErrorMessage
                                        className="color-red mt-1"
                                        errors={errors}
                                        name="reason"
                                        as="div"
                                    />
                                    <div></div>
                                    <div className="color-text-hint">
                                        {form.reason?.length ?? 0}/
                                        {MAX_REASON_CHARS}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                        {form?.typeID > 0 && (
                            <React.Fragment>
                                <Form.Label className="mt-4 uppercase">
                                    Appointment Date & Time
                                </Form.Label>

                                {!isLoading && ranges?.length > 0 ? (
                                    <div className="d-sm-flex d-block align-items-center">
                                        <div className="flex-1 mr-sm-3 mr-0">
                                            <DatePicker
                                                onChange={(date: Date) => {
                                                    setStartDate(date)
                                                }}
                                                popperPlacement="bottom-start"
                                                placeholderText="Select Appointment Date"
                                                selected={startDate}
                                                dateFormat="MMMM do, yyyy"
                                                includeDates={
                                                    ranges.map(
                                                        (range: Range) =>
                                                            range.startAt
                                                    ) ?? []
                                                }
                                                showPopperArrow={false}
                                            />
                                        </div>

                                        {appointmentTypeTimes?.length > 0 ? (
                                            <div className="flex-1">
                                                <DatePicker
                                                    onChange={(date: Date) => {
                                                        setStartTime(date)
                                                        setForm({
                                                            ...form,
                                                            startAt: date,
                                                            endAt: getDateAfterDuration(
                                                                date,
                                                                appointmentType?.defaultDuration ??
                                                                    DEFAULT_DURATION
                                                            ),
                                                        })
                                                    }}
                                                    popperPlacement="bottom-start"
                                                    placeholderText="Select Appointment Time"
                                                    selected={startTime}
                                                    dateFormat="HH:mm aa"
                                                    timeFormat="HH:mm aa"
                                                    timeCaption={
                                                        startTime
                                                            ? moment(
                                                                  startTime
                                                              ).format('MMM DD')
                                                            : 'Time'
                                                    }
                                                    includeTimes={
                                                        appointmentTypeTimes
                                                    }
                                                    injectTimes={
                                                        appointmentTypeTimes
                                                    }
                                                    timeIntervals={
                                                        appointmentType?.defaultDuration ??
                                                        DEFAULT_DURATION
                                                    }
                                                    showPopperArrow={false}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                />
                                            </div>
                                        ) : (
                                            <div className="flex-1 color-red">
                                                There are no available times on
                                                this date.
                                            </div>
                                        )}
                                    </div>
                                ) : !isLoading && ranges?.length === 0 ? (
                                    <div className="color-red">
                                        There are no available dates to book an
                                        appointment of selected type.
                                    </div>
                                ) : (
                                    <div className="is-lod-3"></div>
                                )}
                            </React.Fragment>
                        )}
                        <div className="mt-5 d-flex flex-wrap justify-content-between align-items-center">
                            <div></div>
                            <div className="d-flex align-items-center">
                                <ConfirmationPopover
                                    message={
                                        'Are you sure you want to cancel booking appointment?'
                                    }
                                    confirmLabel={'Cancel Booking'}
                                    cancelLabel={'Continue'}
                                    onCancel={props.onCancel}
                                    position={'bottom-end'}
                                    children={
                                        <div className="div-button color-text-hint">
                                            Cancel Booking
                                        </div>
                                    }
                                />
                                {isFormValid && (
                                    <NextButton
                                        isLoading={false}
                                        label="Next"
                                        hideArrow={true}
                                    />
                                )}
                            </div>
                        </div>
                    </Form>
                )}
                {formStep === FORM_STEP.REVIEW && (
                    <Form
                        className="panel-inner"
                        onSubmit={handleSubmit(onFormStepNext)}
                    >
                        <Form.Label className="uppercase">
                            Appointment Summary
                        </Form.Label>

                        {isFormValid && appointmentReview && (
                            <AppointmentCard
                                appointment={appointmentReview}
                                viewOnly={true}
                            />
                        )}

                        <div className="mt-5 d-flex flex-wrap justify-content-between align-items-center">
                            <PrevButton
                                label={'Edit'}
                                onClick={() => onFormStepPrev(formStep)}
                            />
                            <NextButton
                                isLoading={false}
                                label="Book Appointment"
                                hideArrow={true}
                            />
                        </div>
                    </Form>
                )}
            </div>
        </div>
    )
}

interface FormObj {
    demographicID: number
    providerID: number
    typeID: number
    reason: string
    clientName: string
    startAt: Date | null
    endAt: Date | null
}

const initialForm: FormObj = {
    demographicID: 0,
    providerID: 0,
    typeID: 0,
    reason: '',
    clientName: '',
    startAt: null,
    endAt: null,
}

function NextButton({
    isLoading,
    label,
    hideArrow,
}: {
    isLoading: boolean
    label?: string
    hideArrow?: boolean
}) {
    return (
        <button
            type="button"
            className={
                'w-auto book-appointment-next-button primary ml-3' +
                (isLoading ? ' is-loading' : '')
            }
        >
            <input type="submit" />

            <div className="d-flex align-items-center">
                <span>{label ?? 'Next'}</span>
                {!hideArrow && (
                    <i className="material-icons md-20 ml-2">arrow_forward</i>
                )}
            </div>
        </button>
    )
}

function PrevButton({
    label,
    onClick,
}: {
    label: string
    onClick: () => void
}) {
    return (
        <div
            className="max-width-40p mr-3 d-flex flex-wrap align-items-center font-size-13 font-weight-500 uppercase color-blue cursor-pointer"
            onClick={onClick}
        >
            <i className="material-icons md-20 mr-2">arrow_back</i>
            <span>{label}</span>
        </div>
    )
}

function getDateAfterDuration(date: Date, duration: number): Date | null {
    if (!date || !duration) {
        return null
    }
    return moment(date).add(duration, 'minutes').toDate()
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { scheduleService } from '../../services/http/schedule.service'
import { MemberProvider } from '../../services/models/Patient.model'
import { useFamilyMember } from '../hooks/useFamilyMember'
import './Appointments.scss'
import { AppointmentCard } from './components/AppointmentCard/AppointmentCard'
import { AwaitingApproval } from './components/AwaitingApproval/AwaitingApproval'
import { BookAppointment } from './components/BookAppointment/BookAppointment'
import { useAppointment } from './hooks/useAppointment'
import { AppointmentsContext } from './hooks/useAppointmentsContext'
import { BookedAppointment } from './models/BookedAppointment.model'

enum VIEW {
    VIEW_APPPOINTMENTS,
    BOOK_APPOINTMENT,
}

export function Appointments() {
    const appointmentsContext = useContext(AppointmentsContext)
    const appointmentContextSetClinicsRef = useRef(
        appointmentsContext.setClinics
    )
    const appointmentContextSetProvidersRef = useRef(
        appointmentsContext.setProviders
    )
    const {
        appointments,
        isLoading,
        hasLoadedAll,
        getAppointments,
        onAppointmentBooked,
        onAppointmentCancelled,
    } = useAppointment()
    const { pendingMembersProviderFromCurrentFamilyMember } = useFamilyMember()
    const [view, setView] = useState<VIEW>(VIEW.VIEW_APPPOINTMENTS)
    const [canBook] = useState<boolean>(true)

    // Get providers on load
    useEffect(() => {
        scheduleService.getProviders().then((response) => {
            if (response.clinics?.length) {
                appointmentContextSetClinicsRef.current(response.clinics)
            }
            if (response.providers?.length) {
                appointmentContextSetProvidersRef.current(response.providers)
            }
        })
    }, [])

    // Get appointments
    useEffect(() => {
        getAppointments()
    }, [getAppointments])

    return (
        <div
            className={
                'appointments-wrapper max-content-width flex-1 ' +
                (isLoading ? ' is-loading' : '')
            }
        >
            {view === VIEW.VIEW_APPPOINTMENTS && (
                <React.Fragment>
                    <WelcomeBlock
                        canBook={canBook}
                        onBook={() => setView(VIEW.BOOK_APPOINTMENT)}
                    />

                    <div className="mt-3">
                        {pendingMembersProviderFromCurrentFamilyMember?.map(
                            (memberProvider: MemberProvider) => (
                                <AwaitingApproval
                                    key={memberProvider.id}
                                    memberProvider={memberProvider}
                                />
                            )
                        )}
                    </div>

                    {appointments?.length > 0 && (
                        <AppointmentsList
                            classes="mt-4"
                            appointments={appointments}
                            onCancelled={onAppointmentCancelled}
                        />
                    )}

                    {hasLoadedAll && !isLoading && !appointments?.length && (
                        <EmptyState />
                    )}
                </React.Fragment>
            )}

            {view === VIEW.BOOK_APPOINTMENT && (
                <BookAppointment
                    onBooked={onAppointmentBooked}
                    onCancel={() => setView(VIEW.VIEW_APPPOINTMENTS)}
                />
            )}
        </div>
    )
}

const WelcomeBlock = ({
    canBook,
    onBook,
}: {
    canBook: boolean
    onBook: () => void
}) => {
    const { patientFirstName } = useFamilyMember()

    return (
        <div className="d-md-flex justify-content-between align-items-center text-center text-md-left">
            <div>
                <div className="font-size-18 font-weight-500">
                    <span>Welcome,&nbsp;</span>
                    <span>{patientFirstName}</span>
                </div>
                <div className="color-text-secondary">
                    Upcoming Appointments in the next 6 months
                </div>
            </div>

            {canBook && (
                <button
                    type="button"
                    className="primary w-auto mt-3 mt-md-0"
                    onClick={onBook}
                >
                    Book Appointment
                </button>
            )}
        </div>
    )
}

const AppointmentsList = ({
    appointments,
    onCancelled,
    classes,
}: {
    appointments: BookedAppointment[]
    onCancelled: (appointment: BookedAppointment) => void
    classes?: string
}) => {
    return (
        <div className={classes || ''}>
            {appointments?.map((appointment: BookedAppointment) => (
                <AppointmentCard
                    key={appointment.appointmentID}
                    appointment={appointment}
                    onCancelled={onCancelled}
                />
            ))}
        </div>
    )
}

const EmptyState = () => {
    return (
        <div className="mt-4 text-center color-text-secondary">
            <img
                src={
                    process.env.PUBLIC_URL +
                    '/assets/images/illustration-noappointments.png'
                }
                alt="No Messages"
                className="empty-state-img"
            />
            <div className="mt-4 font-size-18">
                You don't have any upcoming appointments
            </div>
        </div>
    )
}

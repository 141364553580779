import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { OpenAddMembersModal } from '../../../services/event/eventactions'
import './AddMembersModal.scss'
import { Form } from './Form'

export interface AddMembersModalProps {
    show: boolean
    onClose: () => void
}

export function AddMembersModal(props: AddMembersModalProps) {
    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            dialogClassName="add-members-modal-wrapper modal-wrapper"
            animation={false}
            centered
        >
            <Modal.Header className="px-4 add-members-modal-header justify-content-center">
                Add Members
            </Modal.Header>

            <Modal.Body>
                <div className="add-members-modal-body">
                    <Form onAdded={props.onClose} onCancel={props.onClose} />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const onOpenAddMembersModal = (onClose?: () => void) => {
    const modalProps: AddMembersModalProps = {
        show: true,
        onClose: () => onClose && onClose(),
    }
    OpenAddMembersModal.emit(modalProps)
}

import React from 'react'
import './AppLogo.scss'

export function AppLogo() {
    return (
        <img
            className="app-logo"
            src={process.env.PUBLIC_URL + '/assets/images/logo-wordmark.png'}
            alt="Logo"
        />
    )
}

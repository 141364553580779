import React, { useEffect } from 'react'

export const Policy = () => {
    useEffect(() => {
        window.open(
            `${process.env.PUBLIC_URL}/assets/files/privacy_policy.docx`,
            '_blank'
        )
        window.location.href = '/'
    }, [])

    return null
}

import React from 'react'
import { FamilyMember } from '../../../services/models/Patient.model'
import { Avatar } from '../../../UI/Avatar/Avatar'
import { useFamilyMember } from '../../hooks/useFamilyMember'

interface Props {
    familyMember: FamilyMember
    onClick: () => void
}

export function Member(props: Props) {
    const { getPatientNameByFamilyMember } = useFamilyMember()
    const patientName = getPatientNameByFamilyMember(props.familyMember)

    return (
        <div className="member-wrapper" onClick={props.onClick}>
            <div className="section-left">
                <i className="material-icons color-text-secondary">
                    radio_button_unchecked
                </i>
            </div>
            <div className="section-right">
                <Avatar name={patientName} />
                <div className="font-weight-500 ml-2">{patientName}</div>
            </div>
        </div>
    )
}

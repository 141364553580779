import React from 'react'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'

export function SignUpSuccess() {
    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <AuthCard
                title="Verification Email Sent"
                subtitle="Please verify your email and sign in again to complete registration"
                children={<div></div>}
            />
        </div>
    )
}

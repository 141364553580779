import { ErrorMessage } from '@hookform/error-message'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom'
import { ROUTES } from '../../../App'
import useHttp from '../../../services/hooks/useHttp/useHttp'
import {
    AUTHREGEX_PASSWORD_STRENGTH,
    authService,
} from '../../../services/http/auth.service'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'

enum REQUEST {
    RESET_PASSWORD,
}

enum FORM_STATE {
    LOADED,
    FORM_SUCCESS,
    FORM_ERROR,
}

interface FormObj {
    password: string
    confirmPassword: string
}

export function ResetPassword() {
    const { secret } = useParams<{ secret: string | undefined }>()
    const { register, handleSubmit, errors, watch } = useForm()
    const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.LOADED)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        httpLoading,
        httpResponseData,
        httpErrorData,
        httpRequestIdentifier,
        httpSendRequest,
    } = useHttp()

    useEffect(() => {
        if (!secret) {
            setFormState(FORM_STATE.FORM_ERROR)
        }
    }, [secret])

    useEffect(() => {
        if (!httpLoading) {
            if (httpRequestIdentifier === REQUEST.RESET_PASSWORD) {
                if (httpResponseData) {
                    setFormState(FORM_STATE.FORM_SUCCESS)
                } else if (httpErrorData) {
                    setFormState(FORM_STATE.FORM_ERROR)
                }
            }
        }
        setIsLoading(httpLoading)
    }, [httpLoading, httpResponseData, httpRequestIdentifier, httpErrorData])

    const onRender = useCallback(() => {
        let title
        let subtitle
        switch (formState) {
            case FORM_STATE.FORM_SUCCESS:
                title = 'Password Reset Successfully'
                subtitle = 'Please sign in with your new password'
                break

            case FORM_STATE.FORM_ERROR:
                title = 'Whoops'
                subtitle =
                    'Either the session has expired, or an unexpected error has occurred'
                break

            case FORM_STATE.LOADED:
            default:
                title = 'Reset Password'
                subtitle = 'Enter your new password'
                break
        }

        const onSubmit = (data: FormObj) => {
            if (!secret) {
                return
            }

            const decodedSecret = decodeURIComponent(secret)
            httpSendRequest(
                authService.resetPassword.bind(
                    {},
                    decodedSecret,
                    data.password
                ),
                REQUEST.RESET_PASSWORD
            )
        }

        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <AuthCard
                    title={title}
                    subtitle={subtitle}
                    children={
                        <React.Fragment>
                            {formState === FORM_STATE.LOADED && (
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>Password</Form.Label>
                                            <input
                                                className={
                                                    'form-control' +
                                                    (errors?.password?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="password"
                                                type="password"
                                                tabIndex={2}
                                                placeholder="New Password"
                                                ref={register({
                                                    required:
                                                        'New Password is required',
                                                    pattern: {
                                                        value: AUTHREGEX_PASSWORD_STRENGTH,
                                                        message:
                                                            'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 8 characters',
                                                    },
                                                })}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="password"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <Form.Label>
                                                Confirm Password
                                            </Form.Label>
                                            <input
                                                className={
                                                    'form-control' +
                                                    (errors?.confirmPassword
                                                        ?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="confirmPassword"
                                                type="password"
                                                tabIndex={3}
                                                placeholder="Confirm Password"
                                                ref={register({
                                                    required:
                                                        'Confirm Password is required',
                                                    validate: (value) =>
                                                        value ===
                                                            watch('password') ||
                                                        'Passwords do not match',
                                                })}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="confirmPassword"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <input
                                                className={
                                                    'primary' +
                                                    (isLoading
                                                        ? ' is-loading'
                                                        : '')
                                                }
                                                type="submit"
                                                value="submit"
                                            />
                                        </Col>
                                    </Form.Row>
                                </Form>
                            )}

                            {formState === FORM_STATE.FORM_SUCCESS && (
                                <Link
                                    className="color-white"
                                    to={
                                        process.env.REACT_APP_BASE_HREF +
                                        ROUTES.AUTH +
                                        ROUTES.SIGNIN
                                    }
                                >
                                    <button type="button" className="primary">
                                        Sign In
                                    </button>
                                </Link>
                            )}
                        </React.Fragment>
                    }
                />
            </div>
        )
    }, [
        formState,
        errors,
        handleSubmit,
        isLoading,
        register,
        httpSendRequest,
        secret,
        watch,
    ])

    return onRender()
}

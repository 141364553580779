import React from 'react'

export function Footer() {
    return (
        <section className="footer-wrapper">
            <div className="footer-inner">
                <img
                    className="logo"
                    src={
                        process.env.PUBLIC_URL +
                        '/assets/images/medley-by-avaros.png'
                    }
                    alt="Medley by Avaros"
                />

                <div className="text-center">
                    <div className="font-size-18 font-weight-500">
                        <div>Still have some questions?</div>
                        <div>
                            <span>Email us at&nbsp;</span>
                            <a
                                className="font-weight-600"
                                href="mailto:support@medley.health"
                            >
                                support@medley.health
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

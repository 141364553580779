import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Message } from '../../../../services/models/Message.model'
import { MessageThread as MessageThreadModel } from '../../../../services/models/MessageThread.model'
import { Badge } from '../../../../UI/Badge/Badge'
import { IconButton } from '../../../../UI/IconButton/IconButton'
import {
    COLLAPSE_MESSAGE_THREADS_AFTER_DAYS,
    MOMENT_DATETIME_FORMAT,
} from '../../../constants'
import { useProviderIndex } from '../../../hooks/useProviderIndex'
import { useMessageThreads } from '../../hooks/useMessageThreads'
import { MessageItem } from './MessageItem'

interface Props {
    messageThread: MessageThreadModel
    onMessageThreadUpdated: (messageThread: MessageThreadModel) => void
}

export function MessageThread(props: Props) {
    const { getProviderByClientName } = useProviderIndex()
    const { isMessageThreadNotRead } = useMessageThreads()
    const [isExpanded, setIsExpanded] = useState<boolean>(true)

    useEffect(() => {
        if (!props.messageThread) {
            return
        }

        // Check if messageThread should be collapsed after days created
        const date = moment(new Date())
        date.subtract(COLLAPSE_MESSAGE_THREADS_AFTER_DAYS, 'days')
        if (moment(props.messageThread.createdAt).isBefore(date)) {
            setIsExpanded(false)
        }
    }, [props.messageThread])

    const provider = useMemo(
        () =>
            getProviderByClientName(
                props.messageThread.assignedProvider,
                props.messageThread.clientName
            ),
        [props.messageThread, getProviderByClientName]
    )

    const recentMessage = useMemo((): Message | null => {
        if (!props.messageThread?.messages?.length) {
            return null
        }
        return props.messageThread.messages[0]
    }, [props.messageThread])

    const isNotRead = useMemo(
        () => isMessageThreadNotRead(props.messageThread),
        [props.messageThread, isMessageThreadNotRead]
    )

    const onMessageUpdated = (message: Message) => {
        const messageThread = {
            ...props.messageThread,
        }
        messageThread.messages = messageThread.messages?.map(
            (_message: Message) => {
                return _message.id === message.id ? message : _message
            }
        )
        props.onMessageThreadUpdated(messageThread)
    }

    return (
        <div className={'message-thread-wrapper '}>
            <div
                className={
                    'message-thread-header d-flex flex-column flex-md-row ' +
                    (!isExpanded ? ' collapsed' : '')
                }
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <div className="d-flex align-items-center">
                    {isNotRead && <Badge classes="mr-3" hideCount />}

                    {recentMessage && (
                        <div className="nowrap mr-4">
                            {moment(recentMessage.sentAt).format(
                                MOMENT_DATETIME_FORMAT
                            )}
                        </div>
                    )}
                </div>

                {!isExpanded && recentMessage && (
                    <div className="flex-1 mr-4 truncate-row-1">
                        {recentMessage.patientMessage ||
                            recentMessage.providerMessage}
                    </div>
                )}

                <div className="d-flex align-items-center justify-content-between">
                    <div className="mr-3 nowrap">
                        {provider?.fullName || 'Provider'}
                    </div>

                    <IconButton
                        materialIcon={
                            isExpanded
                                ? 'keyboard_arrow_up'
                                : 'keyboard_arrow_down'
                        }
                    />
                </div>
            </div>

            {isExpanded && (
                <React.Fragment>
                    {props.messageThread.messages?.map((message: Message) => (
                        <MessageItem
                            key={message.id}
                            message={message}
                            demographicID={props.messageThread.demographicId}
                            onMessageUpdated={onMessageUpdated}
                        />
                    ))}
                </React.Fragment>
            )}
        </div>
    )
}

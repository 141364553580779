import React, { useReducer } from 'react'
import { Clinic } from '../models/Clinic.model'
import { Provider } from '../models/Provider.model'

interface State {
    providers: Provider[]
    setProviders: (providers: Provider[]) => void
    clinicsMap: Map<string, Clinic>
    setClinics: (clinics: Clinic[]) => void
}

const initialState: State = {
    providers: [],
    setProviders: () => {},
    clinicsMap: new Map<string, Clinic>(),
    setClinics: () => {},
}

enum ACTION {
    SET_CLINICS,
    SET_PROVIDERS,
}

const reducer = (
    state: State,
    action: {
        type: number
        payload: any
    }
): State => {
    switch (action.type) {
        case ACTION.SET_CLINICS: {
            const clinicsMap = new Map(state.clinicsMap)
            const clinics: Clinic[] = action.payload
            clinics.forEach((clinic: Clinic) => {
                clinicsMap.set(clinic.name, clinic)
            })
            return {
                ...state,
                clinicsMap,
            }
        }

        case ACTION.SET_PROVIDERS: {
            const providers: Provider[] = action.payload
            return {
                ...state,
                providers,
            }
        }

        default:
            return state
    }
}

interface Context {
    state: State
    setProviders: (providers: Provider[]) => void
    setClinics: (clinics: Clinic[]) => void
}

const initialContext: Context = {
    state: initialState,
    setProviders: () => {},
    setClinics: () => {},
}

export const AppointmentsContext = React.createContext(initialContext)
export const AppointmentsContextProvider = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setClinics = (clinics: Clinic[]) => {
        dispatch({
            type: ACTION.SET_CLINICS,
            payload: clinics,
        })
    }

    const setProviders = (providers: Provider[]) => {
        dispatch({
            type: ACTION.SET_PROVIDERS,
            payload: providers,
        })
    }

    return (
        <AppointmentsContext.Provider
            value={{
                state,
                setClinics,
                setProviders,
            }}
        >
            {props.children}
        </AppointmentsContext.Provider>
    )
}

export const DEFAULT_COLOR = '#00A5F5'
export const DEFAULT_DURATION = 15

export class AppointmentType {
    id!: number
    name!: string
    color!: string
    defaultDuration!: number
}

export function deserializeAppointmentType(obj: any): AppointmentType {
    const model: AppointmentType = Object.assign({}, obj)
    return model
}

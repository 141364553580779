import React, { useEffect, useRef, useState } from 'react'
import Toast from 'react-bootstrap/Toast'
import { OpenToast } from '../../services/event/eventactions'
import './Toast.scss'

export interface ToastProps {
    toasts: ToastItem[]
}

export interface ToastItem {
    show: boolean
    autohide?: boolean
    delay?: number
    message: any
    classes?: string
    onClose: () => void
}

const DEFAULT_DELAY = 3000
const DEFAULT_AUTO_HIDE = true

export function ToastWrapper(props: ToastProps) {
    const [toasts, setToasts] = useState<ToastItem[]>([])
    const toastsRef = useRef<ToastItem[]>(props.toasts ?? [])

    useEffect(() => {
        let _toasts = Array.from(toastsRef.current)
        _toasts = _toasts.concat(props.toasts || [])
        toastsRef.current = _toasts
        setToasts(_toasts)
    }, [props.toasts])

    return (
        <div className="toasts-wrapper">
            {toasts?.map((toastItem: ToastItem, index: number) => (
                <ToastCompoent key={index} {...toastItem} />
            ))}
        </div>
    )
}

function ToastCompoent(toastItem: ToastItem) {
    const [toast, setToast] = useState<ToastItem>(toastItem)

    const onClose = () => {
        const _toast: ToastItem = Object.assign({}, toast)
        _toast.show = false
        _toast.onClose()
        setToast(_toast)
    }

    return (
        <Toast
            show={toast.show}
            onClose={onClose}
            autohide={
                toast.autohide !== undefined
                    ? toast.autohide
                    : DEFAULT_AUTO_HIDE
            }
            delay={toast.delay !== undefined ? toast.delay : DEFAULT_DELAY}
            className={toast.classes || ''}
        >
            <Toast.Header className="d-flex justify-content-between align-items-start">
                <div>{toast.message}</div>
            </Toast.Header>
        </Toast>
    )
}

export const onOpenToast = (
    message: string,
    classes: string,
    autohide?: boolean,
    delay?: number,
    onClose?: () => void
) => {
    const toast: ToastItem = {
        show: true,
        message,
        classes,
        autohide: autohide !== undefined ? autohide : undefined,
        delay: delay !== undefined ? delay : undefined,
        onClose: onClose ? onClose : () => {},
    }
    OpenToast.emit(toast)
}

import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Redirect as RouterRedirect,
    Switch,
    useRouteMatch,
} from 'react-router-dom'
import { ROUTES } from '../App'
import { CancelAppointment } from './components/CancelAppointment/CancelAppointment'
import { ConfirmAppointment } from './components/ConfirmAppointment/ConfirmAppointment'

enum SUB_ROUTES {
    CONFIRM_APPOINTMENT = '/confirm-appointment',
    CANCEL_APPOINTMENT = '/cancel-appointment',
}

export function Redirect() {
    const { path } = useRouteMatch()

    return (
        <Router>
            <Switch>
                <Route path={path + SUB_ROUTES.CONFIRM_APPOINTMENT + '/:id'}>
                    <ConfirmAppointment />
                </Route>
                <Route path={path + SUB_ROUTES.CANCEL_APPOINTMENT + '/:id'}>
                    <CancelAppointment />
                </Route>
                <Route path={path + '/*'}>
                    <RouterRedirect
                        to={
                            process.env.REACT_APP_BASE_HREF +
                            ROUTES.AUTH +
                            ROUTES.SIGNIN
                        }
                    />
                </Route>
            </Switch>
        </Router>
    )
}

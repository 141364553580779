import { AxiosResponse } from 'axios'
import { Attachment, deserializeAttachment } from '../models/Message.model'
import {
    deserializeMessageThread,
    MessageThread,
} from '../models/MessageThread.model'
import { authService, ax } from './auth.service'

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/patientmail/patient`

const getMessages = (memberClinicIDs: number[]): Promise<MessageThread[]> => {
    return new Promise((resolve, reject) => {
        let url = `${baseURL}/messages/?q=q`
        memberClinicIDs?.forEach((id: number) => {
            url += '&memberClinicID=' + id
        })

        return ax
            .get(url)
            .then((response: AxiosResponse) => {
                if (response.data?.length) {
                    const messageThreads = response.data.map((obj: any) =>
                        deserializeMessageThread(obj)
                    )

                    // Sort by recent message in ascending order
                    messageThreads.sort(
                        (a: MessageThread, b: MessageThread) => {
                            if (!a.messages?.length || !b.messages?.length) {
                                return 0
                            }
                            const aDate = a.messages[0].sentAt
                            const bDate = b.messages[0].sentAt
                            return bDate.getTime() - aDate.getTime()
                        }
                    )

                    resolve(messageThreads)
                } else {
                    resolve([])
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

const markAsRead = (
    messageID: number,
    readAt: Date,
    memberClinicID: number
): Promise<void> => {
    const url = `${baseURL}/messages/mark-read/`
    const payload = {
        messageID,
        readAt,
        memberClinicID,
    }
    return ax.post(url, payload)
}

const acknowledge = (
    messageID: number,
    replyAt: Date,
    memberClinicID: number
): Promise<void> => {
    const url = `${baseURL}/messages/acknowledge/`
    const payload = {
        messageID,
        replyAt,
        memberClinicID,
    }
    return ax.post(url, payload)
}

const respond = (payload: RespondPayload): Promise<void> => {
    const url = `${baseURL}/messages/respond/`
    return ax.post(url, payload)
}

const upload = (clientName: string, file: File): Promise<Attachment> => {
    return new Promise((resolve, reject) => {
        const url = `${baseURL}/attachments/`

        const payload = new FormData()
        payload.set('clientName', `${clientName}`)
        payload.set('attachment', file)

        return ax
            .post(url, payload)
            .then((response: AxiosResponse) => {
                if (response?.data?.attachments?.length) {
                    const attachment = deserializeAttachment(
                        response?.data?.attachments[0]
                    )
                    resolve(attachment)
                } else {
                    reject()
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

const getDownloadLink = (
    clientName: string,
    attachmentID: number,
    demographicID: number
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const url = `${baseURL}/attachments/download_link/?attachmentID=${attachmentID}&clientName=${clientName}&demographicID=${demographicID}`

        return ax
            .get(url)
            .then((response: AxiosResponse) => {
                if (response?.data?.url) {
                    resolve(response.data.url)
                } else {
                    reject()
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

export interface RespondPayload {
    messageID: number
    attachment: number[]
    response: string
    replyAt: Date
    memberClinicID: number
}

export const getAuthHeader = (): {
    headers: {
        Authorization: string
    }
} | null => {
    const token = authService.getAuthToken()
    if (!token) {
        return null
    }
    return {
        headers: {
            Authorization: token,
        },
    }
}

export const patientmessagingService = {
    getMessages,
    markAsRead,
    acknowledge,
    respond,
    upload,
    getDownloadLink,
}

import React from 'react'
import { FamilyMember } from '../../../services/models/Patient.model'
import { Avatar } from '../../../UI/Avatar/Avatar'
import { useFamilyMember } from '../../hooks/useFamilyMember'

interface Props {
    isSelected: boolean
    familyMember: FamilyMember
    onToggle: (isSelected: boolean) => void
}

export function Member(props: Props) {
    const { getPatientNameByFamilyMember } = useFamilyMember()
    const patientName = getPatientNameByFamilyMember(props.familyMember)

    return (
        <div
            className="member-wrapper"
            onClick={() => props.onToggle(!props.isSelected)}
        >
            <div className="section-left">
                <i
                    className={`material-icons ${
                        props.isSelected ? 'color-blue' : 'color-text-secondary'
                    }`}
                >
                    {props.isSelected ? 'check_box' : 'check_box_outline_blank'}
                </i>
            </div>
            <div className="section-right">
                <Avatar name={patientName} />
                <div className="font-weight-500 ml-2">{patientName}</div>
            </div>
        </div>
    )
}

import { ErrorMessage } from '@hookform/error-message'
import React, { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
    AUTHREGEX_PASSWORD_STRENGTH,
    authService,
} from '../../../../services/http/auth.service'
import { onOpenToast } from '../../../../UI/Toast/Toast'

interface FormObj {
    currentPassword: string
    password: string
    confirmPassword: string
}

export function UpdatePassword() {
    const { register, handleSubmit, errors, watch } = useForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onSubmit = (data: FormObj) => {
        setIsLoading(true)
        authService
            .changePassword(data.currentPassword, data.password)
            .then(
                () => {
                    onOpenToast('Password Updated', 'success')
                },
                () => {
                    onOpenToast(
                        'Unable to Update Password. Please check your Current Password',
                        'error'
                    )
                }
            )
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Row>
                    <Col md={12}>
                        <div className="font-weight-500 mb-4">
                            Update Password
                        </div>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={12}>
                        <Form.Label>Current Password</Form.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.password?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="currentPassword"
                            type="password"
                            tabIndex={2}
                            placeholder="Current Password"
                            ref={register({
                                required: 'Current Password is required',
                            })}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="currentPassword"
                            as="div"
                        />
                    </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                    <Col md={12}>
                        <Form.Label>New Password</Form.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.password?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="password"
                            type="password"
                            tabIndex={2}
                            placeholder="New Password"
                            ref={register({
                                required: 'New Password is required',
                                pattern: {
                                    value: AUTHREGEX_PASSWORD_STRENGTH,
                                    message:
                                        'Use 8 or more characters with a mix of letters, numbers & symbols',
                                },
                            })}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="password"
                            as="div"
                        />
                    </Col>
                </Form.Row>

                <Form.Row className="mt-4">
                    <Col md={12}>
                        <Form.Label>Confirm Password</Form.Label>
                        <input
                            className={
                                'form-control' +
                                (errors?.confirmPassword?.message
                                    ? ' invalid-state'
                                    : '')
                            }
                            name="confirmPassword"
                            type="password"
                            tabIndex={3}
                            placeholder="Confirm Password"
                            ref={register({
                                required: 'Confirm Password is required',
                                validate: (value) =>
                                    value === watch('password') ||
                                    'Passwords do not match',
                            })}
                        />

                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="confirmPassword"
                            as="div"
                        />
                    </Col>
                </Form.Row>

                <Form.Row className="mt-5">
                    <Col md={12} className="text-center">
                        <input
                            className={
                                'width-unset primary ' +
                                (isLoading ? ' is-loading' : '')
                            }
                            type="submit"
                            value="Save"
                        />
                    </Col>
                </Form.Row>
            </Form>
        </div>
    )
}

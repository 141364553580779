import { AddMembersModalProps } from '../../main/extra/add-members/AddMembersModal'
import { MemberDetailsModalProps } from '../../main/extra/member-details/MemberDetailsModal'
import { RequestProviderModalProps } from '../../main/extra/request-provider/RequestProviderModal'
import { SwitchMemberModalProps } from '../../main/extra/switch-member/SwitchMemberModal'
import { ToastItem } from '../../UI/Toast/Toast'
import { eventBus, EventBusData } from './eventbus.service'

export class OpenToast {
    static readonly type: string = 'OpenToast'
    static emit = (toast: ToastItem) => emit(OpenToast.type, toast)
}

export class OpenSwitchMemberModal {
    static readonly type: string = 'OpenSwitchMemberModal'
    static emit = (modalProps: SwitchMemberModalProps) =>
        emit(OpenSwitchMemberModal.type, modalProps)
}

export class OpenAddMembersModal {
    static readonly type: string = 'OpenAddMembersModal'
    static emit = (modalProps: AddMembersModalProps) =>
        emit(OpenAddMembersModal.type, modalProps)
}

export class OpenMemberDetailsModal {
    static readonly type: string = 'OpenMemberDetailsModal'
    static emit = (modalProps: MemberDetailsModalProps) =>
        emit(OpenMemberDetailsModal.type, modalProps)
}

export class OpenRequestProviderModal {
    static readonly type: string = 'OpenRequestProviderModal'
    static emit = (modalProps: RequestProviderModalProps) =>
        emit(OpenRequestProviderModal.type, modalProps)
}

const emit = (type: string, payload?: any) => {
    const eventData: EventBusData = {
        action: type,
        payload,
    }
    eventBus.emit(eventData)
}

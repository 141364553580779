import { AxiosResponse } from 'axios'
import { deserializeProvider, Provider } from '../models/Provider.model'
import { deserializeClient, Client } from '../models/Client.model'
import { ax } from './auth.service'

const baseURL = `${process.env.REACT_APP_API_BASE_URL}/providerindex`

export const providerIndexService = {
    getClients: (): Promise<Client[]> => getClients(),
    getProviders: (clientName: string): Promise<Provider[]> =>
        getProviders(clientName),
}

const getClients = (): Promise<Client[]> => {
    return new Promise((resolve, reject) => {
        const url = `${baseURL}/patient/client/`
        ax.get(url)
            .then((response: AxiosResponse) => {
                if (response.data?.clients?.length) {
                    const clients = response.data.clients.map((obj: any) =>
                        deserializeClient(obj)
                    )

                    resolve(clients)
                } else {
                    resolve([])
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

const getProviders = (clientName: string): Promise<Provider[]> => {
    return new Promise((resolve, reject) => {
        const url = `${baseURL}/patient/client/providers?clientName=${clientName}`
        ax.get(url)
            .then((response: AxiosResponse) => {
                if (response.data?.providers?.length) {
                    const providers = response.data.providers.map((obj: any) =>
                        deserializeProvider(obj)
                    )

                    resolve(providers)
                } else {
                    resolve([])
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
}

import { Observable, Subject } from 'rxjs'

export interface EventBusData {
    action: string
    payload?: any
}

const subject: Subject<EventBusData> = new Subject()

export const eventBus = {
    emit(event: EventBusData) {
        subject.next(event)
    },

    getObservable: (): Observable<EventBusData> => subject.asObservable(),
}

import { PAGE } from '../../main/hooks/usePage'
import { Client } from '../models/Client.model'
import { FamilyMember, Patient } from '../models/Patient.model'
import { Provider } from '../models/Provider.model'

export interface State {
    currentPage: PAGE
    patient: Patient | null
    clients: Client[]
    clientProvidersMap: Map<string, Provider[]> // Key: clientName, Value: Array of providers for that clientName
    familyMembersMap: Map<number, FamilyMember> // Key: familyMemberID, Value: familyMember object
    familyMemberID: number | null
}

export const initalState: State = {
    currentPage: PAGE.APPOINTMENTS,
    patient: null,
    clients: [],
    clientProvidersMap: new Map<string, Provider[]>(),
    familyMembersMap: new Map<number, FamilyMember>(),
    familyMemberID: null,
}

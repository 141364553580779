import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { OpenMemberDetailsModal } from '../../../services/event/eventactions'
import './MemberDetailsModal.scss'
import { Form } from './Form'

export interface MemberDetailsModalProps {
    memberDetails: MemberDetails | undefined
    show: boolean
    onSaved: (memberDetails: MemberDetails) => void
    onClose: () => void
}

export function MemberDetailsModal(props: MemberDetailsModalProps) {
    const onSaved = (memberDetails: MemberDetails) => {
        props.onSaved(memberDetails)
        props.onClose()
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            dialogClassName="member-details-modal-wrapper modal-wrapper"
            animation={false}
            centered
        >
            <Modal.Header className="px-4 member-details-modal-header justify-content-center">
                Member Details
            </Modal.Header>

            <Modal.Body>
                <div className="member-details-modal-body">
                    <Form
                        memberDetails={props.memberDetails}
                        onSaved={onSaved}
                        onCancel={props.onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export const onOpenMemberDetailsModal = (
    onSaved: (memberDetails: MemberDetails) => void,
    memberDetails?: MemberDetails | undefined,
    onClose?: () => void
) => {
    const modalProps: MemberDetailsModalProps = {
        memberDetails,
        show: true,
        onSaved,
        onClose: () => onClose && onClose(),
    }
    OpenMemberDetailsModal.emit(modalProps)
}

export interface MemberDetails {
    firstName: string
    lastName: string
    gender: string
    birthday: string
    healthCardNumber: string
}

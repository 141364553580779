import React from 'react'
import { Provider } from 'react-redux'
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom'
import { Authentication } from './authentication/Authentication'
import { GenericError } from './GenericError/GenericError'
import { Landing } from './landing/Landing'
import { Main } from './main/Main'
import { MessagesContextProvider } from './main/messages/hooks/useMessagesContext'
import { Policy } from './policy/Policy'
import { Redirect as RedirectComponent } from './redirect/Redirect'
import { authService } from './services/http/auth.service'
import { createAppStore } from './services/store/store'

export enum ROUTES {
    AUTH = '/auth',
    SIGNIN = '/signin',
    SIGNUP = '/signup',
    SIGNUP_SUCCESS = '/signup-success',
    FORGOT_PASSWORD = '/forgot-password',
    RESET_PASSWORD = '/reset-password',
    VERIFY_EMAIL = '/verify-email',
    REDIRECT = '/redirect',
    HOME = '/index',
    POLICY = '/policy',
    ERROR = '/error',
}

export const store = createAppStore()

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Landing />
                </Route>
                <Route path={process.env.REACT_APP_BASE_HREF + ROUTES.AUTH}>
                    <Authentication />
                </Route>
                <Route path={process.env.REACT_APP_BASE_HREF + ROUTES.REDIRECT}>
                    <RedirectComponent />
                </Route>
                <Route path={process.env.REACT_APP_BASE_HREF + ROUTES.ERROR}>
                    <GenericError />
                </Route>
                <Route path={process.env.REACT_APP_BASE_HREF + ROUTES.POLICY}>
                    <Policy />
                </Route>
                <PrivateRoute
                    path={process.env.REACT_APP_BASE_HREF + ROUTES.HOME}
                >
                    <Provider store={store}>
                        <MessagesContextProvider>
                            <Main />
                        </MessagesContextProvider>
                    </Provider>
                </PrivateRoute>
                <Route path="*">
                    <Redirect
                        to={process.env.REACT_APP_BASE_HREF + ROUTES.HOME}
                    />
                </Route>
            </Switch>
        </Router>
    )
}

function PrivateRoute({ children, ...rest }: any) {
    const isAuthorized = authService.isAuthenticated()

    if (isAuthorized) {
        return (
            <Route
                {...rest}
                render={() =>
                    isAuthorized ? (
                        children
                    ) : (
                        <Redirect
                            to={process.env.REACT_APP_BASE_HREF + ROUTES.AUTH}
                        />
                    )
                }
            />
        )
    } else {
        return <Redirect to={process.env.REACT_APP_BASE_HREF + ROUTES.AUTH} />
    }
}

export default App

import { useCallback, useContext } from 'react'
import { Provider } from '../../../services/models/Provider.model'
import { Clinic } from '../models/Clinic.model'
import { AppointmentsContext } from './useAppointmentsContext'

export const useClinic = () => {
    const appointmentsContext = useContext(AppointmentsContext)

    const getClinicByClientName = useCallback(
        (clientName: string): Clinic | null => {
            return appointmentsContext.state.clinicsMap.get(clientName) || null
        },
        [appointmentsContext.state.clinicsMap]
    )

    const getClinicByProvider = useCallback(
        (provider: Provider | null): Clinic | null => {
            return (
                (provider && getClinicByClientName(provider.clientName)) || null
            )
        },
        [getClinicByClientName]
    )

    return {
        getClinicByClientName,
        getClinicByProvider,
    }
}

import React, { useMemo, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Provider } from '../../../../services/models/Provider.model'
import { useProviderIndex } from '../../../hooks/useProviderIndex'
import { useAppointmentType } from '../../hooks/useAppointmentType'
import {
    AppointmentType,
    DEFAULT_COLOR,
} from '../../models/AppointmentType.model'

interface Props {
    providerID: number
    clientName: string
    appointmentTypeID: number
    options: AppointmentType[]
    onSelected: (...event: any[]) => void
}

export function AppointmentTypeField(props: Props) {
    const ref = useRef<any>()
    const { getProviderByClientName } = useProviderIndex()
    const { getAppointmentTypeByIdAndClientName } = useAppointmentType()

    const provider = useMemo((): Provider | null => {
        return (
            getProviderByClientName(props.providerID, props.clientName) || null
        )
    }, [props.providerID, props.clientName, getProviderByClientName])

    const initialOption = useMemo((): AppointmentType | null => {
        if (!provider || !props.appointmentTypeID) {
            return null
        }
        return getAppointmentTypeByIdAndClientName(
            provider.clientName,
            props.appointmentTypeID
        )
    }, [props.appointmentTypeID, provider, getAppointmentTypeByIdAndClientName])

    return (
        <Typeahead
            id="appointmenttype-typeahead"
            ref={ref}
            options={props.options}
            defaultSelected={initialOption ? [initialOption] : undefined}
            filterBy={() => true}
            labelKey={(option: AppointmentType) => option.name ?? ''}
            renderMenuItemChildren={(option: AppointmentType) => {
                return (
                    <div className="d-flex align-items-center">
                        <i
                            className={'material-icons mr-3'}
                            style={{ color: option.color || DEFAULT_COLOR }}
                        >
                            label
                        </i>
                        <span>{option.name}</span>
                    </div>
                )
            }}
            onChange={(selected: AppointmentType[]) => {
                if (selected?.length <= 0) {
                    return
                }
                props.onSelected(selected[0].id)
            }}
            placeholder={'Select Appointment Type'}
            emptyLabel={'No Appointment Type exists'}
            // @ts-ignore
            renderInput={({
                inputRef,
                referenceElementRef,
                ...inputProps
            }: any) => {
                return (
                    <div className="position-relative d-flex align-items-center">
                        {initialOption && (
                            <div className="position-absolute left-16 d-flex align-content-center">
                                <i
                                    className={'material-icons mr-3'}
                                    style={{
                                        color:
                                            initialOption.color ||
                                            DEFAULT_COLOR,
                                    }}
                                >
                                    label
                                </i>
                            </div>
                        )}
                        <Form.Control
                            {...inputProps}
                            className={
                                'readonly-dropdown' +
                                (initialOption ? ' pl-5' : '')
                            }
                            readOnly
                            ref={(node: any) => {
                                if (node && initialOption) {
                                    node.value = initialOption.name
                                }
                                inputRef(node)
                                referenceElementRef(node)
                            }}
                        />
                    </div>
                )
            }}
        />
    )
}

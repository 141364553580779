import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Provider } from '../../services/models/Provider.model'
import { appSelector } from '../../services/store/selectors/appSelector'

export const useProviderIndex = () => {
    const state = useSelector(appSelector)

    const getProviderByClientName = useCallback(
        (providerID: number, clientName: string): Provider | null => {
            const providers: Provider[] =
                state.clientProvidersMap.get(clientName) || []

            return (
                providers?.find((i: Provider) => i.providerID === providerID) ||
                null
            )
        },
        [state.clientProvidersMap]
    )

    return {
        getProviderByClientName,
    }
}

export class Client {
    name!: string
    displayName!: string
    address!: string
    description!: string
    phoneNumber!: string
    createdAt!: Date
}

export function deserializeClient(obj: any): Client {
    const model: Client = Object.assign({}, obj)
    if (obj.createdAt) {
        model.createdAt = new Date(obj.createdAt)
    }
    return model
}

import React from 'react'
import './PageCard.scss'

interface Props {
    title?: string
    subtitle?: string
    classes?: string
    progressBar?: React.ReactNode
    children: React.ReactNode
}

export function PageCard(props: Props) {
    return (
        <div
            className={
                'pagecard-wrapper max-content-width ' + (props.classes || '')
            }
        >
            <div className="pagecard-inner">
                {props.progressBar && (
                    <div className="mb-5">{props.progressBar}</div>
                )}
                {props.title && (
                    <div className="title font-family-playfair-display">
                        {props.title}
                    </div>
                )}
                {props.subtitle && (
                    <div className="subtitle">{props.subtitle}</div>
                )}
                <div className="mt-5">{props.children}</div>
            </div>
        </div>
    )
}

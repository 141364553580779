import { createSelector } from 'reselect'
import { PAGE } from '../../../main/hooks/usePage'
import { Client } from '../../models/Client.model'
import { FamilyMember, Patient } from '../../models/Patient.model'
import { Provider } from '../../models/Provider.model'
import { State } from '../state'

const currentPageSelector = createSelector(
    (state: State) => state.currentPage,
    (currentPage: PAGE) => {
        return currentPage
    }
)

const patientSelector = createSelector(
    (state: State) => state.patient,
    (patient: Patient | null) => {
        return patient
    }
)

const clientsSelector = createSelector(
    (state: State) => state.clients,
    (clients: Client[]) => {
        return clients
    }
)

const clientProvidersMapSelector = createSelector(
    (state: State) => state.clientProvidersMap,
    (clientProvidersMap: Map<string, Provider[]>) => {
        return clientProvidersMap
    }
)

const familyMemberIDSelector = createSelector(
    (state: State) => state.familyMemberID,
    (familyMemberID: number | null) => {
        return familyMemberID
    }
)

const familyMembersMapSelector = createSelector(
    (state: State) => state.familyMembersMap,
    (familyMembersMap: Map<number, FamilyMember>) => {
        return familyMembersMap
    }
)

export const appSelector = createSelector(
    [
        currentPageSelector,
        patientSelector,
        clientsSelector,
        clientProvidersMapSelector,
        familyMemberIDSelector,
        familyMembersMapSelector,
    ],
    (
        currentPage,
        patient,
        clients,
        clientProvidersMap,
        familyMemberID,
        familyMembersMap
    ) => {
        return {
            currentPage,
            patient,
            clients,
            clientProvidersMap,
            familyMemberID,
            familyMembersMap,
        }
    }
)

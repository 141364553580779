import React from 'react'
import { PageCard } from '../UI/PageCard/PageCard'

export function GenericError() {
    return (
        <React.Fragment>
            <PageCard
                classes="p-4"
                title="Whoops!"
                subtitle="An error has occurred."
            >
                <></>
            </PageCard>
        </React.Fragment>
    )
}

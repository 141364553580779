import React, { useMemo, useRef } from 'react'
import { Typeahead, TypeaheadProps } from 'react-bootstrap-typeahead'
import Moment from 'react-moment'
import { MemberProvider } from '../../../../services/models/Patient.model'
import { MOMENT_DATETIME_FORMAT } from '../../../constants'
import { onOpenRequestProviderModal } from '../../../extra/request-provider/RequestProviderModal'
import { useFamilyMember } from '../../../hooks/useFamilyMember'
import { useClinic } from '../../hooks/useClinic'
import { useProvider } from '../../hooks/useProvider'
import { Clinic } from '../../models/Clinic.model'
import { Provider } from '../../models/Provider.model'
import { ProviderCard } from '../ProviderCard/ProviderCard'

interface Props {
    selectExistingProviders: boolean
    onSelected: (...event: any[]) => void
    onMemberProvidersRequested: (memberProviders: MemberProvider[]) => void
}

interface Option {
    provider: Provider
    clinic: Clinic | null
}

export function ProviderField(props: Props) {
    const ref = useRef<any>()
    const { providers } = useProvider()
    const { getClinicByClientName } = useClinic()
    const {
        approvedMembersProviderFromCurrentFamilyMember,
        getMemberProviderRequestStatus,
    } = useFamilyMember()

    const options = useMemo((): Option[] => {
        if (
            props.selectExistingProviders &&
            !approvedMembersProviderFromCurrentFamilyMember?.length
        ) {
            return []
        }

        return providers
            .filter((provider: Provider) => {
                const isProviderApproved = approvedMembersProviderFromCurrentFamilyMember.some(
                    (i: MemberProvider) =>
                        i.providerID === provider.providerNo &&
                        i.clientName === provider.clientName
                )
                return props.selectExistingProviders
                    ? isProviderApproved
                    : !isProviderApproved
            })
            .map((provider: Provider) => {
                return {
                    provider,
                    clinic: getClinicByClientName(provider.clientName),
                }
            })
    }, [
        props.selectExistingProviders,
        providers,
        getClinicByClientName,
        approvedMembersProviderFromCurrentFamilyMember,
    ])

    const onSelect = (selected: Option[]) => {
        ref?.current?.clear()
        if (selected?.length <= 0) {
            return
        }
        const option = selected.pop()!
        const requestStatus = getMemberProviderRequestStatus(
            option.provider.providerNo,
            option.provider.clientName,
            option.provider.isOnlineBookingEnabled
        )
        if (
            !requestStatus.isOnlineBookingEnabled ||
            requestStatus.isRequestPending
        ) {
            return
        } else if (requestStatus.canSendRequest) {
            onOpenRequestProviderModal(
                option.provider.providerNo,
                option.provider.fullName!,
                option.provider.clientName,
                props.onMemberProvidersRequested
            )
            return
        }

        const provider = option.provider
        props.onSelected(provider, requestStatus.demographicID)
    }

    return (
        <div className="search-provider-wrapper mb-4">
            <Typeahead
                id="provider-typeahead"
                ref={ref}
                options={options}
                filterBy={(option: Option, _props) => {
                    if (!_props.text) {
                        return true
                    }
                    const regex = new RegExp(_props.text, 'i')
                    return (
                        regex.test(option.provider?.fullName ?? '') ||
                        regex.test(option.clinic?.displayName ?? '')
                    )
                }}
                labelKey={(option: Option) => option.provider.fullName ?? ''}
                renderMenuItemChildren={(
                    option: Option,
                    _props: TypeaheadProps<Option>
                ) => {
                    const requestStatus = getMemberProviderRequestStatus(
                        option.provider.providerNo,
                        option.provider.clientName,
                        option.provider.isOnlineBookingEnabled
                    )
                    return (
                        <ProviderOption
                            provider={option.provider}
                            isOnlineBookingEnabled={
                                requestStatus.isOnlineBookingEnabled
                            }
                            canSendRequest={requestStatus.canSendRequest}
                            isRequestPending={requestStatus.isRequestPending}
                            createdAt={requestStatus.createdAt}
                        />
                    )
                }}
                onChange={onSelect}
                placeholder="Select Provider"
                emptyLabel="No Providers exist"
            />
        </div>
    )
}

const ProviderOption = ({
    provider,
    isOnlineBookingEnabled,
    canSendRequest,
    isRequestPending,
    createdAt,
}: {
    provider: Provider
    isOnlineBookingEnabled: boolean
    isRequestPending: boolean
    canSendRequest: boolean
    createdAt: Date | null
}) => {
    return (
        <div>
            <ProviderCard
                providerID={provider.providerNo}
                clientName={provider.clientName}
            />
            <div className="text-right">
                {isOnlineBookingEnabled ? (
                    <>
                        {canSendRequest ? (
                            <div className="div-button color-blue">
                                Send Request
                            </div>
                        ) : isRequestPending ? (
                            <div className="div-button color-text-hint">
                                {createdAt !== null ? (
                                    <span>
                                        Request sent on{' '}
                                        <Moment format={MOMENT_DATETIME_FORMAT}>
                                            {createdAt}
                                        </Moment>
                                    </span>
                                ) : (
                                    <span>Request Pending</span>
                                )}
                            </div>
                        ) : null}
                    </>
                ) : (
                    <div className="div-button color-text-hint">
                        Online Booking Not Enabled
                    </div>
                )}
            </div>
        </div>
    )
}

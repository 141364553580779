export class Range {
    startAt!: Date
    endAt!: Date
}

export function deserializeRange(obj: any): Range {
    const model: Range = Object.assign({}, obj)
    if (obj.startAt) {
        model.startAt = new Date(obj.startAt)
    }
    if (obj.endAt) {
        model.endAt = new Date(obj.endAt)
    }
    return model
}

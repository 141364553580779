import React from 'react'
import styled from 'styled-components'
import Colors from '../../colors'

interface Props {
    value?: number
    classes?: any
    hideCount?: boolean
    offsetTop?: number
    offsetRight?: number
}

const Styles = styled.div`
    min-width: 10px;
    min-height: 10px;
    max-height: 14px;
    padding: 2px 5px;
    border-radius: 100px;
    background-color: ${() => Colors.RED};
    position: relative;
    top: ${(props) => props.theme.offsetTop || 0}px;
    right: ${(props) => props.theme.offsetRight || 0}px;

    .text {
        line-height: 11px;
        font-size: 11px;
        font-weight: bold;
        color: white;
    }
`

export const Badge = ({
    value,
    classes,
    hideCount,
    offsetTop,
    offsetRight,
}: Props) => {
    if (!value && !hideCount) {
        return null
    }

    return (
        <Styles className={classes || ''} theme={{ offsetTop, offsetRight }}>
            {!hideCount && <div className="text">{value}</div>}
        </Styles>
    )
}

import React, { useMemo } from 'react'
import { onOpenSwitchMemberModal } from '../../main/extra/switch-member/SwitchMemberModal'
import { useFamilyMember } from '../../main/hooks/useFamilyMember'
import { PAGE, usePage } from '../../main/hooks/usePage'
import { Avatar } from '../Avatar/Avatar'
import './AppBar.scss'

export function AppBar() {
    const { currentPage } = usePage()
    const { patientName, familyMembers } = useFamilyMember()

    const canShowSwitchMember = useMemo(() => familyMembers?.length > 1, [
        familyMembers,
    ])

    return (
        <div className="app-bar-wrapper d-flex justify-content-between d-md-none">
            {canShowSwitchMember && (
                <div onClick={() => onOpenSwitchMemberModal()}>
                    {patientName && <Avatar name={patientName} />}
                </div>
            )}
            <div className="content-center">
                {currentPage === PAGE.APPOINTMENTS && <div>Appointments</div>}
                {currentPage === PAGE.MESSAGES && <div>Messages</div>}
                {currentPage === PAGE.ACCOUNT && <div>Account</div>}
            </div>
        </div>
    )
}

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { ROUTES } from '../../App'
import {
    deserializeFamilyMember,
    deserializeMemberProvider,
    deserializePatient,
    FamilyMember,
    MemberProvider,
    Patient,
} from '../models/Patient.model'
import { getAuthHeader } from './patientmessaging.service'

export const AUTHREGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
export const AUTHREGEX_PASSWORD_STRENGTH = /^.{8,}$/
export const AUTHREGEX_DATE = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/

const LOCALSTORAGE_KEY = 'AVS_PT_TOKEN'

export interface SignUpPayload {
    email: string
    password: string
    firstName: string
    lastName: string
    birthday: string
    gender: string
    healthCardNumber?: number
    healthCardVersion?: string
    healthCardProvince?: string
    settings: {
        pocketPillsEnabled: boolean
    }
}

export interface UuidSignUpPayload {
    uuid: string
    email: string
    password: string
    birthday: string
    settings: {
        pocketPillsEnabled: boolean
    }
}

export interface VerifySignUpPayload {
    uuid: string
    email: string
    dateOfBirth: string
}

export interface UpdateProfilePayload {
    email: string
    firstName: string
    lastName: string
    birthday: string
    gender: string
    healthCardNumber?: number
    healthCardVersion?: string
    healthCardProvince?: string
    settings: {
        pocketPillsEnabled: boolean
    }
}

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/patientauth`
const refreshTokenUrl = `${baseUrl}/refresh/`

export const authService = {
    isAuthenticated: (): boolean => {
        return !!getToken()
    },

    getAuthToken: (): string | null => {
        return getToken()
    },

    getPatient: (): Patient | null => {
        return getPatient()
    },

    signin: (email: string, password: string) => {
        const payload = {
            email,
            password,
        }

        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/signin/`, payload).then(
                (response: AxiosResponse) => {
                    const token = response.headers?.authorization
                    if (token) {
                        storeToken(token)
                        if (response.data) {
                            const patient = deserializePatient(response.data)
                            if (patient) {
                                storePatient(patient)
                                resolve(true)
                            } else {
                                reject(true)
                            }
                        } else {
                            reject(true)
                        }
                    } else {
                        reject(true)
                    }
                },
                () => {
                    reject(true)
                }
            )
        })
    },

    signup: (payload: SignUpPayload) => {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/signup/`, payload).then(
                () => resolve(true),
                (error: AxiosError) => {
                    reject(error?.response?.data ?? true)
                }
            )
        })
    },

    uuidSignup: (payload: UuidSignUpPayload) => {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/uuid-signup/`, payload).then(
                () => resolve(true),
                (error: AxiosError) => {
                    reject(error?.response?.data ?? true)
                }
            )
        })
    },

    signout: () => {
        clearToken()
        window.location.reload()
    },

    verifyUuid: (
        uuid: string
    ): Promise<{
        email: string
        firstName: string
        isAvailable: boolean
    }> => {
        return new Promise((resolve, reject) => {
            axios.get(`${baseUrl}/preapproval/?uuid=${uuid}`).then(
                (response: AxiosResponse) => {
                    const data = response.data
                    if (data) {
                        resolve(data)
                    } else {
                        reject()
                    }
                },
                (error: AxiosError) => {
                    reject(error?.response?.data ?? true)
                }
            )
        })
    },

    verifySignUp: (payload: VerifySignUpPayload) => {
        return new Promise((resolve, reject) => {
            axios.post(`${baseUrl}/preapproval/verify/`, payload).then(
                () => resolve(true),
                (error: AxiosError) => {
                    reject(error?.response?.data ?? true)
                }
            )
        })
    },

    verifyEmail: (secret: string) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${baseUrl}/confirm/?secret=${secret}`)
                .then(
                    () => resolve(true),
                    () => reject(true)
                )
                .catch(() => reject(true))
        })
    },

    isEmailAvailable: (email: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${baseUrl}/email/available/?email=${email}`)
                .then(
                    (response: AxiosResponse) => {
                        if (response.data.isAvailable) {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    },
                    () => reject(true)
                )
                .catch(() => reject(true))
        })
    },

    isHinAvailable: (hin: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${baseUrl}/hin/available/?hin=${hin}`)
                .then(
                    (response: AxiosResponse) => {
                        if (response.data.isAvailable) {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    },
                    () => reject(true)
                )
                .catch(() => reject(true))
        })
    },

    forgotPassword: (email: string) => {
        const payload = {
            email,
        }
        return axios.post(`${baseUrl}/password/forgot`, payload)
    },

    updateProfile: (payload: UpdateProfilePayload) => {
        const config = getAuthHeader()
        if (!config) {
            return Promise.reject()
        }
        return ax.post(`${baseUrl}/patient/`, payload, config)
    },

    changePassword: (oldPassword: string, newPassword: string) => {
        const payload = {
            oldPassword,
            newPassword,
        }
        const config = getAuthHeader()
        if (!config) {
            return Promise.reject()
        }
        return ax.post(`${baseUrl}/password/`, payload, config)
    },

    resetPassword: (secret: string, password: string) => {
        const payload = {
            secret,
            password,
        }
        return axios.post(`${baseUrl}/password/forgot/reset`, payload)
    },

    addMember: (payload: AddMemberPayload): Promise<FamilyMember> => {
        const url = `${baseUrl}/member/`
        return new Promise((resolve, reject) => {
            ax.post(url, payload)
                .then(
                    (response: AxiosResponse) => {
                        if (response.data?.familyMember) {
                            const familyMember = deserializeFamilyMember(
                                response.data.familyMember
                            )
                            resolve(familyMember)
                        } else {
                            reject(null)
                        }
                    },
                    () => reject(null)
                )
                .catch(() => reject(null))
        })
    },

    requestProvider: (
        payload: RequestProviderPayload
    ): Promise<MemberProvider> => {
        const url = `${baseUrl}/member/provider/`
        return new Promise((resolve, reject) => {
            ax.post(url, payload)
                .then(
                    (response: AxiosResponse) => {
                        if (response.data?.provider) {
                            const memberProvider = deserializeMemberProvider(
                                response.data.provider
                            )
                            resolve(memberProvider)
                        } else {
                            reject(null)
                        }
                    },
                    () => reject(null)
                )
                .catch(() => reject(null))
        })
    },

    refreshToken: (): Promise<AxiosResponse> => {
        return new Promise((resolve, reject) => {
            axios
                .post(refreshTokenUrl)
                .then(
                    (response: AxiosResponse) => resolve(response),
                    () => reject(true)
                )
                .catch(() => reject(true))
        })
    },
}

export interface AuthToken {
    email: string
    exp: number
    firstName: string
    id: number
    lastName: string
}

export interface AddMemberPayload {
    firstName: string
    lastName: string
    birthday: string
    gender: string
    healthCardNumber?: number
    healthCardVersion?: string
    healthCardProvince?: string
}

export interface RequestProviderPayload {
    providerID: number
    clientName: string
    familyMemberID: number
}

export const getAuthToken = (): Promise<AuthToken> => {
    const token = authService.getAuthToken()
    if (token) {
        const decodedToken: AuthToken = jwtDecode<AuthToken>(token)
        return Promise.resolve(decodedToken)
    } else {
        return Promise.reject()
    }
}

const createAxiosWithInterceptor = (): AxiosInstance => {
    const a = axios.create()

    // Attach auth token
    a.interceptors.request.use((config) => {
        const token = getToken()
        if (token) {
            config.headers.Authorization = token
        }
        return config
    })

    // 401 inteceptor
    a.interceptors.response.use(
        (response) => response,
        (error) => {
            const originalRequest = error.config

            // If refresh token has expired, then navigate to login page
            if (
                error.response.status === 401 &&
                originalRequest.url === refreshTokenUrl
            ) {
                window.location.href =
                    process.env.REACT_APP_BASE_HREF +
                    ROUTES.AUTH +
                    ROUTES.SIGNIN
                return Promise.reject(error)
            }

            // If auth token has expired, then refresh the token and continue requesting original request
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true
                return authService
                    .refreshToken()
                    .then((response: AxiosResponse) => {
                        if (response.status === 200) {
                            // Store refreshed auth token
                            const token = response.headers?.authorization
                            if (token) {
                                storeToken(token)
                            } else {
                                return Promise.reject(error)
                            }

                            // Make original request again with refreshed auth token
                            originalRequest.headers.Authorization = token
                            return axios(originalRequest)
                        }
                    })
                    .catch(() => {
                        window.location.href =
                            process.env.REACT_APP_BASE_HREF +
                            ROUTES.AUTH +
                            ROUTES.SIGNIN
                        return Promise.reject(error)
                    })
            }

            return Promise.reject(error)
        }
    )

    return a
}

export const ax = createAxiosWithInterceptor()

function getToken(): string | null {
    const obj = localStorage.getItem(LOCALSTORAGE_KEY)
    if (obj) {
        return JSON.parse(obj)?.token ?? null
    }
    return null
}

function storeToken(token: string) {
    const item = localStorage.getItem(LOCALSTORAGE_KEY)
    const obj = item ? JSON.parse(item) : {}
    localStorage.setItem(
        LOCALSTORAGE_KEY,
        JSON.stringify({
            ...obj,
            token,
        })
    )
}

function getPatient(): Patient | null {
    const obj = localStorage.getItem(LOCALSTORAGE_KEY)
    if (obj) {
        let patient = null
        const patientObj = JSON.parse(obj)?.patient
        if (patientObj) {
            patient = deserializePatient(patientObj)
        }
        return patient
    }
    return null
}

export function storePatient(patient: Patient) {
    const item = localStorage.getItem(LOCALSTORAGE_KEY)
    const obj = item ? JSON.parse(item) : {}
    patient.id = 1
    localStorage.setItem(
        LOCALSTORAGE_KEY,
        JSON.stringify({
            ...obj,
            patient,
        })
    )
}

function clearToken() {
    localStorage.removeItem(LOCALSTORAGE_KEY)
}

import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

interface Props {
    classes?: string
    initialValue: string
    disabled?: boolean
    onChange: (...event: any[]) => void
}

interface ProvinceOption {
    value: string
    display: string
}

export function HealthCardProvinceField(props: Props) {
    const ref = useRef<any>()
    const [provinceOptions, setProvinceOptions] = useState<ProvinceOption[]>([])

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/assets/data/provinces.json`)
            .then((response: Response) => response.json())
            .then((data: any[]) => {
                if (data) {
                    setProvinceOptions(data)
                }
            })
    }, [])

    const getDefaultProvince = useCallback(
        (value: string): ProvinceOption[] | undefined => {
            if (!value?.length) {
                ref?.current?.clear()
                return undefined
            }

            const option = provinceOptions.find(
                (iterator: ProvinceOption) => iterator.value === value
            )
            if (option) {
                return [option]
            }
            return undefined
        },
        [provinceOptions]
    )

    return (
        <div>
            {provinceOptions?.length > 0 && (
                <Typeahead
                    ref={ref}
                    id="province-dropdown"
                    placeholder="Select Province"
                    options={provinceOptions}
                    selected={getDefaultProvince(props.initialValue)}
                    filterBy={() => true}
                    labelKey={(option: ProvinceOption) => option.display}
                    onChange={(option: ProvinceOption[]) => {
                        if (option?.length) {
                            props.onChange(option[0].value)
                        }
                    }}
                    inputProps={{
                        readOnly: true,
                        className:
                            ' ' +
                            (!props.disabled ? 'readonly-dropdown' : '') +
                            (props.classes ?? ''),
                        disabled: props.disabled ? true : false,
                    }}
                />
            )}
        </div>
    )
}

import React from 'react'
import { MessageThread as MessageThreadModel } from '../../../../services/models/MessageThread.model'
import { useMessagesContext } from '../../hooks/useMessagesContext'
import './Messages.scss'
import { MessageThread } from './MessageThread'

export function Messages() {
    const {
        messageThreads,
        isLoading,
        hasLoadedAll,
        updateMessageThread,
    } = useMessagesContext().messageThreads

    return (
        <div
            className={
                'messages-wrapper max-content-width flex-1 ' +
                (isLoading ? ' is-loading' : '')
            }
        >
            {messageThreads?.map((messageThread: MessageThreadModel) => (
                <MessageThread
                    key={messageThread.id}
                    messageThread={messageThread}
                    onMessageThreadUpdated={updateMessageThread}
                />
            ))}

            {hasLoadedAll && !isLoading && !messageThreads?.length && (
                <div className="mt-4 text-center color-text-secondary">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            '/assets/images/no-messages.svg'
                        }
                        alt="No Messages"
                        className="empty-state-img"
                    />
                    <div className="mt-4 font-size-18">
                        You don't have any messages
                    </div>
                </div>
            )}
        </div>
    )
}

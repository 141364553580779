import { useCallback, useState } from 'react'
import { scheduleService } from '../../../services/http/schedule.service'
import { useFamilyMember } from '../../hooks/useFamilyMember'
import { BookedAppointment } from '../models/BookedAppointment.model'

export const useAppointment = () => {
    const { approvedMemberProviderIDs } = useFamilyMember()
    const [appointments, setAppointments] = useState<BookedAppointment[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasLoadedAll, setHasLoadedAll] = useState<boolean>(false)

    const getAppointments = useCallback(() => {
        if (!approvedMemberProviderIDs?.length) {
            setAppointments([])
            setHasLoadedAll(true)
            return
        }

        setIsLoading(true)
        scheduleService
            .getAppointments(approvedMemberProviderIDs)
            .then((_appointments: BookedAppointment[]) => {
                setAppointments(filterFutureAppointments(_appointments))
            })
            .finally(() => {
                setIsLoading(false)
                setHasLoadedAll(true)
            })
    }, [approvedMemberProviderIDs])

    const onAppointmentBooked = useCallback(
        (appointment: BookedAppointment) => {
            const _appointments = [...appointments, appointment]
            _appointments.sort((a: BookedAppointment, b: BookedAppointment) => {
                const dateA = a.startAt.getTime()
                const dateB = b.startAt.getTime()
                if (dateA > dateB) {
                    return 1
                } else if (dateA < dateB) {
                    return -1
                } else {
                    return 0
                }
            })
            setAppointments(_appointments)
        },
        [appointments]
    )

    const onAppointmentCancelled = (appointment: BookedAppointment) => {
        setAppointments((_appointments) => {
            return _appointments.filter(
                (i: BookedAppointment) =>
                    i.appointmentID !== appointment.appointmentID
            )
        })
    }

    return {
        appointments,
        isLoading,
        hasLoadedAll,
        getAppointments,
        onAppointmentBooked,
        onAppointmentCancelled,
    }
}

const filterFutureAppointments = (
    appointments: BookedAppointment[]
): BookedAppointment[] => {
    if (!appointments?.length) {
        return []
    }

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const _appointments =
        appointments.filter(
            (appointment: BookedAppointment) =>
                appointment.startAt?.getTime() > today.getTime()
        ) || []

    return _appointments
}

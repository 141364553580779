import React, { useEffect, useState, useCallback } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'

interface Props {
    classes?: string
    initialValue: string
    onChange: (...event: any[]) => void
}

interface GenderOption {
    value: string
    display: string
}

export function GenderField(props: Props) {
    const [genderOptions, setGenderOptions] = useState<GenderOption[]>([])

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/assets/data/genders.json`)
            .then((response: Response) => response.json())
            .then((data: any[]) => {
                if (data) {
                    setGenderOptions(data)
                }
            })
    }, [])

    const getDefaultGender = useCallback(
        (value: string): GenderOption[] | undefined => {
            if (!value?.length) {
                return undefined
            }

            const option = genderOptions.find(
                (iterator: GenderOption) => iterator.value === value
            )
            if (option) {
                return [option]
            }
            return undefined
        },
        [genderOptions]
    )

    return (
        <div>
            {genderOptions?.length > 0 && (
                <Typeahead
                    id="gender-dropdown"
                    placeholder="Select Gender"
                    options={genderOptions}
                    defaultSelected={getDefaultGender(props.initialValue)}
                    filterBy={() => true}
                    labelKey={(option: GenderOption) => option.display}
                    onChange={(option: GenderOption[]) => {
                        if (option?.length) {
                            props.onChange(option[0].value)
                        }
                    }}
                    inputProps={{
                        readOnly: true,
                        className: 'readonly-dropdown ' + (props.classes ?? ''),
                    }}
                />
            )}
        </div>
    )
}

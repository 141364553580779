import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../App'
import useHttp from '../../../services/hooks/useHttp/useHttp'
import {
    AUTHREGEX_EMAIL,
    authService,
} from '../../../services/http/auth.service'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'

enum REQUEST {
    SIGNIN,
}

interface FormObj {
    email: string
    password: string
}

export function SignIn() {
    const { register, handleSubmit, errors, setError, clearErrors } = useForm()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        httpLoading,
        httpResponseData,
        httpErrorData,
        httpRequestIdentifier,
        httpSendRequest,
    } = useHttp()

    useEffect(() => {
        if (!httpLoading) {
            if (httpRequestIdentifier === REQUEST.SIGNIN) {
                // On successful signin, navigate to home page
                if (httpResponseData) {
                    window.location.href =
                        process.env.REACT_APP_BASE_HREF + ROUTES.HOME ?? '/'
                } else if (httpErrorData) {
                    // On unsuccessful signin, set error
                    setError('form', {
                        type: 'manual',
                        message: 'Invalid Email or Password',
                    })
                }
            }
        }
        setIsLoading(httpLoading)
    }, [
        httpLoading,
        httpResponseData,
        httpRequestIdentifier,
        httpErrorData,
        setError,
    ])

    const onSignIn = (form: FormObj) => {
        httpSendRequest(
            authService.signin.bind({}, form.email, form.password),
            REQUEST.SIGNIN
        )
    }

    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <AuthCard
                title="Sign In"
                subtitle="Enter your credentials below"
                children={
                    <React.Fragment>
                        <Form onSubmit={handleSubmit(onSignIn)}>
                            <Form.Row>
                                <Col md={12}>
                                    <Form.Label>Email</Form.Label>
                                    <input
                                        className={
                                            'form-control' +
                                            (errors?.email?.message ||
                                            errors?.form?.message
                                                ? ' invalid-state'
                                                : '')
                                        }
                                        name="email"
                                        placeholder="Email"
                                        tabIndex={1}
                                        autoCapitalize={'off'}
                                        ref={register({
                                            required: 'Email is required',
                                            pattern: {
                                                value: AUTHREGEX_EMAIL,
                                                message:
                                                    'Invalid Email Address',
                                            },
                                        })}
                                        onChange={() => {
                                            clearErrors('form')
                                        }}
                                    />

                                    {errors?.email?.message && (
                                        <div className="mt-2 color-red">
                                            {errors.email.message}
                                        </div>
                                    )}
                                </Col>
                            </Form.Row>

                            <Form.Row className="mt-4">
                                <Col md={12}>
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <Form.Label>Password</Form.Label>
                                        <div className="color-blue">
                                            <Link
                                                to={
                                                    process.env
                                                        .REACT_APP_BASE_HREF +
                                                    ROUTES.AUTH +
                                                    ROUTES.FORGOT_PASSWORD
                                                }
                                            >
                                                Forgot your Password?
                                            </Link>
                                        </div>
                                    </div>

                                    <input
                                        className={
                                            'form-control' +
                                            (errors?.password?.message ||
                                            errors?.form?.message
                                                ? ' invalid-state'
                                                : '')
                                        }
                                        name="password"
                                        type="password"
                                        tabIndex={2}
                                        placeholder="Password"
                                        ref={register({
                                            required: 'Password is required',
                                        })}
                                        onChange={() => {
                                            clearErrors('form')
                                        }}
                                    />

                                    {errors?.password?.message && (
                                        <div className="mt-2 color-red">
                                            {errors.password.message}
                                        </div>
                                    )}
                                </Col>
                            </Form.Row>

                            {errors?.form?.message && (
                                <Form.Row className="mt-4">
                                    <Col md={12}>
                                        <div className="color-red">
                                            {errors.form.message}
                                        </div>
                                    </Col>
                                </Form.Row>
                            )}

                            <Form.Row className="mt-4">
                                <Col md={12}>
                                    <input
                                        className={
                                            'primary' +
                                            (isLoading ? ' is-loading' : '')
                                        }
                                        type="submit"
                                        value="Sign In"
                                    />
                                </Col>
                            </Form.Row>

                            <Form.Row className="mt-4">
                                <Col
                                    md={12}
                                    className="text-center uppercase color-blue"
                                >
                                    <Link
                                        to={
                                            process.env.REACT_APP_BASE_HREF +
                                            ROUTES.AUTH +
                                            ROUTES.SIGNUP
                                        }
                                    >
                                        Sign Up
                                    </Link>
                                </Col>
                            </Form.Row>
                        </Form>
                    </React.Fragment>
                }
            />
        </div>
    )
}

import React, { useEffect } from 'react'
import { authService } from '../services/http/auth.service'
import { providerIndexService } from '../services/http/providerindex.service'
import { Client } from '../services/models/Client.model'
import { FamilyMember, MemberClinic } from '../services/models/Patient.model'
import { Provider } from '../services/models/Provider.model'
import {
    ClientProvidersLoaded,
    ClientsLoaded,
    FamilyMemberIDSelected,
    FamilyMembersLoaded,
    PatientLoaded,
} from '../services/store/actions'
import { AppBar } from '../UI/AppBar/AppBar'
import { NavigationBar } from '../UI/NavigationBar/NavigationBar'
import { TopBar } from '../UI/TopBar/TopBar'
import { Account } from './account/components/Account/Account'
import { Appointments } from './appointments/Appointments'
import { AppointmentsContextProvider } from './appointments/hooks/useAppointmentsContext'
import { PAGE, usePage } from './hooks/usePage'
import { Messages } from './messages/components/Messages/Messages'
import { Modals } from './Modals'

export function Main() {
    const { currentPage } = usePage()

    useEffect(() => {
        // Fetch and store patient in redux store
        const patient = authService.getPatient()
        if (patient) {
            PatientLoaded.dispatch(patient)
        }

        // Store family memebers in redux store
        if (patient?.familyMembers?.length) {
            FamilyMembersLoaded.dispatch(patient.familyMembers)
            FamilyMemberIDSelected.dispatch(patient.familyMembers[0].id)
        }

        // Get providers at each associated member clinic
        if (patient?.familyMembers?.length) {
            // Fetch and store providers from each associated client to redux store
            const clientNames: string[] = patient.familyMembers
                .reduce((array: string[], familyMember: FamilyMember) => {
                    array = [
                        ...array,
                        ...familyMember.clinics.map(
                            (i: MemberClinic) => i.clientName
                        ),
                    ]
                    return array
                }, [])
                ?.filter(
                    (clientName: string, index: number, array: string[]) =>
                        array.indexOf(clientName) === index
                )

            // Fetch and store clients
            clientNames?.forEach((clientName: string) => {
                providerIndexService
                    .getProviders(clientName)
                    .then((providers: Provider[]) => {
                        ClientProvidersLoaded.dispatch(clientName, providers)
                    })
                    .catch(() => {})
            })
        }

        // Fetch and store clients
        providerIndexService.getClients().then((clients: Client[]) => {
            if (clients?.length) {
                ClientsLoaded.dispatch(clients)
            }
        })
    }, [])

    return (
        <React.Fragment>
            <div className="h-100 d-flex flex-column">
                <TopBar />
                <AppBar />
                {currentPage === PAGE.APPOINTMENTS && (
                    <AppointmentsContextProvider>
                        <Appointments />
                    </AppointmentsContextProvider>
                )}
                {currentPage === PAGE.MESSAGES && <Messages />}
                {currentPage === PAGE.ACCOUNT && <Account />}
                <NavigationBar />
            </div>

            <Modals />
        </React.Fragment>
    )
}

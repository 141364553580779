import React from 'react'
import { POCKET_PILLS_HOMEPAGE } from '../../main/constants'
import './PocketPillsLogo.scss'

export function PocketPillsLogo() {
    return (
        <img
            className="pocketpills-logo"
            src={
                process.env.PUBLIC_URL +
                '/assets/images/pocketpills-logo-wordmark.png'
            }
            alt="PocketPills"
            onClick={() => window.open(POCKET_PILLS_HOMEPAGE, '_blank')}
        />
    )
}

import React, { useMemo } from 'react'
import Moment from 'react-moment'
import styled from 'styled-components'
import Colors from '../../../../colors'
import { MemberProvider } from '../../../../services/models/Patient.model'
import { Provider } from '../../../../services/models/Provider.model'
import { MOMENT_DATETIME_FORMAT } from '../../../constants'
import { useProviderIndex } from '../../../hooks/useProviderIndex'

const Styles = styled.div`
    padding: 16px 24px;
    margin: 0 8px 8px 0;
    border-left: 2px solid ${() => Colors.BLUE};
    border-radius: 2px;
    background: ${() => Colors.BLUE}0F;
    display: inline-block;
    color: ${() => Colors.TEXT_SECONDARY};
    font-weight: 500;
`

const TitleStyles = styled.div`
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    color: ${() => Colors.BLUE};

    .material-icons {
        color: ${() => Colors.BLUE};
    }
`

export const AwaitingApproval = ({
    memberProvider,
}: {
    memberProvider: MemberProvider
}) => {
    const { getProviderByClientName } = useProviderIndex()
    const provider = useMemo(
        (): Provider | null =>
            getProviderByClientName(
                memberProvider.providerID,
                memberProvider.clientName
            ),
        [
            memberProvider.providerID,
            memberProvider.clientName,
            getProviderByClientName,
        ]
    )

    if (!provider || !MemberProvider.isRequestPending(memberProvider)) {
        return null
    }

    return (
        <Styles>
            <TitleStyles>
                <i className="material-icons md-18 mr-2">info</i>
                <div>Awaiting Approval</div>
            </TitleStyles>
            {memberProvider.createdAt !== null ? (
                <div>
                    Sent request to Provider{' '}
                    <span className="font-weight-600">
                        {provider.fullName!}
                    </span>{' '}
                    for booking appointments on{' '}
                    <Moment format={MOMENT_DATETIME_FORMAT}>
                        {memberProvider.createdAt}
                    </Moment>
                </div>
            ) : (
                <div>
                    Awaiting approval from{' '}
                    <span className="font-weight-600">
                        {provider.fullName!}
                    </span>{' '}
                    for booking appointments
                </div>
            )}
        </Styles>
    )
}

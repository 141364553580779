import React, { ReactNode } from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import './ConfirmationPopover.scss'

interface Props {
    message: string
    confirmLabel?: string
    cancelLabel?: string
    position?:
        | 'auto'
        | 'left'
        | 'right'
        | 'top'
        | 'bottom'
        | 'auto-start'
        | 'auto-end'
        | 'top-start'
        | 'top-end'
        | 'bottom-start'
        | 'bottom-end'
        | 'right-start'
        | 'right-end'
        | 'left-start'
        | 'left-end'
        | undefined
    onCancel: () => void
    children: ReactNode
}

export function ConfirmationPopover(props: Props) {
    const popover = (
        <Popover
            id="confirmation-popover"
            className="confirmation-popper-wrapper"
        >
            <Popover.Content className="color-red">
                <div>{props.message}</div>
                <div className="d-flex justify-content-end mt-3">
                    <div
                        className="div-button font-size-12 color-text-hint"
                        onClick={() => document.body.click()}
                    >
                        {props.cancelLabel ?? 'Cancel'}
                    </div>
                    <div
                        className="div-button font-size-12 color-red ml-3"
                        onClick={() => {
                            props.onCancel()
                            document.body.click()
                        }}
                    >
                        {props.confirmLabel ?? 'Confirm'}
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger
            trigger="click"
            placement={props.position ?? 'top'}
            overlay={popover}
            rootClose
        >
            <div className="d-inline-flex align-items-center">
                {props.children}
            </div>
        </OverlayTrigger>
    )
}

export class Patient {
    id!: number
    email!: string
    firstName!: string
    lastName!: string
    gender!: string
    birthday!: string
    healthCardNumber?: number
    healthCardVersion?: string
    healthCardProvince?: string
    familyMembers!: FamilyMember[]
    settings!: {
        pocketPillsEnabled: boolean
    }

    // Custom properties
    fullName?: string
}

export function deserializePatient(obj: any): Patient {
    const model: Patient = Object.assign({}, obj)
    model.fullName = [obj.firstName, obj.lastName].join(' ')
    model.familyMembers =
        obj.familyMembers?.map((_obj: any) => deserializeFamilyMember(_obj)) ||
        []
    return model
}

export class FamilyMember {
    id!: number
    patientID!: number
    patientClinicInformationID!: number
    firstName!: string
    lastName!: string
    gender!: string
    birthday!: string
    healthCardNumber?: number | null
    healthCardVersion?: string
    healthCardProvince?: string
    createdAt!: Date
    clinics!: MemberClinic[]
    providers!: MemberProvider[]
}

export function deserializeFamilyMember(obj: any): FamilyMember {
    const model: FamilyMember = Object.assign({}, obj)
    if (obj.createdAt) {
        model.createdAt = new Date(obj.createdAt)
    }
    model.clinics =
        obj.clinics?.map((_obj: any) => deserializeMemberClinic(_obj)) || []
    model.providers =
        obj.providers?.map((_obj: any) => deserializeMemberProvider(_obj)) || []
    return model
}

export class MemberClinic {
    id!: number
    memberID!: number
    demographicID!: number
    clientName!: string
    isConfirmed!: boolean | null
    confirmationSecret!: string
    createdAt!: Date
}

export function deserializeMemberClinic(obj: any): MemberClinic {
    const model: MemberClinic = Object.assign({}, obj)
    if (obj.createdAt) {
        model.createdAt = new Date(obj.createdAt)
    }
    return model
}

export class MemberProvider {
    id!: number
    memberID!: number
    demographicID!: number
    providerID!: number
    clientName!: string
    isConfirmed!: boolean | null
    confirmationSecret!: string
    createdAt!: Date

    static isRequestApproved = (
        memberProvider: MemberProvider | null
    ): boolean => memberProvider?.isConfirmed === true

    static isRequestPending = (
        memberProvider: MemberProvider | null
    ): boolean =>
        !!(
            memberProvider &&
            (memberProvider?.isConfirmed === null ||
                memberProvider?.isConfirmed === undefined)
        )

    static canSendRequest = (
        isOnlineBookingEnabled: boolean,
        isRequestApproved: boolean,
        isRequestPending: boolean
    ): boolean =>
        isOnlineBookingEnabled && !isRequestApproved && !isRequestPending
}

export function deserializeMemberProvider(obj: any): MemberProvider {
    const model: MemberProvider = Object.assign({}, obj)
    if (obj.createdAt) {
        model.createdAt = new Date(obj.createdAt)
    }
    return model
}

import { PAGE } from '../../main/hooks/usePage'
import { Client } from '../models/Client.model'
import { FamilyMember, Patient } from '../models/Patient.model'
import { Provider } from '../models/Provider.model'
import {
    ClientProvidersLoaded,
    ClientsLoaded,
    FamilyMemberAdded,
    FamilyMemberIDSelected,
    FamilyMembersLoaded,
    FamilyMemberUpdated,
    PageNavigated,
    PatientLoaded,
    PatientUpdated,
} from './actions'
import { initalState, State } from './state'

export const reducer = (
    state: State = initalState,
    action: {
        type: string
        payload: any
    }
) => {
    // Process payload and return next state
    const payload = action.payload
    switch (action.type) {
        case PatientLoaded.type:
            return processPatientLoaded(state, payload)

        case PatientUpdated.type:
            return processPatientUpdated(state, payload)

        case ClientsLoaded.type:
            return processClientsLoaded(state, payload)

        case ClientProvidersLoaded.type: {
            return processClientProvidersLoaded(
                state,
                payload.clientName,
                payload.providers
            )
        }

        case FamilyMemberIDSelected.type: {
            return processFamilyMemberIDSelected(state, payload)
        }

        case FamilyMembersLoaded.type: {
            return processFamilyMembersLoaded(state, payload)
        }

        case FamilyMemberAdded.type: {
            return processFamilyMemberAdded(state, payload)
        }

        case FamilyMemberUpdated.type: {
            return processFamilyMemberUpdated(state, payload)
        }

        case PageNavigated.type:
            return processPageNavigated(state, payload)

        default:
            break
    }

    return state
}

function processPatientLoaded(state: State, patient: Patient): State {
    return {
        ...state,
        patient,
    }
}

function processPatientUpdated(state: State, patient: Patient): State {
    return {
        ...state,
        patient,
    }
}

function processClientsLoaded(state: State, clients: Client[]): State {
    return {
        ...state,
        clients,
    }
}

const processClientProvidersLoaded = (
    state: State,
    clientName: string,
    providers: Provider[]
): State => {
    const clientProvidersMap = new Map(state.clientProvidersMap)
    clientProvidersMap.set(clientName, providers)

    return {
        ...state,
        clientProvidersMap,
    }
}

function processFamilyMemberIDSelected(
    state: State,
    familyMemberID: number | null
): State {
    return {
        ...state,
        familyMemberID,
    }
}

const processFamilyMembersLoaded = (
    state: State,
    familyMembers: FamilyMember[]
): State => {
    const familyMembersMap = new Map(state.familyMembersMap)
    familyMembers?.forEach((familyMember: FamilyMember) => {
        familyMembersMap.set(familyMember.id, familyMember)
    })

    return {
        ...state,
        familyMembersMap,
    }
}

const processFamilyMemberAdded = (
    state: State,
    familyMember: FamilyMember
): State => {
    const familyMembersMap = new Map(state.familyMembersMap)
    familyMembersMap.set(familyMember.id, familyMember)

    return {
        ...state,
        familyMembersMap,
    }
}

const processFamilyMemberUpdated = (
    state: State,
    familyMember: FamilyMember
): State => {
    const familyMembersMap = new Map(state.familyMembersMap)
    familyMembersMap.set(familyMember.id, familyMember)

    return {
        ...state,
        familyMembersMap,
    }
}

function processPageNavigated(state: State, currentPage: PAGE): State {
    return {
        ...state,
        currentPage,
    }
}

import React, { ReactNode } from 'react'
import { AppLogo } from '../AppLogo/AppLogo'
import './AuthCard.scss'

interface Props {
    title: string
    subtitle?: string
    classes?: string
    children: ReactNode
}

export function AuthCard(props: Props) {
    return (
        <div className={'auth-card-wrapper ' + (props.classes ?? '')}>
            <div className="auth-card-inner">
                <AppLogo />
                <div className="auth-card-title">{props.title}</div>
                {props.subtitle && (
                    <div className="auth-card-subtitle">{props.subtitle}</div>
                )}
                {props.children}
            </div>
        </div>
    )
}

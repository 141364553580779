// Default moment datetime format
export const MOMENT_DATETIME_FORMAT = 'MMMM Do, YYYY HH:mm A'

// File types accepted for upload
export const FILE_ACCEPTS =
    'image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'

// Maximum file size allowed for upload
export const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100MB

// Maximum number of members allowed per account
export const MAX_MEMBERS = 5

// Interval for automatically fetching messages
export const GET_MESSAGES_INTERVAL = 300000 // 5 minutes

// Message threads should be collapsed after days
export const COLLAPSE_MESSAGE_THREADS_AFTER_DAYS = 30

// PocketPills home page
export const POCKET_PILLS_HOMEPAGE = 'https://www.pocketpills.com/'

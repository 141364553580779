import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { OpenSwitchMemberModal } from '../../../services/event/eventactions'
import { FamilyMember } from '../../../services/models/Patient.model'
import { FamilyMemberIDSelected } from '../../../services/store/actions'
import { Avatar } from '../../../UI/Avatar/Avatar'
import { useFamilyMember } from '../../hooks/useFamilyMember'
import { Member } from './Member'
import './SwitchMemberModal.scss'

export interface SwitchMemberModalProps {
    show: boolean
    onClose: () => void
}

export function SwitchMemberModal(props: SwitchMemberModalProps) {
    const {
        patientName,
        familyMember: currentFamilyMember,
        familyMembers,
    } = useFamilyMember()

    const members =
        (currentFamilyMember &&
            familyMembers?.filter(
                (i: FamilyMember) => i.id !== currentFamilyMember.id
            )) ||
        []

    const onSelectMember = (familyMember: FamilyMember) => {
        FamilyMemberIDSelected.dispatch(familyMember.id)
        props.onClose()
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onClose}
            dialogClassName="switch-member-modal-wrapper modal-wrapper"
            animation={false}
            centered
        >
            <Modal.Header className="px-4 switch-member-modal-header justify-content-center">
                <div className="d-flex align-items-center font-size-initial">
                    {patientName && <Avatar name={patientName} />}
                    <div className="font-size-initial font-weight-500 ml-2">
                        {patientName}
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="switch-member-modal-body">
                    {members?.map(
                        (familyMember: FamilyMember, index: number) => (
                            <Member
                                key={index}
                                familyMember={familyMember}
                                onClick={() => onSelectMember(familyMember)}
                            />
                        )
                    )}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div
                    className="div-button color-text-secondary"
                    onClick={props.onClose}
                >
                    Close
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export const onOpenSwitchMemberModal = (onClose?: () => void) => {
    const modalProps: SwitchMemberModalProps = {
        show: true,
        onClose: () => onClose && onClose(),
    }
    OpenSwitchMemberModal.emit(modalProps)
}

import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ROUTES } from '../../../App'
import useHttp from '../../../services/hooks/useHttp/useHttp'
import { authService } from '../../../services/http/auth.service'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'

enum REQUEST {
    VERIFY_EMAIL,
}

export function VerifyEmail() {
    const { secret } = useParams<{ secret: string | undefined }>()
    const [verified, setVerified] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        httpLoading,
        httpResponseData,
        httpErrorData,
        httpRequestIdentifier,
        httpSendRequest,
    } = useHttp()

    useEffect(() => {
        if (!httpLoading) {
            if (httpRequestIdentifier === REQUEST.VERIFY_EMAIL) {
                if (httpResponseData) {
                    setVerified(true)
                } else if (httpErrorData) {
                    setVerified(false)
                }
            }
        }
        setIsLoading(httpLoading)
    }, [httpLoading, httpResponseData, httpRequestIdentifier, httpErrorData])

    useEffect(() => {
        if (secret && !verified) {
            httpSendRequest(
                authService.verifyEmail.bind({}, secret),
                REQUEST.VERIFY_EMAIL
            )
        }
        if (!secret) {
            setVerified(false)
        }
    }, [secret, verified, httpSendRequest])

    return (
        <div className="h-100 d-flex align-items-center justify-content-center">
            <AuthCard
                classes={isLoading ? 'is-loading' : ''}
                title={verified ? 'Successfully verified email!' : 'Whoops'}
                subtitle={
                    verified
                        ? 'Please sign in again'
                        : 'Unable to verify email, please try again later'
                }
                children={
                    <React.Fragment>
                        {verified && (
                            <Link
                                className="color-white"
                                to={
                                    process.env.REACT_APP_BASE_HREF +
                                    ROUTES.AUTH +
                                    ROUTES.SIGNIN
                                }
                            >
                                <button type="button" className="primary">
                                    Sign In
                                </button>
                            </Link>
                        )}
                    </React.Fragment>
                }
            />
        </div>
    )
}

import {
    AppointmentType,
    deserializeAppointmentType,
} from './AppointmentType.model'

export class Clinic {
    name!: string
    address!: string
    displayName!: string
    message!: string
    appointmentTypes!: AppointmentType[]
}

export function deserializeClinic(obj: any): Clinic {
    const model: Clinic = Object.assign({}, obj)
    if (obj?.appointmentTypes?.length) {
        model.appointmentTypes = obj.appointmentTypes.map((_obj: any) =>
            deserializeAppointmentType(_obj)
        )
    } else {
        model.appointmentTypes = []
    }
    return model
}

import React, { useCallback, useMemo, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { OpenRequestProviderModal } from '../../../services/event/eventactions'
import {
    authService,
    RequestProviderPayload,
    storePatient,
} from '../../../services/http/auth.service'
import {
    FamilyMember,
    MemberProvider,
    Patient,
} from '../../../services/models/Patient.model'
import {
    FamilyMemberUpdated,
    PatientUpdated,
} from '../../../services/store/actions'
import { onOpenToast } from '../../../UI/Toast/Toast'
import { useFamilyMember } from '../../hooks/useFamilyMember'
import { usePatient } from '../../hooks/usePatient'
import { Member } from './Member'
import './RequestProviderModal.scss'

export interface RequestProviderModalProps {
    show: boolean
    providerID: number
    providerName: string
    clientName: string
    onRequested: (memberProviders: MemberProvider[]) => void
    onClose: () => void
}

export function RequestProviderModal({
    show,
    providerID,
    providerName,
    clientName,
    onRequested,
    onClose,
}: RequestProviderModalProps) {
    const { patient } = usePatient()
    const { familyMembers: allFamilyMembers } = useFamilyMember()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Filter for family members who have not requested this provider before
    const familyMembers = useMemo(
        () =>
            allFamilyMembers.filter(
                (i: FamilyMember) =>
                    i.providers.findIndex(
                        (ii: MemberProvider) => ii.providerID === providerID
                    ) === -1 ||
                    !i.providers.some((ii: MemberProvider) =>
                        MemberProvider.isRequestApproved(ii)
                    )
            ),
        [providerID, allFamilyMembers]
    )

    const [selectedMemberIDs, setSelectedMemberIDs] = useState<number[]>(
        familyMembers?.map((i: FamilyMember) => i.id) || []
    )

    const onSend = useCallback(() => {
        setIsLoading(true)

        const promises: Promise<MemberProvider>[] = []

        selectedMemberIDs.forEach((familyMemberID: number) => {
            const payload: RequestProviderPayload = {
                providerID,
                clientName,
                familyMemberID,
            }
            promises.push(authService.requestProvider(payload))
        })

        Promise.all(promises)
            .then((memberProviders: MemberProvider[]) => {
                // Update localStorage and redux store
                if (memberProviders.length > 0 && patient) {
                    updatePatientWithMemberProviders(patient, memberProviders)
                }
                setIsLoading(false)
                onRequested(memberProviders)
                onClose()
            })
            .catch(() => {
                onOpenToast('Unable to make request', 'error')
                setIsLoading(false)
            })
    }, [
        onRequested,
        onClose,
        patient,
        providerID,
        clientName,
        selectedMemberIDs,
    ])

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="request-provider-modal-wrapper modal-wrapper"
            animation={false}
            centered
        >
            <Modal.Header className="px-4 request-provider-modal-header justify-content-center">
                <span className="w-75 text-center">
                    Sending Online Booking request to Provider{' '}
                    <span className="font-weight-500">{providerName}</span>
                </span>
            </Modal.Header>

            <Modal.Body>
                <div className="request-provider-modal-body">
                    <div className="color-text-secondary mb-2">
                        Select Members
                    </div>
                    <div>
                        {familyMembers?.map(
                            (familyMember: FamilyMember, index: number) => (
                                <Member
                                    key={index}
                                    familyMember={familyMember}
                                    isSelected={selectedMemberIDs.some(
                                        (id: number) => id === familyMember.id
                                    )}
                                    onToggle={(isSelected: boolean) => {
                                        if (isSelected) {
                                            setSelectedMemberIDs((ids) => [
                                                ...ids,
                                                familyMember.id,
                                            ])
                                        } else {
                                            setSelectedMemberIDs((ids) =>
                                                ids.filter(
                                                    (i: number) =>
                                                        i !== familyMember.id
                                                )
                                            )
                                        }
                                    }}
                                />
                            )
                        )}
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                {!isLoading ? (
                    <>
                        {selectedMemberIDs?.length > 0 && (
                            <div
                                className="div-button color-blue mr-3"
                                onClick={onSend}
                            >
                                Send Request
                            </div>
                        )}
                        <div
                            className="div-button color-text-secondary"
                            onClick={onClose}
                        >
                            Close
                        </div>
                    </>
                ) : (
                    <div className="p-3 is-loading"></div>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export const onOpenRequestProviderModal = (
    providerID: number,
    providerName: string,
    clientName: string,
    onRequested: (memberProviders: MemberProvider[]) => void,
    onClose?: () => void
) => {
    const modalProps: RequestProviderModalProps = {
        show: true,
        providerID,
        providerName,
        clientName,
        onRequested,
        onClose: () => onClose && onClose(),
    }
    OpenRequestProviderModal.emit(modalProps)
}

const updatePatientWithMemberProviders = (
    patient: Patient,
    memberProviders: MemberProvider[]
) => {
    const _patient: Patient = {
        ...patient,
    }

    // Group memberProviders by family member ID
    const map = new Map<number, MemberProvider[]>()
    memberProviders.forEach((memberProvider: MemberProvider) => {
        const _memberProviders = [
            ...(map.get(memberProvider.memberID) || []),
            memberProvider,
        ]
        map.set(memberProvider.memberID, _memberProviders)
    })

    map.forEach(
        (_memberProviders: MemberProvider[], familyMemberID: number) => {
            _patient.familyMembers =
                _patient.familyMembers?.map((familyMember: FamilyMember) => {
                    if (familyMember.id === familyMemberID) {
                        return {
                            ...familyMember,
                            providers: [
                                ...familyMember.providers,
                                ..._memberProviders,
                            ],
                        }
                    } else {
                        return familyMember
                    }
                }) || []
        }
    )

    // Update familyMember in redux store
    _patient.familyMembers?.forEach((familyMember: FamilyMember) => {
        FamilyMemberUpdated.dispatch(familyMember)
    })

    // Update patient in redux store
    PatientUpdated.dispatch(_patient)

    // Update patient object in localStorage
    storePatient(_patient)
}

import { useCallback, useMemo, useState } from 'react'
import { patientmessagingService } from '../../../services/http/patientmessaging.service'
import { Message } from '../../../services/models/Message.model'
import { MessageThread } from '../../../services/models/MessageThread.model'

export const useMessageThreads = () => {
    const [messageThreads, setMessageThreads] = useState<MessageThread[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [hasLoadedAll, setHasLoadedAll] = useState<boolean>(false)

    const getMessageThreads = (memberClinicIDs: number[]) => {
        if (!memberClinicIDs?.length) {
            setMessageThreads([])
            return
        }

        setIsLoading(true)
        patientmessagingService
            .getMessages(memberClinicIDs)
            .then((_messageThreads: MessageThread[]) => {
                setMessageThreads(_messageThreads)
            })
            .catch(() => {
                setMessageThreads([])
            })
            .finally(() => {
                setIsLoading(false)
                setHasLoadedAll(true)
            })
    }

    const updateMessageThread = (messageThread: MessageThread) => {
        let _messageThreads = [...messageThreads]
        _messageThreads = _messageThreads.map(
            (_messageThread: MessageThread) => {
                return _messageThread.id === messageThread.id
                    ? messageThread
                    : _messageThread
            }
        )
        setMessageThreads(_messageThreads)
    }

    const isMessageThreadNotRead = useCallback(
        (messageThread: MessageThread): boolean =>
            messageThread.messages.some((ii: Message) => Message.isNotRead(ii)),
        []
    )

    const unreadCount = useMemo(
        (): number =>
            messageThreads.reduce((count: number, i: MessageThread) => {
                if (isMessageThreadNotRead(i)) {
                    count++
                }
                return count
            }, 0),
        [messageThreads, isMessageThreadNotRead]
    )

    return {
        messageThreads,
        unreadCount,
        isLoading,
        hasLoadedAll,
        getMessageThreads,
        updateMessageThread,
        isMessageThreadNotRead,
    }
}

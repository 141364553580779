import React, { useEffect, useState } from 'react'
import './Toggle.scss'

interface Props {
    active: boolean | undefined
    activeLabel?: string
    inactiveLabel?: string
    onClick: () => void
}

export function Toggle(props: Props) {
    const [active, setActive] = useState<boolean>(
        props.active !== undefined ? props.active : false
    )

    useEffect(() => {
        if (props.active !== undefined) {
            setActive(props.active)
        }
    }, [props.active])

    const onClick = () => {
        props.onClick()
    }

    return (
        <React.Fragment>
            <div
                className={
                    'toggle-wrapper' + (active ? ' active' : ' inactive')
                }
                onClick={onClick}
            >
                <div className="toggle-inner"></div>
                <div className="toggle-knob"></div>
            </div>

            {active && props.activeLabel && (
                <div className="ml-3">{props.activeLabel}</div>
            )}
            {!active && props.inactiveLabel && (
                <div className="ml-3">{props.inactiveLabel}</div>
            )}
        </React.Fragment>
    )
}

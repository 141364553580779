export const utilsService = {
    capitalize: (value: string): string => capitalize(value),
    downloadFromUrl: (url: string, filename?: string) =>
        downloadFromUrl(url, filename),
    urlify: (text: string) => urlify(text),
}

const capitalize = (value: string): string => {
    if (!value?.length) {
        return ''
    }
    return value.charAt(0).toUpperCase() + value.slice(1)
}

const downloadFromUrl = (url: string, filename?: string) => {
    const anchorEl = document.createElement('a')
    anchorEl.href = url
    if (!isMobile.any()) {
        anchorEl.target = '_blank'
    }
    anchorEl.download = filename || 'download'
    document.body.appendChild(anchorEl)
    anchorEl.click()

    const clickHandler = () => {
        setTimeout(() => {
            window.URL.revokeObjectURL(url)
            document.body.removeChild(anchorEl)
        }, 100)
    }

    anchorEl.addEventListener('click', clickHandler, false)
}

const urlify = (text: string) => {
    if (!text?.length) {
        return ''
    }

    const regexUrl = new RegExp(
        // eslint-disable-next-line
        /([(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))/gi
    )
    const regexEmail = new RegExp(
        // eslint-disable-next-line
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    )

    const matches = text.match(regexUrl)

    if (matches?.length) {
        Array.from(matches).forEach((match: string) => {
            let url = match
            const isEmail = !!url.match(regexEmail)?.length

            if (
                !isEmail &&
                !url.startsWith('https://') &&
                !url.startsWith('http://')
            ) {
                url = 'https://' + url
            }

            const href = !isEmail ? url : `mailto:${url}`

            text = text.replace(
                match,
                `&nbsp;<a target="_blank" href="${href}">${match}</a>`
            )
        })
    }

    return text
}

const isMobile = {
    Android() {
        return navigator.userAgent.match(/Android/i)
    },
    BlackBerry() {
        return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera() {
        return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows() {
        return (
            navigator.userAgent.match(/IEMobile/i) ||
            navigator.userAgent.match(/WPDesktop/i)
        )
    },
    any() {
        return (
            isMobile.Android() ||
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows()
        )
    },
}

import React, { useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import {
    OpenAddMembersModal,
    OpenMemberDetailsModal,
    OpenRequestProviderModal,
    OpenSwitchMemberModal,
    OpenToast,
} from '../services/event/eventactions'
import { eventBus, EventBusData } from '../services/event/eventbus.service'
import { ToastProps, ToastWrapper } from '../UI/Toast/Toast'
import {
    AddMembersModal,
    AddMembersModalProps,
} from './extra/add-members/AddMembersModal'
import {
    MemberDetailsModal,
    MemberDetailsModalProps,
} from './extra/member-details/MemberDetailsModal'
import {
    RequestProviderModal,
    RequestProviderModalProps,
} from './extra/request-provider/RequestProviderModal'
import {
    SwitchMemberModal,
    SwitchMemberModalProps,
} from './extra/switch-member/SwitchMemberModal'

export function Modals() {
    const [toastProps, setToastProps] = useState<ToastProps>({ toasts: [] })
    const [
        switchMemberModalProps,
        setSwitchMemberModalProps,
    ] = useState<SwitchMemberModalProps | null>(null)
    const [
        addMembersModalProps,
        setAddMembersModalProps,
    ] = useState<AddMembersModalProps | null>(null)
    const [
        memberDetailsModalProps,
        setMemberDetailsModalProps,
    ] = useState<MemberDetailsModalProps | null>(null)
    const [
        requestProviderModalProps,
        setRequestProviderModalProps,
    ] = useState<RequestProviderModalProps | null>(null)

    useEffect(() => {
        const subscription: Subscription = eventBus
            .getObservable()
            .subscribe((event: EventBusData) => {
                switch (event.action) {
                    case OpenToast.type: {
                        const _toastProps: ToastProps = {
                            toasts: [event.payload],
                        }
                        setToastProps(_toastProps)
                        break
                    }

                    case OpenSwitchMemberModal.type: {
                        const modalProps: SwitchMemberModalProps = {
                            ...event.payload,
                            show: true,
                            onClose: () => {
                                event.payload.onClose()
                                setSwitchMemberModalProps(null)
                            },
                        }
                        setSwitchMemberModalProps(modalProps)
                        break
                    }

                    case OpenAddMembersModal.type: {
                        const modalProps: AddMembersModalProps = {
                            ...event.payload,
                            show: true,
                            onClose: () => {
                                event.payload.onClose()
                                setAddMembersModalProps(null)
                            },
                        }
                        setAddMembersModalProps(modalProps)
                        break
                    }

                    case OpenMemberDetailsModal.type: {
                        const modalProps: MemberDetailsModalProps = {
                            ...event.payload,
                            show: true,
                            onClose: () => {
                                event.payload.onClose()
                                setMemberDetailsModalProps(null)
                            },
                        }
                        setMemberDetailsModalProps(modalProps)
                        break
                    }

                    case OpenRequestProviderModal.type: {
                        const modalProps: RequestProviderModalProps = {
                            ...event.payload,
                            show: true,
                            onClose: () => {
                                event.payload.onClose()
                                setRequestProviderModalProps(null)
                            },
                        }
                        setRequestProviderModalProps(modalProps)
                        break
                    }
                }
            })

        return () => {
            subscription.unsubscribe()
        }
    }, [])

    return (
        <React.Fragment>
            <ToastWrapper {...toastProps} />
            {switchMemberModalProps && (
                <SwitchMemberModal {...switchMemberModalProps} />
            )}
            {addMembersModalProps && (
                <AddMembersModal {...addMembersModalProps} />
            )}
            {memberDetailsModalProps && (
                <MemberDetailsModal {...memberDetailsModalProps} />
            )}
            {requestProviderModalProps && (
                <RequestProviderModal {...requestProviderModalProps} />
            )}
        </React.Fragment>
    )
}

import { deserializeMessage, Message } from './Message.model'

export class MessageThread {
    id!: number
    assignMessage!: string
    assignedProvider!: number
    clientName!: string
    closeMessage?: string
    closedAt?: Date
    createdAt!: Date
    creatingProvider!: number
    demographicId!: number
    messages!: Message[]
}

export function deserializeMessageThread(obj: any): MessageThread {
    const model: MessageThread = new MessageThread()
    model.id = obj.ID
    model.assignMessage = obj.AssignMessage
    model.assignedProvider = obj.AssignedProvider
    model.clientName = obj.ClientName
    model.closeMessage = obj.CloseMessage
    model.closedAt = obj.ClosedAt
    if (obj.CreatedAt) {
        model.createdAt = new Date(obj.CreatedAt)
    }
    model.creatingProvider = obj.CreatingProvider
    model.demographicId = obj.DemographicID
    model.messages = obj.Messages.map((_obj: any) => {
        return deserializeMessage(_obj)
    })

    // Sort by ascending order
    if (model.messages?.length > 0) {
        model.messages.sort((a: Message, b: Message) => {
            const aDate = a.sentAt
            const bDate = b.sentAt
            return bDate.getTime() - aDate.getTime()
        })
    }

    return model
}

import React, { useState } from 'react'
import { patientmessagingService } from '../../../../services/http/patientmessaging.service'
import { utilsService } from '../../../../services/utils.service'
import { IconButton } from '../../../../UI/IconButton/IconButton'
import { IconLabel } from '../../../../UI/IconLabel/IconLabel'

interface Props {
    filename: string
    demographicID?: number
    clientName?: string
    attachmentID?: number
    canDelete?: boolean
    onDelete?: () => void
}

export function FileAttachment(props: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onClick = () => {
        if (props.clientName && props.attachmentID && props.demographicID) {
            setIsLoading(true)
            patientmessagingService
                .getDownloadLink(
                    props.clientName,
                    props.attachmentID,
                    props.demographicID
                )
                .then((downloadURL: string) => {
                    utilsService.downloadFromUrl(downloadURL, props.filename)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    return (
        <div
            className={
                'file-attachment-wrapper d-flex align-items-center ' +
                (isLoading ? ' is-loading' : '')
            }
            onClick={onClick}
        >
            <div className="file-attachment-inner">
                <IconLabel
                    label={props.filename}
                    materialIcon="attachment"
                    color="color-blue"
                    classes="text-transform-none word-break-break-all"
                />
            </div>
            {props.canDelete && (
                <IconButton
                    materialIcon="close"
                    classes="color-text-secondary ml-2 mr-3"
                    onClick={props.onDelete}
                />
            )}
        </div>
    )
}

import { ErrorMessage } from '@hookform/error-message'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../App'
import useHttp from '../../../services/hooks/useHttp/useHttp'
import {
    AUTHREGEX_EMAIL,
    authService,
} from '../../../services/http/auth.service'
import { AuthCard } from '../../../UI/AuthCard/AuthCard'

enum REQUEST {
    FORGOT_PASSWORD,
}

enum FORM_STATE {
    LOADED,
    FORM_SUCCESS,
    FORM_ERROR,
}

interface FormObj {
    email: string
}

export function ForgotPassword() {
    const { register, handleSubmit, errors } = useForm()
    const [formState, setFormState] = useState<FORM_STATE>(FORM_STATE.LOADED)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {
        httpLoading,
        httpResponseData,
        httpErrorData,
        httpRequestIdentifier,
        httpSendRequest,
    } = useHttp()

    useEffect(() => {
        if (!httpLoading) {
            if (httpRequestIdentifier === REQUEST.FORGOT_PASSWORD) {
                if (httpResponseData) {
                    setFormState(FORM_STATE.FORM_SUCCESS)
                } else if (httpErrorData) {
                    setFormState(FORM_STATE.FORM_ERROR)
                }
            }
        }
        setIsLoading(httpLoading)
    }, [httpLoading, httpResponseData, httpRequestIdentifier, httpErrorData])

    const onRender = useCallback(() => {
        let title
        let subtitle
        switch (formState) {
            case FORM_STATE.FORM_SUCCESS:
                title = 'Reset Password Email Sent'
                subtitle = 'Please check your email to reset your password'
                break

            case FORM_STATE.FORM_ERROR:
                title = 'Whoops'
                subtitle =
                    'Either the email does not exist in our system, or an unexpected error has occurred'
                break

            case FORM_STATE.LOADED:
            default:
                title = 'Forgot Password'
                subtitle = 'Enter your email below'
                break
        }

        const onSubmit = (data: FormObj) => {
            httpSendRequest(
                authService.forgotPassword.bind({}, data.email),
                REQUEST.FORGOT_PASSWORD
            )
        }

        return (
            <div className="h-100 d-flex align-items-center justify-content-center">
                <AuthCard
                    title={title}
                    subtitle={subtitle}
                    children={
                        <React.Fragment>
                            {formState === FORM_STATE.LOADED && (
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Row>
                                        <Col md={12}>
                                            <Form.Label>Email</Form.Label>
                                            <input
                                                className={
                                                    'form-control' +
                                                    (errors?.email?.message
                                                        ? ' invalid-state'
                                                        : '')
                                                }
                                                name="email"
                                                placeholder="Email"
                                                tabIndex={1}
                                                autoCapitalize={'off'}
                                                ref={register({
                                                    required:
                                                        'Email is required',
                                                    pattern: {
                                                        value: AUTHREGEX_EMAIL,
                                                        message:
                                                            'Invalid Email Address',
                                                    },
                                                })}
                                            />

                                            <ErrorMessage
                                                className="color-red mt-1"
                                                errors={errors}
                                                name="email"
                                                as="div"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col md={12}>
                                            <input
                                                className={
                                                    'primary' +
                                                    (isLoading
                                                        ? ' is-loading'
                                                        : '')
                                                }
                                                type="submit"
                                                value="submit"
                                            />
                                        </Col>
                                    </Form.Row>

                                    <Form.Row className="mt-4">
                                        <Col
                                            md={12}
                                            className="text-center color-blue"
                                        >
                                            <Link
                                                to={
                                                    process.env
                                                        .REACT_APP_BASE_HREF +
                                                    ROUTES.AUTH +
                                                    ROUTES.SIGNIN
                                                }
                                            >
                                                <span className="color-text-hint">
                                                    Remember your Password?
                                                </span>
                                                <span className="uppercase ml-1">
                                                    Sign In
                                                </span>
                                            </Link>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            )}
                        </React.Fragment>
                    }
                />
            </div>
        )
    }, [formState, errors, handleSubmit, isLoading, register, httpSendRequest])

    return onRender()
}

import { ErrorMessage } from '@hookform/error-message'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Col, Form as FormBS } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import {
    AddMemberPayload,
    authService,
    storePatient,
} from '../../../services/http/auth.service'
import { FamilyMember, Patient } from '../../../services/models/Patient.model'
import {
    FamilyMemberAdded,
    PatientUpdated,
} from '../../../services/store/actions'
import { MAX_MEMBERS } from '../../constants'
import { useFamilyMember } from '../../hooks/useFamilyMember'
import { usePatient } from '../../hooks/usePatient'
import {
    MemberDetails,
    onOpenMemberDetailsModal,
} from '../member-details/MemberDetailsModal'

interface Props {
    onAdded: () => void
    onCancel: () => void
}

export interface DropdownOption {
    value: string
    display: string
}

export function Form(props: Props) {
    const { familyMembers } = useFamilyMember()
    const { patient } = usePatient()
    const { handleSubmit, errors, setError, clearErrors } = useForm()
    const [form, setForm] = useState<FormObj | null>(null)
    const formRef = useRef<FormObj | null>(form)

    useEffect(() => {
        let _form: FormObj = {
            members: [],
        }
        if (formRef.current) {
            _form = { ...formRef.current }
        }
        setForm(_form)
    }, [])

    useEffect(() => {
        formRef.current = form
    }, [form])

    const canAddMember = useMemo((): boolean => {
        const count = familyMembers.length + (form?.members as any[])?.length
        return count < MAX_MEMBERS
    }, [familyMembers, form?.members])

    const onValidate = () => {
        if (!form?.members?.length) {
            setError('members', {
                type: 'manual',
                message: 'Members are required',
            })
            return false
        }
        return true
    }

    const onSubmit = (data: FormObj) => {
        if (!onValidate()) {
            return
        }

        const formData = {
            ...form,
            ...data,
        }

        const promises: Promise<FamilyMember>[] = []
        formData.members?.forEach((memberDetails: MemberDetails) => {
            const payload: AddMemberPayload = {
                firstName: memberDetails.firstName,
                lastName: memberDetails.lastName,
                gender: memberDetails.gender,
                birthday: new Date(memberDetails.birthday).toISOString(),
            }
            if (memberDetails.healthCardNumber) {
                payload.healthCardNumber = +memberDetails.healthCardNumber
            }

            promises.push(
                new Promise((resolve) => {
                    authService
                        .addMember(payload)
                        .then((familyMember: FamilyMember) => {
                            FamilyMemberAdded.dispatch(familyMember)

                            resolve(familyMember)
                        })
                })
            )
        })

        Promise.all(promises).then((_familyMembers: FamilyMember[]) => {
            if (patient) {
                const _patient: Patient = {
                    ...patient,
                }
                _familyMembers?.forEach((familyMember: FamilyMember) => {
                    _patient.familyMembers.push(familyMember)
                })

                // Update patient object in localStorage
                storePatient(_patient)

                // Update patient in redux store
                PatientUpdated.dispatch(_patient)
            }
            props.onAdded()
        })
    }

    return (
        <div>
            <FormBS onSubmit={handleSubmit(onSubmit)}>
                {/* Members */}
                <FormBS.Row>
                    <Col md={12}>
                        <FormBS.Label>Members</FormBS.Label>
                    </Col>

                    {form?.members?.map(
                        (memberDetails: MemberDetails, index: number) => (
                            <Col md={12} key={index}>
                                <div className="member-wrapper">
                                    <div>{`${memberDetails.firstName} ${memberDetails.lastName}`}</div>
                                    <div className="d-flex align-items-center">
                                        <i
                                            className="material-icons md-20 ml-3 cursor-pointer color-blue"
                                            onClick={() => {
                                                onOpenMemberDetailsModal(
                                                    (
                                                        _memberDetails: MemberDetails
                                                    ) => {
                                                        const members = [
                                                            ...form.members,
                                                        ]
                                                        members[
                                                            index
                                                        ] = _memberDetails
                                                        if (formRef.current) {
                                                            setForm({
                                                                ...formRef.current,
                                                                members,
                                                            })
                                                        }
                                                    },
                                                    memberDetails
                                                )
                                            }}
                                        >
                                            edit
                                        </i>
                                        <i
                                            className="material-icons md-20 ml-3 cursor-pointer color-red"
                                            onClick={() => {
                                                const members = [
                                                    ...form.members,
                                                ]
                                                members.splice(index, 1)
                                                if (formRef.current) {
                                                    setForm({
                                                        ...formRef.current,
                                                        members,
                                                    })
                                                }
                                            }}
                                        >
                                            delete
                                        </i>
                                    </div>
                                </div>
                            </Col>
                        )
                    )}

                    <Col md={12}>
                        <ErrorMessage
                            className="color-red mt-1"
                            errors={errors}
                            name="members"
                            as="div"
                        />
                    </Col>

                    {canAddMember && (
                        <Col md={12}>
                            <div
                                className="mt-2 div-button color-blue"
                                onClick={() => {
                                    onOpenMemberDetailsModal(
                                        (memberDetails: MemberDetails) => {
                                            if (formRef.current) {
                                                const members = [
                                                    ...formRef.current.members,
                                                    memberDetails,
                                                ]
                                                setForm({
                                                    ...formRef.current,
                                                    members,
                                                })
                                            }
                                            clearErrors()
                                        }
                                    )
                                }}
                            >
                                New Member
                            </div>
                        </Col>
                    )}
                </FormBS.Row>
                <FormBS.Row className="my-4">
                    <Col
                        md={12}
                        className="d-flex align-items-center justify-content-center"
                    >
                        <input
                            type="submit"
                            value="Submit"
                            className="mr-3 width-unset color-blue background-none"
                        />
                        <div
                            className="div-button color-text-secondary"
                            onClick={props.onCancel}
                        >
                            Cancel
                        </div>
                    </Col>
                </FormBS.Row>
            </FormBS>
        </div>
    )
}

interface FormObj {
    members: MemberDetails[]
}

import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { ManageMembers } from '../ManageMembers/ManageMembers'
import { PrescriptionHomeDelivery } from '../PrescriptionHomeDelivery/PrescriptionHomeDelivery'
import { UpdatePassword } from '../UpdatePassword/UpdatePassword'
import './Account.scss'

enum MENU {
    UPDATE_PROFILE = 'Update Profile',
    UPDATE_PASSWORD = 'Update Password',
    MANAGE_MEMBERS = 'Manage Friends & Family',
    PRESCRIPTION_HOME_DELIVERY = 'Prescription - Home Delivery',
}

const menus = [
    // MENU.UPDATE_PROFILE,
    MENU.MANAGE_MEMBERS,
    MENU.UPDATE_PASSWORD,
    MENU.PRESCRIPTION_HOME_DELIVERY,
]

export function Account() {
    const [menuSelected, setMenuSelected] = useState<MENU>(MENU.MANAGE_MEMBERS)

    return (
        <div className="account-wrapper max-content-width flex-1">
            <div className="d-none d-md-block menu-options-wrapper">
                {menus.map((menu: MENU) => (
                    <div
                        key={menu}
                        className={
                            'd-flex menu-options-item ' +
                            (menuSelected === menu ? ' active' : '')
                        }
                        onClick={() => setMenuSelected(menu)}
                    >
                        {menu}
                    </div>
                ))}
            </div>

            <Dropdown className="d-inline-flex d-md-none menu-dropdown">
                <Dropdown.Toggle id="menu-dropdown" as="div">
                    {menuSelected}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {menus.map((menu: MENU) => (
                        <Dropdown.Item
                            key={menu}
                            className={
                                'd-flex ' +
                                (menuSelected === menu ? ' active' : '')
                            }
                            onClick={() => setMenuSelected(menu)}
                        >
                            {menu}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            <div className="menu-panel">
                {/* {menuSelected === MENU.UPDATE_PROFILE && <UpdateProfile />} */}
                {menuSelected === MENU.UPDATE_PASSWORD && <UpdatePassword />}
                {menuSelected === MENU.MANAGE_MEMBERS && <ManageMembers />}
                {menuSelected === MENU.PRESCRIPTION_HOME_DELIVERY && (
                    <PrescriptionHomeDelivery />
                )}
            </div>
        </div>
    )
}

import React from 'react'

export function Hero() {
    return (
        <section className="hero-wrapper">
            <div className="hero-inner">
                <div className="hero-content">
                    <div className="section-title">
                        Better health starts with better communication
                    </div>
                    <div className="section-subtitle">
                        Receive secure messages, documents, lab results and more
                        from your Doctor anytime, anywhere.
                    </div>
                </div>
                <img
                    className="hero-image"
                    src={
                        process.env.PUBLIC_URL + '/assets/images/hero-image.png'
                    }
                    alt="Medley App"
                />
            </div>
        </section>
    )
}

import React from 'react'
import './IconLabel.scss'

interface Props {
    label: string
    materialIcon: string
    color?:
        | 'color-text'
        | 'color-text-secondary'
        | 'color-text-hint'
        | 'color-blue'
        | 'color-green'
        | 'color-red'
    classes?: string
}

export function IconLabel(props: Props) {
    return (
        <div
            className={
                'icon-label-wrapper ' +
                (props.classes ?? '') +
                ' ' +
                (props.color ?? ' color-text-secondary')
            }
        >
            <i
                className={
                    'material-icons md-20 mr-2 ' +
                    (props.color ?? 'color-text-secondary')
                }
            >
                {props.materialIcon}
            </i>
            <div>{props.label}</div>
        </div>
    )
}

import { Provider as ProviderIndex } from '../../../services/models/Provider.model'

export class Provider {
    providerNo!: number
    firstName!: string
    lastName!: string
    clientName!: string
    isOnlineBookingEnabled?: boolean

    // Custom properties
    fullName?: string
}

export function deserializeProvider(obj: any): Provider {
    const model: Provider = Object.assign({}, obj)
    model.fullName = [obj.firstName, obj.lastName].join(' ')
    return model
}

export const toProviderIndex = (provider: Provider): ProviderIndex => {
    const model: ProviderIndex = {
        ...provider,
        fullName: provider.fullName!,
        providerID: provider.providerNo,
        createdAt: new Date(),
    }
    return model
}
